<mat-card-title>{{article.category}}</mat-card-title>
<mat-card-subtitle *ngIf="!!article.imageUri">
  <a [href]="article.uri" target="_blank"  [title]="article.title">{{ article.title }}</a>
</mat-card-subtitle>
<div *ngIf="!article.imageUri">
  <mat-card-title>
    <a [href]="article.uri" target="_blank" [title]="article.title">{{ article.title }}</a>
  </mat-card-title>
</div>
<img *ngIf="!!article.imageUri"
     mat-card-image
     class="news-image"
     [src]="article.imageUri"
     [alt]="article.imageAlt"
>

