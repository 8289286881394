import {EnumMap, EnumMemberDetails} from '../utils/lookup-map';

export enum EUserRole {
  Admin,
  User
}

export class EUserRoleDetails extends EnumMemberDetails<EUserRole> {}

export const EUserRoleLookup: EnumMap<EUserRole, EUserRoleDetails> =
  new EnumMap<EUserRole, EUserRoleDetails>(
    [
      [EUserRole.User, { id: EUserRole.User, name: 'User' }],
      [EUserRole.Admin, { id: EUserRole.Admin, name: 'Admin' }],
    ],
    { id: null, name: 'Unknown Role' }
  );
