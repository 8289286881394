import { Component, OnInit } from '@angular/core';
import {AdditionalInfoAdminService} from '../../services/additional-info-admin.service';
import {takeUntil} from 'rxjs/operators';
import {AdditionalInfoCategoryModel} from '../../models/AdditionalInfoCategory';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AdditionalInfoSubmissionModel} from '../../models/AdditionalInfoSubmissionModel';

@Component({
  selector: 'app-additional-info-submit',
  templateUrl: './additional-info-submit.component.html',
  styleUrls: ['./additional-info-submit.component.scss']
})
export class AdditionalInfoSubmitComponent implements OnInit {
  form: UntypedFormGroup;
  submission: AdditionalInfoCategoryModel;
  types: ['*/*'];
  private adminOnly: boolean;


  constructor(private service: AdditionalInfoAdminService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      adminOnly: new UntypedFormControl('', [Validators.required]),
    });

  }

  addCategory() {
    if (!this.form.valid) {
      return false;
    }
    if (this.form.controls.adminOnly.value === '1') {
      this.adminOnly = true;
    } else {
      this.adminOnly = false;
    }

    this.submission = new AdditionalInfoCategoryModel(
      1,
      this.form.controls.name.value,
      this.form.controls.adminOnly.value,
    );

    this.service.createCategory(this.submission).subscribe(() => {
          this.router.navigateByUrl(
            'information',
            {state: {messages: [{type: 'success', message: 'The Category has been created.'}]}}
          );
        }, err => console.error(err)
      );

  }
}
