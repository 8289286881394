import {Component, Input, OnInit} from '@angular/core';
import {ContactModel} from '../../models/contact-model';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss']
})
export class ContactCardComponent implements OnInit {
  @Input() contact: ContactModel;

  constructor() { }

  ngOnInit() {
  }

  get objectPosition() {
    if (!this.contact.imageFile || !this.contact.position) {
      return 'unset';
    }

    return `${this.contact.position.x}% ${this.contact.position.y}%`;
  }
}
