import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AdditionalInfoItemSubmission} from "../../models/AdditionalInfoItemSubmission";
import {AdditionalInfoSubmissionModel} from "../../models/AdditionalInfoSubmissionModel";
import {AdditionalInfoAdminService} from "../../services/additional-info-admin.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-cbrne-info-add-item',
  templateUrl: './cbrne-info-add-item.component.html',
  styleUrls: ['./cbrne-info-add-item.component.scss']
})
export class CbrneInfoAddItemComponent implements OnInit {
  form: UntypedFormGroup;
  submission: AdditionalInfoItemSubmission;
  types: ['*/*'];
  id: number;

  constructor(private service: AdditionalInfoAdminService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.form = new UntypedFormGroup({
      itemDescription: new UntypedFormControl('', [Validators.required]),
    });
  }

  addItem() {
    if (!this.form.valid) {
      return false;
    }
    this.submission = new AdditionalInfoItemSubmission(
      this.id,
      this.form.controls.itemDescription.value,
    );
    this.service.createItem(this.submission).subscribe(() => {
        this.router.navigateByUrl(
          'information',
          {state: {messages: [{type: 'success', message: 'The Item has been created.'}]}}
        );
      }, err => console.error(err)
    );

  }
}
