import {Component, Inject, Injectable, OnInit, ViewChild} from '@angular/core';
import {UserModel} from '../../models/user-model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Observable, of, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {Message} from '../../components/messages/messages.component';
import {EUserRole, EUserRoleLookup} from '../../models/user-role.enum';
import {PasswordRulesComponent} from '../../components/password-rules/password-rules.component';
import {OneSignal} from 'onesignal-ngx';
import {Toast, ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {RemoveAccountComponent} from '../remove-account/remove-account.component';


type PositionError = GeolocationPositionError;
type Position = GeolocationPosition;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  @ViewChild(PasswordRulesComponent) passwordRulesComponent: PasswordRulesComponent;
  ownProfile: boolean;
  private unsubscribe = new Subject<boolean>();
  changePasswordForm: UntypedFormGroup;
  resetPasswordForm: UntypedFormGroup;
  profile: UserModel;
  messageStream = new Subject<Message>();
  gettingPosition = false;
  form: UntypedFormGroup;

  public location: UntypedFormGroup = new UntypedFormGroup({
    gps: new UntypedFormControl('', [Validators.required]),
    streetAddress: new UntypedFormControl('', []),
  });

  constructor(private authService: AuthService, private userService: UserService, private route: ActivatedRoute,
              private router: Router, private toastr: ToastrService, private translate: TranslateService,
              public oneSignal: OneSignal, private PopUp: MatDialog,) { }

  ngOnInit(): void {
    this.changePasswordForm = new UntypedFormGroup({
      currentPassword: new UntypedFormControl('', [
        Validators.required
      ]),
      password: new UntypedFormControl('', [
      ]),
    });

    this.resetPasswordForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [
      ]),
      role: new UntypedFormControl('', [
        Validators.required
      ]),
      isConfirmed: new UntypedFormControl(false)
    });

    this.form = new UntypedFormGroup({
      location: new UntypedFormControl(
        '',
      ),})

    this.loadProfile();
  }

  loadProfile(): void {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.has('id')) {
        const id = parseInt(params.get('id'), 10);
        this.userService.get$(id).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          this.ownProfile = false;
          this.profile = data;
          this.resetPasswordForm.setValue({password: '', role: this.profile.role});
        });
      } else {
        this.authService.profile$.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          this.ownProfile = true;
          this.profile = data;
        });
      }
    });
  }


  changePassword(): void {
    if (!this.changePasswordForm.valid) {
      return;
    }

    this.authService.changePassword(
      this.changePasswordForm.get('currentPassword').value,
      this.changePasswordForm.controls.password.value.password
    ).subscribe(
      () => this.passwordChanged(),
      err => this.passwordRulesComponent.handleError(err),
    );
  }

  passwordChanged(): void {
    this.authService.logout();
    this.router.navigateByUrl(
      'login',
      { state: { messages: [{type: 'success', message: 'app-message.profile.password-changed'}] } }
    );
  }

  saveChanges(): Observable<boolean> {
    if (!this.resetPasswordForm.valid) {
      return of(false);
    }
    const newRole = this.resetPasswordForm.get('role').value;
    const newPassword = this.resetPasswordForm.controls.password.value.password;
    const isConfirmed = this.resetPasswordForm.controls.isConfirmed.value;

    if (newPassword) {
      this.userService.updatePassword$(this.profile.id, newPassword).subscribe(
        () => this.updatedMessage('app-message.profile.Password'),
        err => this.passwordRulesComponent.handleError(err),
      );
    }


    if (this.profile.role !== newRole) {
      this.userService.updateRole$(this.profile.id, newRole).subscribe(
        () => this.updatedMessage('app-message.profile.Role'),
        err => this.passwordRulesComponent.handleError(err),
      );
    }

    if (this.profile.isConfirmed !== isConfirmed) {
      this.userService.updateIsConfirmed$(this.profile.id, isConfirmed).subscribe(
        () => this.updatedMessage('app-message.profile.Role'),
        err => this.passwordRulesComponent.handleError(err),
      );
    }
  }



  private updatedMessage(value: string) {
    this.messageStream.next({
      message: 'app-message.profile.updated',
      value,
      type: 'success'
    });
    this.loadProfile();
  }

  roleType(index: number, item: {id: number, name: string}): any {
    return item.id;
  }

  get userRoles(): { id: number, name: string }[] {
    return Object.keys(EUserRole).filter(k => typeof EUserRole[k] === 'number').map(type => {
      const id = EUserRole[type];
      return {
        id,
        name: EUserRoleLookup.get(id).name
      };
    });
  }

  toggleLocation() {
    this.gettingPosition = true;
    navigator.geolocation.getCurrentPosition(
      this.receiveCurrentLocation.bind(this),
      this.currentPositionError.bind(this),
      { maximumAge: 60000}
      );
      this.oneSignal.setSubscription(true);
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(result => {
        if (result.state === 'denied') {
          alert('Disabled in the browser')
        }
      })
    } 
  }
  receiveCurrentLocation(position: Position) {
    this.gettingPosition = false;
    const pos = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };

    this.location.patchValue({
      gps: pos
    });
    
    let newLocation = this.form.controls.location.value;
    newLocation = pos;

    this.authService.setProfile(newLocation).subscribe( ()=> {},
    err => {
      console.error(err);
      this.toastr.error(this.translate.instant('profile-notification.toggle.error.message'),
        this.translate.instant('profile-notification.toggle.error.title'), {
        timeOut: 10000,
        positionClass: 'toast-bottom-center',
        closeButton: true
      });
    },
    () => {
      this.toastr.success(this.translate.instant('profile-notification.toggle.success.message'),
        this.translate.instant('profile-notification.toggle.success.title'), {
        timeOut: 10000,
        positionClass: 'toast-bottom-center',
        closeButton: true
      });
    });
  }
  currentPositionError(error: PositionError) {
    this.gettingPosition = false;
    console.error('Error getting current position', error);
  }


  openPopUp() {
    const PopUpConfig = new MatDialogConfig();

    PopUpConfig.autoFocus = false;

    this.PopUp.open(RemoveAccountComponent, PopUpConfig);
  }

}
