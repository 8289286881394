import {HttpClient} from "@angular/common/http";
import {Observable, Subscription, throwError} from "rxjs";
import {AdditionalInfoCategoryModel} from "../models/AdditionalInfoCategory";
import {catchError, map, shareReplay} from "rxjs/operators";
import {handleHttpError} from "../utils/http-error-catcher";
import {Injectable} from "@angular/core";
import {AdditionalInfoItemModel} from "../models/AdditionalInfoItem";
import {AdditionalInfoResponseModel} from "../models/AdditionalInfoResponseModel";
import {IncidentCreateModel} from "../models/incident-create-model";
import {AuthService} from "./auth.service";
import {AdditionalInfoSubmissionModel} from "../models/AdditionalInfoSubmissionModel";
import {AdditionalInfoItemSubmission} from "../models/AdditionalInfoItemSubmission";
import {AdditionalInfoFileModel} from "../models/AdditionalInfoFileModel";
import {AdditionalFileSubmission} from "../models/AdditionalFileSubmission";
import {AdditionalInfoUserService} from "./additional-info-user.service";

@Injectable({
  providedIn: 'root'
})

export class AdditionalInfoAdminService {
  categorylist: Observable<AdditionalInfoCategoryModel[]>;
  itemlist: Observable<AdditionalInfoItemModel[]>;
  category: Observable<AdditionalInfoCategoryModel>;
  private catlistCompleted = false;
  private itemlistCompleted = false;
  private catlistCompletedSubscription: Subscription;
  private itemlistCompletedSubscription: Subscription;
  private getMap: Map<number, Observable<AdditionalInfoResponseModel>> = new Map();
  private getItemMap: Map<number, Observable<AdditionalInfoItemModel>> = new Map();
  private getCompletedMap: Map<number, boolean> = new Map();
  private getCompletedSubscriptionMap: Map<number, Subscription> = new Map();
  private getCompletedItemMap: Map<number, boolean> = new Map();
  private getCompletedItemSubscriptionMap: Map<number, Subscription> = new Map();

  constructor(private http: HttpClient, private auth: AuthService, private userInfo: AdditionalInfoUserService) { }


  categorylist$(): Observable<AdditionalInfoCategoryModel[]> {
    if (!this.categorylist || this.catlistCompleted) {
      this.categorylist = this.http.get('additionalinformation/categories').pipe(
        catchError(handleHttpError),
        map(resp => {
          return resp.map(AdditionalInfoCategoryModel.fromObject);
        }),
        shareReplay(1));

      this.catlistCompletedSubscription = this.categorylist.subscribe({complete: this.oncatListCompleted.bind(this)});
    }

    return this.categorylist;

  }

  itemlist$(): Observable<AdditionalInfoItemModel[]> {
    if (!this.itemlist || this.itemlistCompleted) {
      this.itemlist = this.http.get('additionalinformation/items').pipe(
        catchError(handleHttpError),
        map(resp => {
          return resp.map(AdditionalInfoItemModel.fromObject);
        }),
        shareReplay(1));

      this.itemlistCompletedSubscription = this.itemlist.subscribe({complete: this.onitemListCompleted.bind(this)});
    }

    return this.itemlist;

  }
  deleteFile$(fileId): Observable<boolean> {
    return this.http.delete<AdditionalInfoFileModel>(`additionalinformation/files/${fileId}`).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }

  deleteItem$(itemId): Observable<boolean> {
    return this.http.delete<AdditionalInfoItemModel>(`additionalinformation/items/${itemId}`).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }

  deleteCategory$(categoryId): Observable<boolean> {
    return this.http.delete<AdditionalInfoCategoryModel>(`additionalinformation/categories/${categoryId}`).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }

  update$(category): Observable<boolean> {
    return this.http.put<AdditionalInfoCategoryModel>(`additionalinformation/categories/${category.id}`, category).pipe(
      catchError(handleHttpError),
      map(resp => resp.success)
    );
  }

  updateItem$(item): Observable<boolean> {
    return this.http.put<AdditionalInfoItemSubmission>(`additionalinformation/items/${item.id}`, item).pipe(
      catchError(handleHttpError),
      map(resp => resp.success)
    );
  }

  getItem$(id: number): Observable<AdditionalInfoItemModel> {
    let obs = this.getItemMap.get(id);
    if (!obs || this.getCompletedItemMap.get(id)) {
      this.getCompletedItemMap.set(id, false);
      obs = this.http.get<AdditionalInfoItemModel>(`additionalinformation/items/${id}`).pipe(
        catchError(handleHttpError),
        map(AdditionalInfoItemModel.fromObject),
        shareReplay(1)
      );
      this.getItemMap.set(id, obs);

      const subscription = obs.subscribe({complete: this.onGetItemCompleted(id)});
      this.getCompletedItemSubscriptionMap.set(id, subscription);
    }

    if (!obs) {
      return throwError('Not found');
    }

    return obs;
  }

  onGetItemCompleted(id: number): () => void {
    return () => {
      this.getCompletedItemMap.set(id, true);
      setTimeout(() => this.getCompletedItemSubscriptionMap.get(id).unsubscribe(), 0);
    };
  }

  get$(id: number): Observable<AdditionalInfoResponseModel> {
    let obs = this.getMap.get(id);
    if (!obs || this.getCompletedMap.get(id)) {
      this.getCompletedMap.set(id, false);
      obs = this.http.get<AdditionalInfoResponseModel>(`additionalinformation/categories/${id}`).pipe(
        catchError(handleHttpError),
        map(AdditionalInfoResponseModel.fromObject),
        shareReplay(1)
      );
      this.getMap.set(id, obs);

      const subscription = obs.subscribe({complete: this.onGetCompleted(id)});
      this.getCompletedSubscriptionMap.set(id, subscription);
    }

    if (!obs) {
      return throwError('Not found');
    }

    return obs;
  }

  createCategory(data: AdditionalInfoCategoryModel): Observable<boolean> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Create Categories.');
    }

    const body = {...data};

    return this.http.post<AdditionalInfoCategoryModel>(`additionalinformation/categories`, body).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }

  createItem(data: AdditionalInfoItemSubmission): Observable<boolean> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Create Items.');
    }

    const body = {...data};

    return this.http.post<AdditionalInfoItemSubmission>(`additionalinformation/items`, body).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }

  onGetCompleted(id: number): () => void {
    return () => {
      this.getCompletedMap.set(id, true);
      setTimeout(() => this.getCompletedSubscriptionMap.get(id).unsubscribe(), 0);
    };
  }

  oncatListCompleted(): void {
    this.catlistCompleted = true;
    setTimeout(() => this.catlistCompletedSubscription.unsubscribe(), 0);
  }

  onitemListCompleted(): void {
    this.itemlistCompleted = true;
    setTimeout(() => this.itemlistCompletedSubscription.unsubscribe(), 0);
  }

  createFile(submission: AdditionalFileSubmission) {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Create Items.');
    }

    const body = {...submission};

    return this.http.post<AdditionalFileSubmission>(`additionalinformation/files`, body).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );

  }
}
