<div id="home-container" role="main">
  <mat-card class="home-tile" fxLayout="row" id="welcome-card">
    <div fxFlex="" fxLayout="column">
      <mat-card-title role="heading" aria-level="2">{{'dashboard.welcome' | translate}}</mat-card-title>
      <mat-card-content fxFlex="">
        <div>
          <p>{{'dashboard.crisis' | translate}}</p>
          <ul>
            <li>{{'dashboard.report' | translate}}</li>
            <li>{{'dashboard.access' | translate}}</li>
            <li>{{'dashboard.interact' | translate}}</li>
          </ul>
        </div>
      </mat-card-content>
      <mat-card-content>
        <div id="quick-buttons">
            <button *appIsNotLoggedIn mat-raised-button color="primary" routerLink="/login" id="login-button" role="link" aria-label="Log In to your Account">{{'dashboard.login' | translate}}</button>
            <button *appIsNotAdmin mat-raised-button color="primary" routerLink="/share" role="link" aria-label="Report a new Incident">{{'dashboard.report-an-incident' | translate}}</button>
            <button *appIsAdmin mat-raised-button color="primary" routerLink="/incidents/new" role="link" aria-label="Report an Incident to the Public">{{'dashboard.report-incident-to-public' | translate}}</button>
            <button mat-raised-button color="primary" routerLink="/incidents" role="link" aria-label="View Incident Information">{{'dashboard.incident-information' | translate}}</button>
        </div>
      </mat-card-content>
    </div>
    <img fxFlex.gt-md="40vw" fxFlex.md="30vw" fxHide.lt-md="true" src="assets/images/proactive-mosaic.webp" alt="Tiled photographs of CUAV personnel.">
  </mat-card>
  <br>
  <div id="incident-wrapper">
    <div id="incident-grid" fxLayout="row wrap" fxLayoutGap="12px grid">
      <div class="incident-tile notification" fxFlex.gt-md="25" fxFlex="50" fxFlex.lt-sm="100" *ngIf="!hasLatestNotification">
        <h1>{{'dashboard.live-notification' | translate}}</h1>
        <p>{{'dashboard.no-live' | translate}}</p>
      </div>
      <div routerLink="incidents/{{latestNotification.id}}" role="link" class="incident-tile notification linked" fxFlex.gt-md="25" fxFlex="50" fxFlex.lt-sm="100" *ngIf="!!hasLatestNotification">
        <h1> {{'dashboard.live-notification' | translate}}</h1>
        <p *ngIf="latestNotification.location.streetAddress !== 'Unknown'">{{latestNotification.created | date: 'yyyy-MM-dd HH:mm'}} at {{latestNotification.location.streetAddress}}</p>
        <p *ngIf="latestNotification.location.streetAddress == 'Unknown'">{{latestNotification.created | date: 'yyyy-MM-dd HH:mm'}} at {{'dashboard.lat' | translate}} {{latestNotification.location.lat}}, {{'dashboard.long' | translate}} {{latestNotification.location.lng}}</p>
        <p>{{latestNotification.headline}}</p>
        <p>{{latestNotification.description}}</p>
        <p>{{latestNotification.nextSteps}}</p>
      </div>
      <div class="incident-tile previous" fxFlex.gt-md="25" fxFlex="50" fxFlex.lt-sm="100">
        <mat-card>
          <mat-card-title role="heading" aria-level="2">{{'dashboard.previous-incidents' | translate}}</mat-card-title>
          <div class="previous-list" fxLayout="column" fxLayoutAlign="space-evenly">
              <div *ngFor="let incident of previousIncidents" fxFlex="0 0 0">
                <a routerLink="/incidents/{{incident.id}}">
                    •{{incident.headline}}: {{incident.location.streetAddress || gpsCoords(incident)}} -
                    {{incident.resolvedDate | date: 'yyyy/MM/dd'}}
                </a>
              </div>
          </div>
        </mat-card>
      </div>
      <div class="incident-tile" fxFlex.gt-md="25" fxFlex="50" fxFlex.lt-sm="100" >
        <mat-card routerLink="/incidents" role="link">
          <mat-card-title role="heading" aria-level="2">{{'dashboard.incident-map' | translate}}</mat-card-title>
          <app-incident-map [incidents]="dataSource.filteredData" [loading]="!dataSource.data"></app-incident-map>
        </mat-card>
      </div>
      <div class="incident-tile" fxFlex.gt-md="25" fxFlex="50" fxFlex.lt-sm="100" >
        <app-twitter-feed></app-twitter-feed>
      </div>
    </div>
  </div>
  <br>
  <div id="large-buttons">
    <button *appIsNotAdmin mat-raised-button id="left-button" color=warn routerLink="/share" role="link" aria-label="Report a New Incident">{{'dashboard.report-an-incident' | translate}}</button>
    <button *appIsAdmin mat-raised-button id="left-button" color=warn routerLink="/incidents/new" role="link" aria-label="Create a New Incident">{{'dashboard.report-incident-to-public' | translate}}</button>
    <button mat-raised-button id="right-button" color="primary" routerLink="/information" role="link" aria-label="CBRNe Information">{{'dashboard.cbrne-information' | translate}}</button>
  </div>
  <br/>
  <mat-card *ngIf="!hasNews || news.length == 0" class="home-tile">
    <mat-card-title *ngIf="!hasNews" role="heading" aria-level="2">{{'dashboard.live-news-feed' | translate}}</mat-card-title>
    <mat-card-content *ngIf="!hasNews">
      <mat-spinner class="centered" aria-label="Loading News Feed"></mat-spinner>
    </mat-card-content>
    <mat-card-content *ngIf="hasNews">
      <p *ngIf="news.length == 0">{{'dashboard.there-are-no' | translate}}</p>
    </mat-card-content>
  </mat-card>
  <br>
  <mat-card class="home-tile">
    <mat-card-title role="heading" aria-level="2">{{'dashboard.more-information' | translate}}</mat-card-title>
    <mat-card-content>
      <div class="info-tile-wrapper">
        <ul>
          <li>
            <mat-icon class="list-icon">info</mat-icon>
            <a [routerLink]="['/about']">{{'dashboard.about-us' | translate}}</a>
          </li>
          <li>
            <mat-icon class="list-icon">email</mat-icon>
            <a [routerLink]="['/contacts']">{{'dashboard.lea' | translate}}</a>
          </li>
          <li>
            <mat-icon class="list-icon">help_center</mat-icon>
            <a [routerLink]="['/faq']">{{'dashboard.help' | translate}}</a>
          </li>
          <li>
            <mat-icon class="list-icon">privacy_tip</mat-icon>
            <a [routerLink]="['/privacy']">{{'dashboard.privacy-policy' | translate}}</a>
          </li>
          <li>
            <mat-icon class="list-icon">apps</mat-icon>
            <a [routerLink]="['/resources']">{{'dashboard.other-apps' | translate}}</a>
          </li>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>
</div>
