import {ChangeDetectorRef, Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AsyncSubject,  Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MediaChange, MediaObserver} from '@angular/flex-layout';

@Directive({
  selector: '[appFxHide.gt-sm]'
})
export class FxHideGtSmDirective implements OnInit, OnDestroy {
  private unsubscribe = new Subject<boolean>();
  private init = new AsyncSubject<void>();
  private watcher: Subscription;

  constructor(
    private view: ViewContainerRef,
    private nextRef: TemplateRef<any>,
    private changes: ChangeDetectorRef,
    private mediaObserver: MediaObserver
  ) {
    this.watcher = this.mediaObserver.media$.pipe(takeUntil(this.unsubscribe)).subscribe(
      (change: MediaChange) => {
        this.view.clear();
        if (change.mqAlias === 'xs' || change.mqAlias === 'sm') {
          this.view.createEmbeddedView(this.nextRef);
        }
        this.changes.markForCheck();
    });
  }

  ngOnInit() {
    this.init.next();
    this.init.complete();
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
  }
}
