export class OneSignalModel {
  id: string;

  constructor(id: string) {
    this.id = id;
  }

  static fromObject(
    obj: {
      id: string;
    }
  ): OneSignalModel {
    const id = obj.id;

    return new OneSignalModel(
      id,
    );
  }
}
