<div>
  <h4>{{data.title}}</h4>
  <span *ngIf="data.body">{{data.body}}</span>
</div>
<div class="mat-simple-snackbar-action left-button" *ngIf="hasUri">
  <button mat-button (click)="view($event)">{{'fallback-notification.view' | translate }}</button>
</div>
<div class="mat-simple-snackbar-action right-button">
  <button mat-button (click)="dismiss($event)">{{'fallback-notification.dismiss' | translate }}</button>
</div>
