export class LookupMap<TKey, TVal> extends Map<TKey, TVal> {
  default: TVal | undefined;

  constructor(values: [TKey, TVal][], defaultValue?: TVal) {
    super(values);
    this.default = defaultValue;
  }

  get(key: TKey): TVal {
    const value = super.get(key);

    return value || this.default;
  }

  find(predicate: (TVal) => boolean): TVal {
    for (const entry of this.entries()) {
      if (predicate(entry[1])) {
        return entry[1];
      }
    }

    return this.default;
  }
}

export class EnumMemberDetails<TEnum> {
  id: TEnum | null;
  name: string;
}

export class EnumMap<TEnum extends number, TVal extends EnumMemberDetails<TEnum>> extends LookupMap<TEnum, TVal> {
  constructor(values: [TEnum, TVal][], defaultValue?: TVal) {
    super(values, defaultValue);
  }

  findByName(target: string, caseSensitive = false, spaceSensitive = false): TVal {

    let preparedTarget = target;
    if (!caseSensitive) {
      preparedTarget = preparedTarget.toLowerCase();
    }
    if (!spaceSensitive) {
      preparedTarget = preparedTarget.replace(' ', '');
    }

    for (const entry of this.entries()) {
      let candidate = entry[1].name;

      if (!caseSensitive) {
        candidate = candidate.toLowerCase();
      }
      if (!spaceSensitive) {
        candidate = candidate.replace(' ', '');
      }

      if (candidate === preparedTarget) {
        return entry[1];
      }
    }

    return this.default;
  }
}
