import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable, Subscription, throwError} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {UserCreateModel, UserModel} from '../models/user-model';
import {EUserRole, EUserRoleLookup} from '../models/user-role.enum';
import {handleHttpError} from '../utils/http-error-catcher';


interface UserDetailResponseModel {
  id: number;
  email: string;
  role: string;
}

interface UserCreateResponseModel {
  id: number;
  success: boolean;
}

interface UserDeleteResponseModel {
  id: number;
  success: boolean;
}

interface UserChangePasswordResponse {
  id: number;
  success: boolean;
}

interface UserChangeRoleResponse {
  id: number;
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private list: Observable<UserModel[]> = null;
  private listCompleted = false;
  private listCompletedSubscription: Subscription;
  private getMap: Map<number, Observable<UserModel>> = new Map();
  private getCompletedMap: Map<number, boolean> = new Map();
  private getCompletedSubscriptionMap: Map<number, Subscription> = new Map();

  constructor(private http: HttpClient, private auth: AuthService) { }

  // TODO: Filter? Pagination?
  list$(): Observable<UserModel[]> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to List Users.');
    }

    if (!this.list || this.listCompleted) {
      this.listCompleted = false;
      this.list = this.http.get<UserDetailResponseModel[]>('users').pipe(
        catchError(handleHttpError),
        map(resp => {
          return resp.map(
            u => UserModel.fromObject({
              ...u,
              role: EUserRoleLookup.findByName(u.role).id
            })
          );
        }),
        shareReplay(1)
      );

      this.listCompletedSubscription = this.list.subscribe({complete: this.onListComplete.bind(this) });
    }

    return this.list;
  }

  onListComplete(): void {
    this.listCompleted = true;
    setTimeout(() => this.listCompletedSubscription.unsubscribe(), 0);
  }

  get$(id: number): Observable<UserModel> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Get User Details.');
    }

    let obs = this.getMap.get(id);

    if (!obs || this.getCompletedMap.get(id)) {
      this.getCompletedMap.set(id, false);
      obs = this.http.get<UserDetailResponseModel>(`users/${id}`).pipe(
        catchError(handleHttpError),
        map(u => UserModel.fromObject({
            ...u,
            role: EUserRoleLookup.find(ur =>
              ur.name.toLowerCase() === u.role.toLowerCase()
            ).id
          })
        ),
        shareReplay(1)
      );
      this.getMap.set(id, obs);

      const subscription = obs.subscribe({ complete: this.onGetCompleted(id) });
      this.getCompletedSubscriptionMap.set(id, subscription);
    }

    if (!obs) {
      return throwError('Not found');
    }

    return obs;
  }

  onGetCompleted(id: number): () => void {
    return () => {
      this.getCompletedMap.set(id, true);
      setTimeout(() => this.getCompletedSubscriptionMap.get(id).unsubscribe(), 0);
    };
  }

  create(data: UserCreateModel): Observable<boolean> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Create Users.');
    }

    const body = {
      email: data.email,
      password: data.password,
      role: EUserRoleLookup.findByName(data.role).name,
      isConfirmed: data.isConfirmed,
      emailConfirmed: true,
    };

    return this.http.post<UserCreateResponseModel>(`users`, body).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }

  delete$(userId: number): Observable<boolean> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Create Users.');
    }

    return this.http.delete<UserDeleteResponseModel>(`users/${userId}`).pipe(
      catchError(handleHttpError),
    );
  }

  updatePassword$(id: number, password: string): Observable<UserModel> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to change user passwords');
    }

    const body = {
      id,
      password
    };

    return this.http.put<UserChangePasswordResponse>(`Users/${id}/ChangePassword`, body).pipe(
      catchError(handleHttpError),
      );
  }

  updateRole$(id: number, role: EUserRole): Observable<UserModel> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to change user roles');
    }

    const isAdmin = role === 0;

    const body = {
      id,
      isAdmin
    };

    return this.http.put<UserChangeRoleResponse>(`Users/${id}/ChangeRole`, body).pipe(
      catchError(handleHttpError),
      );
  }

  updateIsConfirmed$(id: number, isConfirmed: boolean): Observable<UserModel> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to change user confirmation status.');
    }

    const body = {
      isConfirmed: isConfirmed
    };

    return this.http.put<UserModel>(`users/${id}/updateIsConfirmed`, body).pipe(
      catchError(handleHttpError),
      map(resp => UserModel.fromObject(resp))  // Assuming the response is the updated user object
    );
  }

}
