import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {IncidentModel} from '../../models/incident-model';
import {IncidentService} from '../../services/incident.service';
import {Subject, Subscription} from 'rxjs';
import {AuthService} from '../../services/auth.service';
import { interval } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import {IncidentFilter} from '../../utils/incident-filter';

@Component({
  templateUrl: './incident-list.component.html',
  styleUrls: ['./incident-list.component.scss']
})
export class IncidentListComponent implements OnInit {
  panRequested = new Subject<google.maps.LatLngLiteral>();

  dataSource = new MatTableDataSource(null);
  incidentData: IncidentModel[] = [];
  filterValue: string;

  selectedIncident: IncidentModel = null;
  currentScreenWidth = '';
  flexMediaWatcher: Subscription;

  get tooSmall(): boolean {
    return ['xs'].includes(this.currentScreenWidth);
  }

  constructor(private incidentService: IncidentService, private auth: AuthService,
              private mediaObserver: MediaObserver) {
    this.flexMediaWatcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      this.currentScreenWidth = change.mqAlias;
    }); // Be sure to unsubscribe from this in onDestroy()!
  }

  ngOnInit() {
    this.loadIncidents();
    // Task every min to load all Incidents again
    interval(1000 * 60).subscribe(() => {
      this.loadIncidents();
    });
  }

  get displayedColumns(): string[] {
    const cols = [];
    cols.push('id', 'location', 'occurredDate', 'headline', 'description', 'liveUpdate', 'status');
    if (!this.tooSmall) {
      cols.push('actions');
    }
    return cols;
  }

  get hasData(): boolean {
    return this.dataSource.data !== null;
  }

  setFilter(event: Event) {
    const filter = (event.target as HTMLInputElement).value;
    this.filterValue = filter.trim().toLowerCase();
    this.applyFilter();
  }

  applyFilter() {
    this.dataSource.data = IncidentFilter.filterIncidents(this.incidentData, this.filterValue);
  }

  selectIncident(incident: IncidentModel) {
    this.selectedIncident = incident;
    this.panRequested.next(incident.location);
  }

  rowLabel(incident: IncidentModel): string {
    return `Incident, ID: ${incident.id}, Reported on: ${incident.reportedDate}, Current State: ${incident.displayStatus}`;
  }

  private loadIncidents(): void {
    this.incidentService.list$().subscribe(data => {
      this.incidentData = data;
      this.applyFilter();
    });
  }
}
