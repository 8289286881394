import {
  Component,
  EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {IncidentModel} from '../../models/incident-model';
import {GoogleMap} from '@angular/google-maps';
import {BehaviorSubject, Observable, ReplaySubject, Subject, zip} from 'rxjs';
import {MapLoaderService} from '../../services/map-loader.service';

@Component({
  selector: 'app-incident-map',
  templateUrl: './incident-map.component.html',
  styleUrls: ['./incident-map.component.scss']
})
export class IncidentMapComponent implements OnInit, OnDestroy {

  private _incidents = new BehaviorSubject<IncidentModel[]>([]);
  private _map = new ReplaySubject<GoogleMap>();

  @Input() set incidents(value: IncidentModel[]) {
    this._incidents.next(value);
  }
  get incidents() {
    return this._incidents.getValue();
  }

  @Input() loading = false;
  @Input() location: google.maps.LatLngLiteral;
  @Input() zoom: number;
  @Input() selectedIncident: IncidentModel;
  @Output() incidentSelected: EventEmitter<IncidentModel> = new EventEmitter<IncidentModel>();
  @Input() panToLocation: Observable<google.maps.LatLngLiteral> = null;
  @ViewChild(GoogleMap)
  set map(m: GoogleMap) {
    if (m) {
      setTimeout(() => {
        this._map.next(m);
      }, 0);
    }
  }
  unsubscribe = new Subject();
  markers: IncidentModel[];

  constructor(public mapLoader: MapLoaderService) {
    zip(
      mapLoader.mapsLoaded,
      this._incidents,
      this._map
    ).subscribe(values => {
      this.fitToMarkers(...values);
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(null);
    this.unsubscribe.complete();
  }

  incidentMarkerOptions(incident: IncidentModel): object {
    return {
      position: incident.location,
      title: incident.headline,
      icon: this.selectedIncident && this.selectedIncident.id === incident.id ? {
        path: 'M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z',
        fillColor: '#ea4335',
        fillOpacity: 1,
        scale: 2,
        anchor: new google.maps.Point(12, 20)
      } : null
    };
  }

  incidentMarkerClicked(incident: IncidentModel) {
    this.incidentSelected.emit(incident);
  }

  onTab(event: KeyboardEvent) {
    if (event.target === document.querySelector('div.map-container div div.gm-style div')) {
      setTimeout(() => {
        (document.querySelector('div.gm-style a') as HTMLElement).focus();
      }, 0);
      event.preventDefault();
    }

    return true;
  }

  onShiftTab(event: KeyboardEvent) {
    if (event.target === document.querySelector('div.gm-style a')) {
      setTimeout(() => {
        (document.querySelector('div.map-container div div.gm-style div') as HTMLElement).focus();
      }, 0);
      event.preventDefault();
    }

    return true;
  }

  private fitToMarkers(loaded, incidents, map: GoogleMap): void {
    const bounds = new google.maps.LatLngBounds();
    if (!incidents || incidents.length === 0) {
      // Set some default bounds to show EU
      bounds.extend( { lat: 55, lng: 3 }); // Uk
      bounds.extend( { lat: 60, lng: 18 }); // Sweden
      bounds.extend( { lat: 40, lng: 4 }); // Spain
      bounds.extend( { lat: 40, lng: 35 }); // Turkey
    } else {
      for (const marker of incidents) {
        bounds.extend({lat: marker.location.lat, lng: marker.location.lng});
      }
    }
    map.fitBounds(bounds);
  }
}
