import {AdditionalInfoItemModel} from "./AdditionalInfoItem";

export class AdditionalInfoResponseModel {
  id: number;
  category: string;
  adminOnly: boolean;
  pdfFile: unknown[];
  webFile: unknown[];
  items: AdditionalInfoItemModel[] | null;

  constructor(id: number, category: string, adminOnly: boolean, pdfFile: unknown[], webFile: unknown[], items: AdditionalInfoItemModel[] | null) {
    this.id = id;
    this.category = category;
    this.adminOnly = adminOnly;
    this.pdfFile = pdfFile;
    this.webFile = webFile;
    this.items = items;
  }

  static fromObject(
    obj: {
      id: number;
      category: string;
      adminOnly: boolean;
      pdfFile: unknown[];
      webFile: unknown[];
      items: AdditionalInfoItemModel[] | null
    }
  ): AdditionalInfoResponseModel {
    const id = obj.id;
    const category = obj.category;
    const adminOnly = obj.adminOnly;
    const pdfFile = obj.pdfFile;
    const webFile = obj.webFile;
    const items = obj.items;
    return new AdditionalInfoResponseModel(
      id,
      category,
      adminOnly,
      pdfFile,
      webFile,
      items
    );
  }
}
