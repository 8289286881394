export class AdditionalFileSubmission {
  id: number;
  itemId: number;
  filepaths: unknown[];

  constructor(id: number, itemId: number, filepaths: unknown[]) {
    this.id = id;
    this.itemId = itemId;
    this.filepaths = filepaths;
  }

  static fromObject(
    obj: {
      id: number;
      itemId: number;
      filepaths: unknown[];
    }
  ): AdditionalFileSubmission {
    const id = obj.id;
    const itemId = obj.itemId;
    const filepaths = obj.filepaths;
    return new AdditionalFileSubmission(
      id,
      itemId,
      filepaths,
    );
  }
}
