<div id="incident-view-wrapper" class="incident-tile" role="main">

<!--Map-->
  <mat-card class="incident-tile">
    <mat-card-title role="heading" aria-level="2">{{'incident-list.incident-map' | translate}}</mat-card-title>
    <app-incident-map
      [incidents]="dataSource.filteredData"
      [loading]="!dataSource.data"
      [selectedIncident]="selectedIncident"
      (incidentSelected)="selectIncident($event)"
      [panToLocation]="panRequested">
    </app-incident-map>
  </mat-card>

<!--Selected Incident-->
  <div id="incident-details-container" class="incident-tile" *ngIf="selectedIncident">
    <mat-card>
      <mat-card-title role="heading" aria-level="2">{{'incident-list.incident-information' | translate}}</mat-card-title>
      <mat-card-content>
        <app-incident-info [incident]="selectedIncident"></app-incident-info>
      </mat-card-content>
    </mat-card>
  </div>

<!--Incident List-->
  <div id="incident-list-container" class="incident-tile">
    <mat-card>
      <mat-card-title role="heading" aria-level="2">{{'incident-list.incident-list' | translate}}</mat-card-title>
      <mat-card-content *ngIf="!hasData">
        <mat-spinner class="centered" aria-label="Loading Incident List"></mat-spinner>
      </mat-card-content>
      <mat-card-content *ngIf="hasData">
        <mat-form-field id="filter">
          <mat-label>{{'incident-list.filter' | translate}}</mat-label>
          <input matInput (keyup)="setFilter($event)" aria-label="Incident Text Filter" placeholder="{{'incident-list.filter-prompt' | translate}}">
        </mat-form-field>
        <button *appIsAdmin mat-raised-button color="primary" id="create-button" routerLink="./new"><mat-icon>add</mat-icon>{{'incident-list.create' | translate}}</button>

        <!--Actual Table-->
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8" aria-label="Table of Incidents" style="overflow-x: scroll; display: grid; grid-template-rows: 1fr;">
          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <div *ngIf="!tooSmall">
              <mat-header-cell *matHeaderCellDef>{{'incident-list.incident-id' | translate }}</mat-header-cell>
            </div>
            <div *ngIf="tooSmall">
              <mat-header-cell *matHeaderCellDef>{{'incident-list.id' | translate}}</mat-header-cell>
            </div>
            <mat-cell *matCellDef="let element"> {{element.id}} </mat-cell>
          </ng-container>

          <!--Location-->
          <ng-container matColumnDef="location">
            <mat-header-cell *matHeaderCellDef>{{'incident-list.location' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span>{{ element.location.streetAddress || 'Unknown' }}</span>
            </mat-cell>
          </ng-container>

          <!-- Occurred Date Column -->
          <ng-container matColumnDef="occurredDate">
            <div *ngIf="!tooSmall">
              <mat-header-cell *matHeaderCellDef>{{'incident-list.date' | translate}}</mat-header-cell>
            </div>
            <div *ngIf="tooSmall">
              <mat-header-cell *matHeaderCellDef>{{'incident-list.occured' | translate}}</mat-header-cell>
            </div>
            <mat-cell *matCellDef="let element">
              <span>{{ element.occurred | date: 'dd.MM.yyyy' }} at {{ element.occurred | date: 'HH:mm' }} UTC</span>
            </mat-cell>
          </ng-container>

          <!-- Headline Column -->
          <ng-container matColumnDef="headline">
            <mat-header-cell *matHeaderCellDef>{{'incident-list.headline' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span>{{ element.headline || 'None provided' }}</span>
            </mat-cell>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef>{{'incident-list.description' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span>{{ element.description || 'None provided' }}</span>
            </mat-cell>
          </ng-container>

          <!-- Live Update Column -->
          <ng-container matColumnDef="liveUpdate">
            <mat-header-cell *matHeaderCellDef> {{'incident-list.live-update' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.nextSteps || 'No updates reported' }} </mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> {{'incident-list.status' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.displayStatus | translate }} </mat-cell>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions" *ngIf="!tooSmall">
            <mat-header-cell *matHeaderCellDef>{{'incident-list.actions' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <div id="action-buttons">
                <button mat-raised-button color="primary" [routerLink]="element.id">
                  <mat-icon>visibility</mat-icon>
                  {{'incident-list.view' | translate}}
                </button>
                <button *appIsAdmin mat-raised-button color="primary" [routerLink]="element.id + '/edit'">
                  <mat-icon>edit</mat-icon>{{'general.edit' | translate}}
                </button>
              </div>
            </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"
                   [ngClass]="{'selected': selectedIncident && selectedIncident.id === row.id}"
                   (click)="selectIncident(row)"
                   (keydown.enter)="selectIncident(row)"
                    tabIndex="0">
          </mat-row>
        </mat-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
