import {ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AsyncSubject, Observable, Subject} from 'rxjs';
import {concatMapTo, map, takeUntil} from 'rxjs/operators';
import {AuthService} from '../services/auth.service';

@Directive()
class BaseAuthDirective<T = boolean> implements OnInit, OnDestroy {
  private unsubscribe = new Subject<boolean>();
  private init = new AsyncSubject<void>();

  constructor(
    private view: ViewContainerRef,
    private nextRef: TemplateRef<any>,
    private changes: ChangeDetectorRef
  ) {}


  @Input()
  set observe(source: Observable<T>) {
    if (!source) {
      return;
    }
    this.unsubscribe.next(true);
    this.init.pipe(
      concatMapTo(source),
      takeUntil(this.unsubscribe)
    ).subscribe(value => {
      this.view.clear();
      if (value) {
        this.view.createEmbeddedView(this.nextRef);
      }
      this.changes.markForCheck();
    });
  }

  ngOnInit() {
    this.init.next();
    this.init.complete();
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
  }
}

@Directive({
  selector: '[appIsLoggedIn]'
})
export class IsLoggedInDirective extends BaseAuthDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthService,
    private viewContainer: ViewContainerRef,
    changes: ChangeDetectorRef
  )  {
    super(viewContainer, templateRef, changes);
    this.observe = this.authService.loggedIn;
  }
}

@Directive({
  selector: '[appIsNotLoggedIn]'
})
export class IsNotLoggedInDirective extends BaseAuthDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthService,
    private viewContainer: ViewContainerRef,
    changes: ChangeDetectorRef
  )  {
    super(viewContainer, templateRef, changes);
    this.observe = this.authService.loggedIn.pipe(map(b => !b));
  }
}

@Directive({
  selector: '[appIsAdmin]'
})
export class IsAdminDirective extends BaseAuthDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthService,
    private viewContainer: ViewContainerRef,
    changes: ChangeDetectorRef
  )  {
    super(viewContainer, templateRef, changes);
    this.observe = this.authService.admin;
  }
}

@Directive({
  selector: '[appIsNotAdmin]'
})
export class IsNotAdminDirective extends BaseAuthDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private authService: AuthService,
    private viewContainer: ViewContainerRef,
    changes: ChangeDetectorRef
  )  {
    super(viewContainer, templateRef, changes);
    this.observe = this.authService.admin.pipe(
      map(admin => !admin),
    );
  }
}
