<div id="privacy-view-wrapper" role="main">
  <mat-card class="privacy-tile">
    <mat-card-title role="heading" aria-level="2">{{'privacy.ssnapsight' | translate}}</mat-card-title>
    <mat-card-content>
      <p>
        {{'privacy.the-chart' | translate}}
      </p>
        <h3>{{'privacy.who-is-responsible' | translate}}</h3>
      <p>
        {{'privacy.data-limited' | translate}}<br>
        {{'privacy.data-email' | translate}} <a href="mailto: kolev@rinisoft.com">kolev@rinisoft.com</a>
      </p>
      <h3>{{'privacy.what-personal' | translate}}</h3>
      <p>
        {{'privacy.we-will-collect' | translate}}
      </p>
      <h3>{{'privacy.why-is-the-date' | translate}}</h3>
      <p>
        {{'privacy.data-will-be' | translate}}
      </p>
      <ol>
        <li>{{'privacy.providing' | translate}}</li>
        <li>{{'privacy.communication' | translate}}
          <ol type="a">
            <li>{{'privacy.between-citizens' | translate}}</li>
            <li>{{'privacy.between-event' | translate}}</li>
          </ol>
        </li>
        <li>{{'privacy.statistics' | translate}}
        <ol type="a">
          <li>{{'privacy.number-of-registered' | translate}}</li>
          <li>{{'privacy.number-of-incidents' | translate}}</li>
          <li>{{'privacy.data-being-accessed' | translate}}</li>
        </ol>
        </li>
        <li>{{'privacy.sharing-information' | translate}}</li>
        <li>{{'privacy.audits' | translate}}</li>
        <li>{{'privacy.receive' | translate}}</li>
      </ol>
      <h3>{{'privacy.on-what-basis' | translate}}</h3>
      <ol>
        <li>{{'privacy.providing-guidance' | translate}}</li>
        <li>{{'privacy.communication-legal' | translate}}</li>
        <li>{{'privacy.statistics-legal' | translate}}</li>
        <li>{{'privacy.sharing-information-legal' | translate}}</li>
        <li>{{'privacy.audits-legal' | translate}}</li>
        <li>{{'privacy.receive-live' | translate}}</li>
      </ol>
      <p>
        {{'privacy.your-personal-data' | translate}}
      </p>
      <h3>{{'privacy.who-is-your-data' | translate}}</h3>
      <p>
        {{'privacy.during' | translate}}
      </p>
      <h3>{{'privacy.what-are-your' | translate}}</h3>
      <p>
        {{'privacy.as-a-resident' | translate}}
        <a href="mailto: kolev@rinisoft.com">kolev@rinisoft.com</a>.
      </p>
      <p>
        {{'privacy.you-should-attach' | translate}}
      </p>
    </mat-card-content>
  </mat-card>
  <br/>
  <mat-card class="cookie-tile">
    <mat-card-title role="heading" aria-level="2">{{'privacy.cookies' | translate}}</mat-card-title>
    <mat-card-content>
      <h3>{{'privacy.does' | translate}}</h3>
      <p>
        {{'privacy.yes' | translate}}
      </p>
      <h3>{{'privacy.understanding' | translate}}</h3>
      <p>
        {{'privacy.as-is-common' | translate}}
      </p>
      <h3>{{'privacy.the-cookies' | translate}}</h3>
      <p>
        {{'privacy.we-use' | translate}}
      </p>
      <h3>{{'privacy.what-are' | translate}}</h3>
      <p>
        {{'privacy.our-website' | translate}}
      </p>
      <h3>{{'privacy.more-information' | translate}}</h3>
      <p>
        {{'privacy.we-hope' | translate}}
      </p>
      <p>
        {{'privacy.if-you-are' | translate}}
        <a href="mailto: kolev@rinisoft.com">kolev@rinisoft.com</a>
      </p>
    </mat-card-content>
  </mat-card>
</div>
