import {Component, Input, OnInit} from '@angular/core';
import {MapLocation} from '../../models/map-location';
import {MapLoaderService} from '../../services/map-loader.service';

@Component({
  selector: 'app-location-view',
  templateUrl: './location-view.component.html',
  styleUrls: ['./location-view.component.scss']
})
export class LocationViewComponent implements OnInit {
  @Input() location: MapLocation;
  @Input() showCoords = false;

  constructor(public mapLoader: MapLoaderService) { }

  ngOnInit(): void {
  }
}
