import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NewsModel} from '../../models/news-model';
import {NewsService} from '../../services/news.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource<NewsModel>(null);
  displayedColumns: string[] = ['id', 'created', 'title', 'actions'];
  filterValue: string;
  private listUnsubscribe = new Subject<boolean>();

  messages: { message: string, type: string }[];

  constructor(private newsService: NewsService) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.newsService.list$().pipe(takeUntil(this.listUnsubscribe)).subscribe(data => {
      this.dataSource.data = data;
      this.applyFilter();
    });
  }

  ngOnDestroy(): void {
    this.listUnsubscribe.next(true);
    this.listUnsubscribe.complete();
  }

  setFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.applyFilter();
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue;
  }

  get hasData(): boolean {
    return this.dataSource.data !== null;
  }

  deleteItem(id: number) {
    this.newsService.delete(id).subscribe(
      resp => {
        this.loadData();
        return resp;
      }
    );
  }
}
