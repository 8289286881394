import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {EIncidentStatus, EIncidentStatusLookup} from '../../models/incident-status.enum';
import {IncidentService} from '../../services/incident.service';
import {EIncidentClassification, EIncidentClassificationLookup} from '../../models/incident-classification.enum';
import {Incidentform} from '../../models/incidentform.model';
import {NotificationService} from '../../services/notification.service';
import {ToastrService} from 'ngx-toastr';
import {IncidentUpdateService} from '../../services/incident-update.service';
import {TranslateService} from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { IncidentModel } from 'src/app/models/incident-model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubmissionModel } from 'src/app/models/submission-model';
import { SubmissionsService } from 'src/app/services/submissions.service';

@Component({
  selector: 'app-incident-create',
  templateUrl: './incident-submission-create.component.html',
  styleUrls: ['./incident-submission-create.component.scss']
})
export class IncidentSubmissionCreateComponent implements OnInit {
  form: UntypedFormGroup
  incidentSubmission: Incidentform;
  addUpdate = false;
  resolvedIncident: IncidentModel = null;
  submissionId: number = null;
  submission: SubmissionModel = null;
  error: string;

  private unsubscribe = new Subject<boolean>();

  constructor(private router: Router, private route: ActivatedRoute, private submissions: SubmissionsService, private service: IncidentService, private toastr: ToastrService, private incidents: IncidentService,
              private updateService: IncidentUpdateService, private notificationService: NotificationService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.loadSubmission();
  const currentDateTime = new Date(Date.now());

    this.form = new UntypedFormGroup({
        location: new UntypedFormControl(
          '',
        ),
        incidentTime: new UntypedFormControl(
          `${String(currentDateTime.getHours()).padStart(2, '0')}:${String(currentDateTime.getMinutes()).padStart(2, '0')}`,
          [Validators.required]
        ),
        incidentDate: new UntypedFormControl(
          currentDateTime,
          [Validators.required]
        ),
        status: new UntypedFormControl('', [Validators.required]),
        classification: new UntypedFormControl('', [Validators.required]),
        headline: new UntypedFormControl('', [Validators.required]),
        description: new UntypedFormControl('', [Validators.required]),
        photos: new UntypedFormControl(),
        text: new UntypedFormControl(''),
        resolved: new UntypedFormControl(),
        makePublic: new UntypedFormControl(),
        sendNotification: new UntypedFormControl('', [Validators.required]),
      });
  }

  private loadSubmission() {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
        if (params.has('id')) {
          this.submissionId = parseInt(params.get('id'), 10);
          this.submissions.get$(this.submissionId).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
              this.submission = data;

            if (this.submission.incidentId) {
              this.incidents.get$(this.submission.incidentId).pipe(takeUntil(this.unsubscribe)).subscribe(incidentData => {
                this.resolvedIncident = incidentData;
              });
            }
          });
        } else {
          this.error = 'No Submission ID found in URL';
        }
      });
    
  }

  get incidentStatuses(): { id: number, name: string }[] {
    return Object.keys(EIncidentStatus).filter(k => typeof EIncidentStatus[k] === 'number').map(type => {
      const id = EIncidentStatus[type];

      return {
        id,
        name: EIncidentStatusLookup.get(id).name
      };
    });
  }

  get incidentClassifications(): { id: number, name: string }[] {
    return Object.keys(EIncidentClassification).filter(k => typeof EIncidentClassification[k] === 'number').map(type => {
      const id = EIncidentClassification[type];

      return {
        id,
        name: EIncidentClassificationLookup.get(id).name
      };
    });
  }

  showUpdateForm() {
    this.addUpdate = !this.addUpdate;
    if (!this.addUpdate) {
      this.form.get('text').clearValidators();
      this.form.get('text').updateValueAndValidity();
    } else if (this.form.contains('text')) {
      this.form.get('text').setValidators(Validators.required);
      this.form.get('text').updateValueAndValidity();
    }
  }

  trackType(index: number, item: { id: number, name: string }): any {
    return item.id;
  }

  submitIncident() {
    if (!this.form.valid) {
      return false;
    }

    // Combining Time and Date inputs before submitting
    const date: Date = this.form.controls.incidentDate.value;
    let timeValue = 0;

    timeValue += this.form.controls.incidentTime.value.substring(0, 2) * 3600000;
    timeValue += this.form.controls.incidentTime.value.substring(3) * 60000;

    date.setHours(0, 0, 0, 0);

    const occurred = new Date(date.getTime() + timeValue);

    this.incidentSubmission = new Incidentform(
      this.form.controls.status.value,
      this.form.controls.classification.value,
      occurred,
      this.form.controls.headline.value,
      this.submission.description,
      {
        lat: this.submission.location.lat,
        lng: this.submission.location.lng,
        streetAddress: this.submission.location.streetAddress
      },
      this.form.controls.sendNotification.value
    );

    if (this.form.controls.sendNotification.value !== '1') {
      this.incidentSubmission.sendNotification = false;
    } else {
      this.incidentSubmission.sendNotification = true
    }

    this.service.create(this.incidentSubmission).subscribe(response => {
      if (this.addUpdate) {
        const data = {
          incident: {id: response},
          description: this.form.get('text').value,
          photos: [],
          isPublic: this.form.get('makePublic').value,
          sendNotification: false
        };

        if (data.isPublic == null) {
          data.isPublic = false;
        }

        const photoFiles = this.form.get('photos').value?.map(f => f.data as Promise<string>) || [];
        Promise.all(photoFiles).then(
          photos => {
            data.photos = photos;

            this.updateService.share(response, data).subscribe();
          }
        );
      }
      this.router.navigateByUrl(
        'incidents'
      );
      }, err => {
        console.error(err);
        this.toastr.error(this.translate.instant('toast-notification.incident-create.error.message'),
          this.translate.instant('toast-notification.incident-create.error.title'), {
          timeOut: 10000,
          positionClass: 'toast-bottom-center',
          closeButton: true
        });
      },
      () => {
        this.toastr.success(this.translate.instant('toast-notification.incident-create.success.message'),
          this.translate.instant('toast-notification.incident-create.success.title'), {
          timeOut: 10000,
          positionClass: 'toast-bottom-center',
          closeButton: true
        });
      });
  }
}
