<div id="news-create-wrapper" role="main">
  <mat-card id="news-create-container">
    <mat-card-title role="heading" aria-level="2">{{'news-create.create-news-item' | translate}}</mat-card-title>
    <app-news-form [form]="form"></app-news-form>
    <br>
    <div class="right">
      <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="createNewsItem()">{{'news-create.create-news-item' | translate}}</button>
    </div>
  </mat-card>
</div>
