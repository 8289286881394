import { Injectable } from '@angular/core';
import {Observable, Subscription, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {handleHttpError} from '../utils/http-error-catcher';
import {IncidentUpdateModel} from '../models/incident-update-model';

interface IncidentUpdateDetailResponseModel {
  id: number;
  datetime: Date;
  submitter: string;
  description: string;
  photos: string[];
  isPublic: boolean;
  sendNotification: boolean;
}

interface IncidentUpdateCreateModel {
  description: string;
  photos: string[];
  isPublic: boolean;
  sendNotification: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class IncidentUpdateService {
  private listMap: Map<number, Observable<IncidentUpdateModel[]>> = new Map();
  private listCompletedMap: Map<number, boolean> = new Map();
  private listCompletedSubscriptionMap: Map<number, Subscription> = new Map();

  constructor(private http: HttpClient, private auth: AuthService) { }

  private mapDetailResponse(resp: IncidentUpdateDetailResponseModel): IncidentUpdateModel {
    return IncidentUpdateModel.fromObject({
      ...resp,
    });
  }

  list$(incidentId: number): Observable<IncidentUpdateModel[]> {
    let obs = this.listMap.get(incidentId);
    if (!obs || this.listCompletedMap.get(incidentId)) {
      this.listCompletedMap.set(incidentId, false);
      obs = this.http.get<IncidentUpdateModel[]>(`incidents/${incidentId}/updates`).pipe(
        catchError(handleHttpError),
        map(array => array.map(this.mapDetailResponse)),
        shareReplay(1)
      );
      this.listMap.set(incidentId, obs);

      const subscription = obs.subscribe({ complete: this.onListCompleted(incidentId) });
      this.listCompletedSubscriptionMap.set(incidentId, subscription);
    }

    if (!obs) {
      return throwError('Not found');
    }

    return obs;
  }

  onListCompleted(id: number): () => void {
    return () => {
      this.listCompletedMap.set(id, true);
      setTimeout(() => this.listCompletedSubscriptionMap.get(id).unsubscribe(), 0);
    };
  }

  share(incidentId: number, data: IncidentUpdateCreateModel): Observable<boolean> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Create Incidents.');
    }

    const body = {
      ...data,

    };

    return this.http.post<IncidentUpdateModel>(`incidents/${incidentId}/updates`, body).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }
}
