<div id="statistics-wrapper" class="user-tile" role="main" *ngIf="isDataLoaded">
    <div  id="user-list-container" class="user-tile"> 
        <h1 role="heading" aria-level="2">{{'statistics.header' | translate}}</h1>
        <app-bar-chart></app-bar-chart>
        <div class="statistics-content">
        <div>{{'statistics.users' | translate}}
          <p>{{statistics.registeredUsers}}</p>
        </div>
        <div>{{'statistics.downloads' | translate}}
          <p>{{statistics.numberOfDownloads}}</p>
        </div>
        <div>{{'statistics.incidents' | translate}}
          <p>{{statistics.reportedIncidents}}</p>
        </div>
        <div>{{'statistics.notifications' | translate}}
          <p>{{statistics.sendedPushNotifications}}</p>
        </div>
    </div>
  </div>
  </div>
  