<div id="submission-view-wrapper" class="submission-tile" role="main">
  <div id="submission-list-container" class="submission-tile">
    <mat-card>
      <mat-card-title role="heading" aria-level="2">{{'user-submission-list.public' | translate }}</mat-card-title>
      <app-messages></app-messages>
      <div class="right">
        <button mat-raised-button color="primary" routerLink="/incidents/new">{{'user-submission-list.create' | translate }}</button>
      </div>
      <mat-card-title role="heading" aria-level="2">{{'user-submission-list.incident-map' | translate}}</mat-card-title>
      <app-incident-map
        [incidents]="dataSource.filteredData"
        [loading]="!dataSource.data">
      </app-incident-map>
    </mat-card>
    <br>
    <mat-card>
      <mat-card-content *ngIf="!hasData">
        <mat-spinner class="centered" aria-label="Loading User Submission List"></mat-spinner>
      </mat-card-content>
      <mat-card-content *ngIf="hasData">
        <mat-form-field id="filter-submissions">
          <mat-label>{{'user-submission-list.filter' | translate }}</mat-label>
          <input matInput (keyup)="setFilter($event)" placeholder="{{'user-submission-list.filter-prompt' | translate }}">
        </mat-form-field>
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="overflow-x: scroll; display: grid; grid-template-rows: 1fr;">

          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>{{'user-submission-list.submission-id' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
          </ng-container>

          <!-- Location Column -->
          <ng-container matColumnDef="location">
            <mat-header-cell *matHeaderCellDef>{{'user-submission-list.location' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.location.streetAddress | translate }}</mat-cell>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef>{{'user-submission-list.description' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
          </ng-container>

          <!-- Submitted Column -->
          <ng-container matColumnDef="submitted">
            <mat-header-cell *matHeaderCellDef>{{'user-submission-list.submitted-at' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.submitted | date: 'yyyy-MM-dd' }} at {{element.submitted | date: 'HH:mm'}}</mat-cell>
          </ng-container>

          <!-- Controls Column -->
          <ng-container matColumnDef="controls">
            <mat-header-cell *matHeaderCellDef>{{'user-submission-list.view-submission' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a [routerLink]="element.id">
                <button mat-raised-button color="primary"><mat-icon>visibility</mat-icon> {{'user-submission-list.view' | translate }}</button>
              </a>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
        </mat-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
