import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {filter, map, mergeMap, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {FocusOrigin} from '@angular/cdk/a11y';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-layout-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit, OnDestroy {
  language: string;
  private baseTitle = 'SSNapSight';
  routeTitle$: Observable<string> = null;
  private routeTitleUnsubscribe = new Subject<boolean>();
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('menuElement') menuElement: MatMenu;
  @Input() fixed = false;
  @Input() disabled = false;

  constructor(private auth: AuthService, private router: Router, private activatedRoute: ActivatedRoute,
              private titleService: Title, public translate: TranslateService) { }


  ngOnInit() {
    const lang = localStorage.getItem('lang');
    this.language = lang ? lang : this.translate.defaultLang;
    this.translate.setDefaultLang(this.language);
    const route$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.router.routerState.root),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),
    );

    this.routeTitle$ = route$.pipe(
      map(({ title }) => title),
      takeUntil(this.routeTitleUnsubscribe)
    );

    this.routeTitle$.subscribe(title => {
      if (title) {
        const assembledTitle = `${this.baseTitle} - ${title}`;
        this.titleService.setTitle(assembledTitle);
      } else {
        this.titleService.setTitle(this.baseTitle);
      }
    });
  }

  changeLanguage() {
    localStorage.setItem('lang', this.language);
  }

  ngOnDestroy(): void {
    this.routeTitleUnsubscribe.next(true);
    this.routeTitleUnsubscribe.complete();
  }

  logOut() {
    this.auth.logout();
    this.router.navigateByUrl('');
  }

  goToLogin() {
    this.router.navigateByUrl('login', {
      state: {
        requestedUrl: this.router.routerState.snapshot.url
      }
    });
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }

  onMenuItemTab(event: KeyboardEvent): boolean {
    const node = event.target as Element;

    if (node !== node.parentElement.lastElementChild) {
      event.stopPropagation();
    }

    return true;
  }

  onMenuItemShiftTab(event: KeyboardEvent): boolean {
    const node = event.target as Element;

    if (node !== node.parentElement.firstElementChild) {
      event.stopPropagation();
    }

    return true;
  }

}
