<mat-card-content [formGroup]="formGroup" [ngClass]="{'publicUpdateIndicator': formGroup.controls.makePublic.value}">
  <div class="update-form">
    <div id="photo-upload">
      <label for="photoUpload">{{'incident-update.photos' | translate}}</label>
      <br/>
      <app-photo-upload formControlName="photos" id="photoUpload" [mediaTypes]="types"></app-photo-upload>
    </div>
    <mat-form-field appearance="fill" class="incident-info" id="incident-info-text">
      <label for="incident-info-text">{{'incident-update.information' | translate}}</label>
      <textarea matInput type="textarea" formControlName="text" rows="5" placeholder="{{'incident-update.information-prompt' | translate}}"></textarea>
      <mat-error>{{'incident-update.text-field-cannot-be-empty' | translate}}</mat-error>
    </mat-form-field>
    <mat-checkbox formControlName="resolved" id="resolveCheck" aria-label="check to mark incident as resolved">{{'incident-update.mark-as-resolved' | translate}}</mat-checkbox>
    <br>
    <mat-checkbox formControlName="makePublic" id="publicCheck" aria-label="check to make update as public">{{'incident-update.make-update-public' | translate}}</mat-checkbox>
  </div>
</mat-card-content>
