import { Component, OnInit } from '@angular/core';

export class FaqModel {
  id: string;
  question: string;
  answer: string;

  constructor(id: string, question: string, answer: string) {
    this.id = id;
    this.question = question;
    this.answer = answer;
  }
}


@Component({
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  questions = [
    new FaqModel('question-1', 'faq.questions.what-is-ssnapsight.question', 'faq.questions.what-is-ssnapsight.answer'),
    new FaqModel('question-2', 'faq.questions.app-accessible.question', 'faq.questions.app-accessible.answer'),
    new FaqModel('question-3', 'faq.questions.newest-incidents.question', 'faq.questions.newest-incidents.answer'),
    new FaqModel('question-4', 'faq.questions.live-news-feed.question', 'faq.questions.live-news-feed.answer'),
    new FaqModel('question-5', 'faq.questions.incident-classification.question', 'faq.questions.incident-classification.answer'),
    new FaqModel('question-6', 'faq.questions.incident-type.question', 'faq.questions.incident-type.answer'),
    new FaqModel('question-7', 'faq.questions.incident-status.question', 'faq.questions.incident-status.answer'),
    new FaqModel('question-8', 'faq.questions.notifications.question', 'faq.questions.notifications.answer'),
    new FaqModel('question-9', 'faq.questions.data-stored.question', 'faq.questions.data-stored.answer'),
    new FaqModel('question-10', 'faq.questions.share-information-button.question', 'faq.questions.share-information-button.answer'),
    new FaqModel('question-11', 'faq.questions.forgot-password.question', 'faq.questions.forgot-password.answer'),
    new FaqModel('question-12', 'faq.questions.photos-upload.question', 'faq.questions.photos-upload.answer'),
    new FaqModel('question-13', 'faq.questions.no-incidents.question', 'faq.questions.no-incidents.answer'),
    new FaqModel('question-14', 'faq.questions.share-information-cant-submit.question',
      'faq.questions.share-information-cant-submit.answer')
  ];

  constructor() { }

  ngOnInit() {
  }

}
