import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Incidentform} from '../../models/incidentform.model';
import {UploadedFile} from '../photo-upload/photo-upload.component';

@Component({
  selector: 'app-incident-update',
  templateUrl: './incident-update.component.html',
  styleUrls: ['./incident-update.component.scss', '../../pages/incident-detail/incident-detail.component.scss']
})
export class IncidentUpdateComponent implements OnInit {
  incidentResolved: Incidentform;
  types = ['.jpeg', '.png', '.mov', '.mp4', '.jpg'];

  @Input() formGroup: UntypedFormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  get canSubmit(): boolean {
    return this.formGroup.valid;
  }

  setPhotos(photos: UploadedFile[]): void {
    this.formGroup.patchValue({
      photos
    });
  }
}
