export class NewsModel {
  id: number;
  title: string;
  category: string;
  body: string;
  uri: string;
  imageUri: string;
  imageAlt: string;
  created: Date | null;

  constructor(id: number, title: string, category: string, body: string, uri: string, imageUri: string, imageAlt: string, created: Date) {
    this.id = id;
    this.title = title;
    this.category = category;
    this.body = body;
    this.uri = uri;
    this.imageUri = imageUri;
    this.imageAlt = imageAlt;
    this.created = created;
  }

  static fromObject(obj: { id: number, title: string, category: string, body: string, uri: string, imageUri: string,
    imageAlt: string, created: Date })
    : NewsModel {
    return new NewsModel(obj.id, obj.title, obj.category, obj.body, obj.uri, obj.imageUri, obj.imageAlt, obj.created);
  }
}
