import { Component, OnInit } from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {AdditionalInfoAdminService} from "../../services/additional-info-admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AdditionalInfoCategoryModel} from "../../models/AdditionalInfoCategory";
import {Subject} from "rxjs";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AdditionalInfoResponseModel} from "../../models/AdditionalInfoResponseModel";
import {ConfirmationDialogComponent} from "../dialog/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-cbrne-info-edit',
  templateUrl: './cbrne-info-edit.component.html',
  styleUrls: ['./cbrne-info-edit.component.scss']
})
export class CbrneInfoEditComponent implements OnInit {
  category: AdditionalInfoResponseModel;
  private unsubscribe = new Subject<boolean>();
  form: UntypedFormGroup;
  error: string = null;
  categoryId: number;
  submission: AdditionalInfoCategoryModel;
  displayedColumns: string[] = ['id', 'description', 'edit'];

  constructor(private service: AdditionalInfoAdminService, private route: ActivatedRoute,
              private formBuilder: UntypedFormBuilder, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.has('id')) {
        this.categoryId = parseInt(params.get('id'), 10);
        this.loadCategory();
      } else {
        this.error = 'No category ID found in URL';
      }
    });
  }

  private loadCategory(): void {
    this.service.get$(this.categoryId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        data => {
          this.category = data;
          this.form = new UntypedFormGroup({
            name: new UntypedFormControl(this.category.category, [Validators.required]),
            adminOnly: new UntypedFormControl(this.category.adminOnly, [Validators.required]),
          });
        }
      );
  }
  successfulPost(): void {
    this.router.navigateByUrl(
      'information',
      {state: {messages: [{type: 'success', message: 'The Category has been updated.'}] } }
    );
  }

  successfulDelete(): void {
    this.router.navigateByUrl(
      'information',
      {state: {messages: [{type: 'success', message: 'The Item has been deleted.'}] } }
    );
  }

  editCategory() {
    const results = this.form.controls;
    this.submission =  {
      name: results.name.value,
      adminOnly: results.adminOnly.value,
      id: this.categoryId
    };

    this.service.update$(this.submission)
      .subscribe(
        _ => this.successfulPost(),
        err => console.error(err)
      );

  }

  delete(id: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {data: {
          title: 'confirmation-dialog.delete-item.title',
          description: 'confirmation-dialog.delete-item.description',
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.deleteItem$(id).subscribe(
          () => this.successfulDelete(),
          err => console.error(err)
        );
      }
    });
  }

  back() {
    this.router.navigateByUrl(
      'information');
  }
}
