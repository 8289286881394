import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {AboutComponent} from './pages/about/about.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {IncidentListComponent} from './pages/incident-list/incident-list.component';
import {HeatmapComponent} from './pages/heatmap/heatmap.component';
import {RegisterComponent} from './pages/register/register.component';
import {ContactsComponent} from './pages/contacts/contacts.component';
import {FaqComponent} from './pages/faq/faq.component';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {UserListComponent} from './pages/user-list/user-list.component';
import {StatisticsComponent} from './pages/statistics/statistics.component';
import {UserCreateComponent} from './pages/user-create/user-create.component';
import {LoggedInGuard} from './guards/logged-in.guard';
import {AdminGuard} from './guards/admin.guard';
import {NotLoggedInGuard} from './guards/not-logged-in.guard';
import {IncidentCreateComponent} from './pages/incident-create/incident-create.component';
import { IncidentSubmissionCreateComponent } from './pages/incident-submission-create/incident-submission-create.component';
import {ShareInformationComponent} from './pages/share-information/share-information.component';
import {ProfileComponent} from './pages/profile/profile.component';
import {UserSubmissionListComponent} from './pages/user-submission-list/user-submission-list.component';
import {UserSubmissionDetailComponent} from './pages/user-submission-detail/user-submission-detail.component';
import {IncidentDetailComponent} from './pages/incident-detail/incident-detail.component';
import {IncidentEditComponent} from './pages/incident-edit/incident-edit.component';
import {ShareAfterComponent} from './pages/share-after/share-after.component';
import {NewsListComponent} from './pages/news-list/news-list.component';
import {NewsCreateComponent} from './pages/news-create/news-create.component';
import {NewsEditComponent} from './pages/news-edit/news-edit.component';
import {ResourcesComponent} from './pages/resources/resources.component';
import {ForgotPasswordComponent} from './pages/login/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './pages/login/reset-password/reset-password.component';
import {ConfirmEmailComponent} from './pages/register/confirm-email/confirm-email.component';
import {CBRNeInformationComponent} from './pages/cbrne-information/cbrne-information.component';
import {PreIncidentComponent} from './pages/pre-incident/pre-incident.component';
import {CbrneInfoEditComponent} from "./components/cbrne-info-edit/cbrne-info-edit.component";
import {AdditionalInfoSubmitComponent} from "./components/additional-info-submit/additional-info-submit.component";
import {CbrneInfoEditItemComponent} from "./components/cbrne-info-edit-item/cbrne-info-edit-item.component";
import {CbrneInfoAddItemComponent} from "./components/cbrne-info-add-item/cbrne-info-add-item.component";
import {CbrneAddFileComponent} from "./components/cbrne-add-file/cbrne-add-file.component";
import {CbrneWebViewComponent} from "./components/cbrne-web-view/cbrne-web-view.component";


const routes: Routes = [
  {path: 'register', canActivate: [NotLoggedInGuard], component: RegisterComponent, data: { title: 'Register' }},
  {path: 'confirm-email', component: ConfirmEmailComponent, data: { title: 'Confirm Email' }},

  {path: 'login', canActivate: [NotLoggedInGuard], component: LoginComponent, data: { title: 'Log In' }},
  {path: 'forgot-password', canActivate: [NotLoggedInGuard], component: ForgotPasswordComponent, data: { title: 'Forgot Password' }},
  {path: 'reset-password', canActivate: [NotLoggedInGuard], component: ResetPasswordComponent, data: { title: 'Reset Password' }},

  {path: 'dashboard', redirectTo: ''},
  {path: '',  canActivate: [LoggedInGuard], component: DashboardComponent, data: { title: 'Dashboard' }},
  {path: 'profile', canActivate: [LoggedInGuard], data: { title: 'Profile' },
    children: [
      {path: '', component: ProfileComponent, data: { title: 'View your own profile' }},
      {path: ':id', component: ProfileComponent, data: { title: 'View another User profile' }},
    ]},

  {path: 'share', canActivate: [LoggedInGuard], children: [
      {path: '', component: ShareInformationComponent, data: { title: 'Share Incident Information' }},
      {path: 'after', component: ShareAfterComponent, data: { title: 'Thank You for Sharing' }}
  ]},


  {path: 'incidents', canActivate: [LoggedInGuard], children: [
      {path: '', component: IncidentListComponent, data: { title: 'Incidents' }},
      {path: 'new', canActivate: [LoggedInGuard, AdminGuard], component: IncidentCreateComponent, data: { title: 'Create Incident' }},
      {path: ':id/edit', canActivate: [LoggedInGuard, AdminGuard], component: IncidentEditComponent, data: { title: 'Edit Incident' }},
      {path: ':id/share', canActivate: [LoggedInGuard], component: ShareInformationComponent, data:
          { title: 'Share Information about an Incident' }},
      {path: ':id', component : IncidentDetailComponent, data: { title: 'Incident Details'}},
    ],
  },

  {path: 'map-heatmap-layer',
    canActivate: [LoggedInGuard, AdminGuard],
    children: [
      {path: '', component: HeatmapComponent, data: { title: 'Heatmap' }},
    ]
  },

  {
    path: 'information', canActivate: [LoggedInGuard], children: [
      {path: '', component: CBRNeInformationComponent, data: { title: 'Additional CBRNe Information'}},
      {path: 'add', canActivate: [LoggedInGuard, AdminGuard], component: AdditionalInfoSubmitComponent, data: { title: 'Add Category' }},
      {path: ':id/web', component: CbrneWebViewComponent, data: { title: 'View File' }},
      {path: ':id/edit', canActivate: [LoggedInGuard, AdminGuard], children: [
          {path: '', component: CbrneInfoEditComponent, data: { title: 'Edit Category' }},
          {path: 'add', component: CbrneInfoAddItemComponent, data: { title: 'Add Item' }},
          {path: ':itemid/edit', children: [
              {path: '', component: CbrneInfoEditItemComponent, data: { title: 'Edit Item' }},
              {path: 'add', component: CbrneAddFileComponent, data: { title: 'Add file'}},
            ]},
        ]},
    ],
  },

  {path: 'users',
    canActivate: [LoggedInGuard, AdminGuard],
    canActivateChild: [LoggedInGuard, AdminGuard],
    children: [
      {path: '', component: UserListComponent, data: { title: 'User List' }},
      {path: 'new', component: UserCreateComponent, data: { title: 'Create User' }}
    ]
  },

  {path: 'statistics',
    canActivate: [LoggedInGuard, AdminGuard],
    children: [
      {path: '', component: StatisticsComponent, data: { title: 'Statistics' }},
    ]
  },

  {path: 'news',
    canActivate: [LoggedInGuard, AdminGuard],
    canActivateChild: [LoggedInGuard, AdminGuard],
    children: [
      {path: '', component: NewsListComponent, data: { title: 'News List' }},
      {path: 'new', component: NewsCreateComponent, data: { title: 'Create News Item' }},
      {path: ':id', component: NewsEditComponent, data: { title: 'Edit News Item' }}
    ]
  },

  {path: 'submissions',
    canActivate: [LoggedInGuard, AdminGuard],
    canActivateChild: [LoggedInGuard, AdminGuard],
    children: [
      {path: '', component: UserSubmissionListComponent, data: { title: 'User Submission List' }},
      {path: ':id', component: UserSubmissionDetailComponent, data: { title: 'User Submission Details' }},
      {path: ':id/new-incident-submission', component: IncidentSubmissionCreateComponent, data: { title: 'Create Incident from Submission' }}
    ]
  },

  {path: 'contacts', canActivate: [LoggedInGuard], component: ContactsComponent, data: { title: 'Contacts' }},
  {path: 'about', canActivate: [LoggedInGuard], component: AboutComponent, data: { title: 'About the Project' }},
  {path: 'faq', canActivate: [LoggedInGuard], component: FaqComponent, data: { title: 'Frequently Asked Questions' }},
  {path: 'privacy', canActivate: [LoggedInGuard], component: PrivacyComponent, data: { title: 'Privacy Policy' }},
  {path: 'resources', canActivate: [LoggedInGuard], component: ResourcesComponent, data: { title: 'Other Resources' }},
  {path: 'pre-incident', canActivate: [LoggedInGuard], component: PreIncidentComponent, data: { title: 'Public pre-incident communication information sheet'}},

  // {path: 'logout', component: LogoutComponent},

  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
