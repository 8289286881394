export class NotificationModel {
  title: string;
  id: number;

  constructor(headline: string, id: number) {
    this.title = headline;
    this.id = id;
  }

  static fromObject(obj: { headline: string, id: number }): NotificationModel {
    return new NotificationModel(obj.headline, obj.id);
  }
}
