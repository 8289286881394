import {Component, Inject, OnInit} from '@angular/core';
import {NotificationService} from '../../services/notification.service';
import {takeUntil} from 'rxjs/operators';
import {UserModel} from '../../models/user-model';
import {AuthService} from '../../services/auth.service';
import {Subject} from 'rxjs';
import {OneSignalService} from 'ngx-onesignal';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
  test: boolean;
  profile: UserModel;
  private unsubscribe = new Subject<boolean>();

  constructor(private notificationService: NotificationService,
              @Inject(OneSignalService)public oneSignal: OneSignalService,
              private authService: AuthService) {(window as any).ngxOnesignal = this.oneSignal; }

  ngOnInit() {
    this.authService.profile$.pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.profile = data;
    });
  }

  sendTest() {
    const id = this.oneSignal.userId;
    return this.notificationService.sendTest(id).subscribe(
      _ => this.test,
      err => console.error(err)
    );
  }

  toggleSubscription() {
    this.oneSignal.isSubscribe$.subscribe(next => this.test = next);
    if (this.test) {
      this.oneSignal.unsubscribe();
    } else if (!this.test) {
      this.oneSignal.subscribe();
      this.oneSignal.isSubscribe$.subscribe(next => next && this.oneSignal.push(['sendTag', 'id', this.profile.id.toString()]));
    }
    if (!("Notification" in window)) {
      alert("This browser does not support desktop notification");
    }
    else if (Notification.permission === "denied") {
      alert('Disabled in the browser');
    }
    
  }
}
