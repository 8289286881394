<div fxLayout="row wrap" fxLayoutAlign="start space-between" fxLayoutGap="0 grid">
  <div id="partner-list" fxLayout="column wrap" fxLayoutAlign="space-around stretch" fxLayoutGap="1em grid"
       fxFlex.gt-md="1 1 33%"
       fxFlex.md="1 1 33%"
       fxFlex.sm="50%" [ngStyle.sm]="{'height': '35em'}"
       fxFlex.xs="100%">
    <app-consortium-partner
      *ngFor="let partner of partners"
      (partnerSelected)="selectPartner($event)"
      [highlight]="selectedPartner && selectedPartner === partner"
      [partner]="partner">
    </app-consortium-partner>
  </div>
  <div fxFlex.gt-md="1 0 66%" fxFlex.md="2 0 66%" fxFlex.sm="100%" fxFlexAlign="stretch" [ngStyle.lt-md]="{'margin-top': '2em'}">
    <div id="map-background">
      <img id="map" src="assets/images/consortium-map.png" alt="A map showing the Proactive Consortium Members, with colour coded countries." />
    </div>
  </div>
</div>
