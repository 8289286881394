import {Injectable, NgZone} from '@angular/core';
import * as signalR from '@microsoft/signalr';
import {environment} from '../../environments/environment';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {FallbackNotificationComponent} from '../components/fallback-notification/fallback-notification.component';
import {Observable, ReplaySubject} from 'rxjs';
import {catchError, map} from "rxjs/operators";
import {handleHttpError} from "../utils/http-error-catcher";
import {HttpClient} from "@angular/common/http";
import {OneSignalModel} from "../models/OneSignalModel";

const LOCAL_STORAGE_KEY = 'notificationsEnabled';


class NotificationModel {
  title: string;
  body: string;
  uri: string;
  id: string;
}


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private hubConnection: signalR.HubConnection;
  private connectionStarted = false;
  private $notificationsEnabled = new ReplaySubject<boolean>(1);
  lastNotification: NotificationModel = null;
  lastNotificationTime: Date = null;

  constructor(private snackbar: MatSnackBar, private zone: NgZone, private http: HttpClient) {
    setTimeout(() => {
      this.connect();
    }, 0);

    let enabled;
    try {
      enabled = localStorage.getItem(LOCAL_STORAGE_KEY);
    } catch (e) {
      console.error('Failed to read', LOCAL_STORAGE_KEY, 'from localstorage');
      enabled = 'false';
    }
    if (this.notificationsSupported) {
      this.setNotificationsEnabled(Notification.permission === 'granted' && enabled === 'true');
    } else {
      this.setNotificationsEnabled(enabled === 'true');
    }
  }

  private setNotificationsEnabled(value: boolean) {
    this.zone.run(() => {
      this.$notificationsEnabled.next(value);
    });
  }

  get notificationsSupported(): boolean {
    return 'Notification' in window;
  }

  get notificationsEnabled(): Observable<boolean> {
    return this.$notificationsEnabled.asObservable();
  }

  onNotification(message: NotificationModel) {
    this.makeNotification(message);
    this.lastNotification = message;
    this.lastNotificationTime = new Date();
  }

  makeNotification(message: NotificationModel) {
    const title = message.title;
    const options = {
      body: message.body || undefined,
      tag: message.id,
      renotify: true,
      data: {
        uri: message.uri || undefined,
      }
    };

    if (!this.notificationsEnabled) {
      return;
    }

    if (this.notificationsSupported) {
      navigator.serviceWorker.getRegistration().then(
        sw => {
          try {
            const notification = this.makeNotificationWindow(title, options);
            this.addNotificationClickHandlerWindow(notification);
          } catch (e) {
            if (e.name === 'TypeError') {
              try {
                setTimeout(() => this.makeNotificationServiceWorker(sw, title, options), 100);
              } catch (e) {
                console.error('Cannot notify', e);
              }
            }
          }
        }
      );
    } else {
      this.makeNotificationFallback(title, options);
    }
  }

  addNotificationClickHandlerWindow(notification: Notification) {
    notification.onclick = (event) => {
      const target = event.target as Notification;
      if (target.data) {
        const data = target.data as NotificationModel;
        if (data.uri) {
          window.location.href = data.uri;
        }
      }
    };
  }

  makeNotificationServiceWorker(sw: ServiceWorkerRegistration, title: string, options: object): Promise<Notification> {
    return sw.showNotification(title, options).then(
      n => sw.getNotifications()
    ).then(
      list => {
        if (list.length > 0) {
          return list[list.length - 1];
        }
        return null;
      }
    );
  }

  makeNotificationWindow(title: string, options: object): Notification {
    return new Notification(title, options);
  }

  makeNotificationFallback(title: string, options: any) {
    const config: MatSnackBarConfig = {};
    options.title = title;

    config.announcementMessage = title;
    config.politeness = 'assertive';
    config.data = options;
    config.verticalPosition = 'bottom';
    config.horizontalPosition = 'center';

    this.zone.run(() => {
      this.snackbar.openFromComponent(FallbackNotificationComponent, config);
    });
  }

  connect() {
    if (!this.hubConnection) {
      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(environment.apiUrl.replace('/api', '/hub/notifications'))
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: retryContext => {
            return Math.min(20000, retryContext.previousRetryCount * 5000);
          }
        })
        .build();

      this.hubConnection.on('notify', this.onNotification.bind(this));
    }

    if (!this.connectionStarted) {
      this.hubConnection
        .start()
        .then(() => {
          console.log('Connection to Notifications Started');
          this.connectionStarted = true;
        })
        .catch(err => console.error('Error while connecting to Notifications', err));
    }
  }

  sendTest(id: string): Observable<boolean> {
    const body = new OneSignalModel(id);

    return this.http.post<string>(`NotificationSubscriptions/test`, body).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }
}
