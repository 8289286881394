import {ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

export interface UploadedFile {
  file: any;
  thumbnail: SafeUrl;
  data: Promise<any>;
}

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PhotoUploadComponent),
  multi: true
};

@Component({
  selector: 'app-photo-upload',
  templateUrl: './photo-upload.component.html',
  styleUrls: ['./photo-upload.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR]
})
export class PhotoUploadComponent implements OnInit, ControlValueAccessor {
  @Input() id = 'photoUpload';
  @Input() mediaTypes: string[] = ['.png', '.jpeg', '.mov', 'mp4', '.jpg'];
  @Output() changed = new EventEmitter<UploadedFile[]>();
  files: UploadedFile[];
  @ViewChild('fileInput')
  input: ElementRef;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(private changeDetector: ChangeDetectorRef, private domSanitizer: DomSanitizer, private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  writeValue(obj: any): void {
    if (this.input) {
      this.input.nativeElement.value = null;
    }
    this.files = obj === null ? [] : obj;
    this.changeDetector.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onUploadChange(event: Event) {
    const target = event.target as HTMLInputElement;

    if (target.files && target.files.length) {
      const files = [];

      for (let i = 0; i < target.files.length; i++) {
        files[i] = target.files.item(i);
      }

      this.files = files.map(file => {
        const dataPromise = new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onerror = () => {
            reader.abort();
            reject(new DOMException(`Failed to parse file ${file}`));
          };

          reader.onload = () => {
            resolve(reader.result);
          };

          reader.readAsDataURL(file);
          
          if (file.size > 2097152) {
            this.toastr.error(this.translate.instant('toast-notification.upload-photo.error-file.message'),
                this.translate.instant('toast-notification.upload-photo.error-file.title'), {
                timeOut: 10000,
                positionClass: 'toast-bottom-center',
                closeButton: true
                });
            return file = null;
          }
          else if (files.length > 1) {
            const fileSizeSum = files.reduce((sum, file) => sum + parseInt (file.size, 10), 0)
              if (fileSizeSum > 5242880) {
                this.toastr.error(this.translate.instant('toast-notification.upload-photo.error-files.message'),
                this.translate.instant('toast-notification.upload-photo.error-files.title'), {
                timeOut: 10000,
                positionClass: 'toast-bottom-center',
                closeButton: true
              });
                return file = null;
              }
          }
        });

        return {
          file,
          thumbnail: this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file)),
          data: dataPromise
        } as UploadedFile;
      });

      this.onChange(this.files);
      this.changeDetector.markForCheck();
    }
  }

}
