<div id="news-view-wrapper" class="news-tile" role="main">
  <div id="news-list-container" class="news-tile">
    <mat-card>
      <mat-card-title role="heading" aria-level="2">{{'news-list.news-items' | translate}}</mat-card-title>
      <app-messages></app-messages>
      <mat-card-content *ngIf="!hasData">
        <mat-spinner class="centered" aria-label="Loading News List"></mat-spinner>
      </mat-card-content>
      <mat-card-content *ngIf="hasData">
        <mat-form-field>
          <mat-label>{{'news.filter' | translate}}</mat-label>
          <input matInput (keyup)="setFilter($event)" placeholder="{{'news.filter-prompt' | translate}}">
        </mat-form-field>
        <button mat-raised-button color="primary" id="create-button" routerLink="./new"><mat-icon>add</mat-icon>{{'news-list.create' | translate}}</button>
        <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>{{'news-list.id' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>
          </ng-container>

          <!-- Created Column -->
          <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef>{{'news-list.created' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.created | date: 'yyyy-MM-dd HH:mm' }}</mat-cell>
          </ng-container>

          <!-- Title Column -->
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>{{'news-list.title' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element" class="right">
              <a routerLink="{{element.id}}">
                <button mat-raised-button color="primary" role="link">
                  <mat-icon>edit</mat-icon> {{'general.edit' | translate}}
                </button>
              </a>
              &nbsp;
              <button mat-raised-button color="warn" (click)="deleteItem(element.id)">
                <mat-icon>delete</mat-icon> {{'general.delete' | translate }}
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
        </mat-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
