import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'app-twitter-feed',
  templateUrl: './twitter-feed.component.html',
  styleUrls: ['./twitter-feed.component.scss']
})
export class TwitterFeedComponent {

  constructor(private renderer: Renderer2, private element: ElementRef) { }

  ngAfterViewInit(): void {

    let script = document.createElement('script');
    script.src = "https://platform.twitter.com/widgets.js"

    this.renderer.appendChild(this.element.nativeElement, script)
  }

}
