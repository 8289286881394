<div id="about-view-wrapper" role="main">
<mat-card class="about-tile">
  <mat-card-title role="heading" aria-level="2">{{'about.ssnapsight' | translate}}</mat-card-title>
  <mat-card-subtitle>“{{'about.preparedness' | translate}}”
  </mat-card-subtitle>

  <mat-card-actions>

    <!-- <a href="https://proactive-h2020.eu/" class="button-container">
      <button mat-raised-button color="primary">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: white"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.016 11c-.048-1.03-.168-2.035-.356-3h3.5c.41.935.685 1.942.79 3h-3.934zm3.933 2c-.105 1.058-.38 2.065-.79 3h-3.5c.188-.965.309-1.97.356-3h3.934zm-1.968-7h-2.83c-.375-1.197-.875-2.292-1.461-3.286 1.716.684 3.195 1.831 4.291 3.286zm-4.948 0h-2.033v-3.674c.841 1.01 1.526 2.256 2.033 3.674zm-2.033 12h2.033c-.507 1.418-1.192 2.664-2.033 3.674v-3.674zm-2-15.674v3.674h-2.033c.507-1.418 1.192-2.664 2.033-3.674zm0 5.674v3h-2.994c.052-1.04.181-2.044.389-3h2.605zm0 5v3h-2.605c-.208-.956-.336-1.96-.389-3h2.994zm0 5v3.674c-.841-1.01-1.526-2.256-2.033-3.674h2.033zm2-2v-3h2.994c-.052 1.04-.181 2.044-.389 3h-2.605zm0-5v-3h2.605c.208.956.336 1.96.389 3h-2.994zm-4.69-8.286c-.586.994-1.086 2.089-1.461 3.286h-2.83c1.096-1.455 2.575-2.602 4.291-3.286zm-2.326 8.286h-3.933c.105-1.058.38-2.065.79-3h3.5c-.189.965-.309 1.97-.357 3zm.357 5h-3.5c-.41-.935-.685-1.942-.79-3h3.934c.047 1.03.167 2.035.356 3zm-2.322 2h2.83c.375 1.197.875 2.292 1.461 3.286-1.716-.684-3.195-1.831-4.291-3.286zm11.671 3.286c.586-.994 1.086-2.089 1.461-3.286h2.83c-1.096 1.455-2.575 2.602-4.291 3.286z"/></svg>
        <span class="button-text">{{'about.website' | translate}}</span>
      </button>
    </a>

    <a href="https://twitter.com/oslgroup?lang=en" class="button-container">
      <button mat-raised-button id="twitter">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: white"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/></svg>
        <span class="button-text">{{'about.twitter' | translate}}</span>
      </button>
    </a>

    <a href="https://www.linkedin.com/company/proactive-eu/" class="button-container">
      <button mat-raised-button id="linkedin">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: white"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
        <span class="button-text">{{'about.linkedin' | translate}}</span>
      </button>
    </a> -->

    <a routerLink="/contacts" class="button-container">
      <button mat-raised-button color="primary">
        <mat-icon>trending_up</mat-icon>
        <span class="button-text">{{'about.contacts' | translate}}</span>
      </button>
    </a>

  </mat-card-actions>
</mat-card>
<br>
<mat-card class="about-tile">
  <mat-card-title role="heading" aria-level="2">{{'about.abstract' | translate}}</mat-card-title>

  <p>{{'about.ssnapsight-can-help' | translate}}</p>

</mat-card>
<br>
<mat-card class="about-tile">
  <mat-card-title role="heading" aria-level="2">{{'about.consortium' | translate}}</mat-card-title>
  <mat-card-content>
    <app-consortium-map></app-consortium-map>
  </mat-card-content>
</mat-card>
<br>
</div>
