<div id="login-wrapper" role="main">
  <div id="logo-container">
    <img src="assets/images/proactive-logo.png" id="proactive-logo">
  </div>
  <mat-card class="center" id="login-container">
    <mat-card-subtitle>{{'login.dont'| translate}} <a routerLink="/register">{{'login.register' | translate}}</a></mat-card-subtitle>
    <app-messages [messages]="loginMessages"></app-messages>
    <div [formGroup]="form">
      <mat-form-field color="primary" appearance="fill">
        <mat-label>{{'login.email'| translate}}</mat-label>
        <input color="primary" matInput type="email" autocomplete="email" spellcheck="false" formControlName="email" (keyup.enter)="login()">
        <mat-error *ngIf="form.controls.email.touched && !form.controls.email.value">{{'login.email-cannot'| translate}}</mat-error>
        <mat-error *ngIf="form.controls.email.hasError('email')">{{'login.please-enter'| translate}}</mat-error>
      </mat-form-field>
      <br>
      <mat-form-field appearance="fill">
        <mat-label>{{'login.password'| translate}}</mat-label>
        <input matInput type="password" autocomplete="current-password" formControlName="password" (keyup.enter)="login()">
        <mat-error>{{'login.password-cannot'| translate}}</mat-error>
      </mat-form-field>
      <div><a routerLink="/forgot-password">{{'login.forgot' | translate}}</a></div>
      <br>
      <div class="right">
        <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="login()">{{'login.login' | translate}}</button>
      </div>
    </div>
  </mat-card>
</div>
