import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {UserModel} from '../../models/user-model';
import {UserService} from '../../services/user.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationDialogComponent} from '../../components/dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class UserListComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource<UserModel>(null);
  displayedColumns: string[] = ['id', 'email', 'role', 'actions'];
  filterValue: string;
  private listUnsubscribe = new Subject<boolean>();
  messages: { message: string, type: string }[];

  constructor(private userService: UserService, private router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers(): void {
    this.userService.list$().pipe(takeUntil(this.listUnsubscribe)).subscribe(data => {
      this.dataSource.data = data;
      this.applyFilter();
    });
  }

  ngOnDestroy(): void {
    this.listUnsubscribe.next(true);
    this.listUnsubscribe.complete();
  }

  setFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.applyFilter();
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue;
  }

  get hasData(): boolean {
    return this.dataSource.data !== null;
  }

  delete(userId: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {data: {
            title: 'confirmation-dialog.delete-user.title',
            description: 'confirmation-dialog.delete-user.description',
          }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.delete$(userId).subscribe(
          () => this.loadUsers(),
          err => console.error(err)
        );
      }
    });
  }
}
