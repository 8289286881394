import {Component, OnDestroy, OnInit} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {AdditionalInfoUserService} from "../../services/additional-info-user.service";
import {ActivatedRoute} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-cbrne-web-view',
  templateUrl: './cbrne-web-view.component.html',
  styleUrls: ['./cbrne-web-view.component.scss']
})
export class CbrneWebViewComponent implements OnInit, OnDestroy {
  categoryId: number;
  private unsubscribe = new Subject<boolean>();
  html = '';

  constructor(private domSanitizer: DomSanitizer, private userService: AdditionalInfoUserService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.has('id')) {
        this.categoryId = parseInt(params.get('id'), 10);
        this.userService.getWebFile$(this.categoryId)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(
            data => {
              this.html = data;
            }
          );
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  get sanitizedHtml() {
    return this.domSanitizer.bypassSecurityTrustHtml(this.html);
  }
}
