import { Component, OnInit } from '@angular/core';
import {ConsortiumPartnerModel} from '../../models/consortium-partner-model';

const partners: ConsortiumPartnerModel[] = [
  {name: 'Union internationale des chemins de fer (UIC)', country: 'France', color: '#00b1eb', coordinator: true},
  {name: 'CBRNE Ltd (CBRNE)', country: 'United Kingdom', color: '#74a3c0', coordinator: false},
  {name: 'Population Protection Institute (PPI – Ministry of the Interior of the Czech Republic)',
    country: 'Czech Republic', color: '#83c3eb', coordinator: false},
  {name: 'Deutsche Bahn AG (DB)', country: 'Germany', color: '#93b46c', coordinator: false},
  {name: 'Umea Universitet (UMU)', country: 'Sweden', color: '#8a8cc4', coordinator: false},
  {name: 'Deutsche Hochschule der Polizei (DHPOL)', country: 'Germany', color: '#93b46c', coordinator: false},
  {name: 'Rinisoft Ltd (RINISOFT)', country: 'Bulgaria', color: '#a65ea2', coordinator: false},
  {name: 'West Midlands Police and Crime Commissioner (WMP)', country: 'United Kingdom', color: '#74a3c0', coordinator: false},
  {name: 'Eticas Research and Consulting SL (ETICAS)', country: 'Spain', color: '#be6784', coordinator: false},
  {name: 'State Emergency Service of Ukraine (SESU)', country: 'Ukraine', color: '#e4cb25', coordinator: false},
  {name: 'Department of Health – Public Health England  (PHE)', country: 'United Kingdom', color: '#74a3c0', coordinator: false},
  {name: 'State Police of Latvia (SPL)', country: 'Latvia', color: '#a5b9c4', coordinator: false},
  {name: 'An Garda Síochána – National Police Force Ireland (AGS) ', country: 'Ireland', color: '#ea5175', coordinator: false},
  {name: 'Forsvarets Forskningsinstitutt (FFI)', country: 'Norway', color: '#524b9a', coordinator: false},
  {name: 'Komenda Główna Policji  (NPH)', country: 'Poland', color: '#e46f70', coordinator: false},
];

@Component({
  selector: 'app-consortium-map',
  templateUrl: './consortium-map.component.html',
  styleUrls: ['./consortium-map.component.scss']
})
export class ConsortiumMapComponent implements OnInit {
  partners: ConsortiumPartnerModel[] = partners;
  selectedPartner: ConsortiumPartnerModel = null;

  constructor() { }

  ngOnInit(): void {
  }

  selectPartner(partner: ConsortiumPartnerModel) {
    this.selectedPartner = partner;
  }
}
