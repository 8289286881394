import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {Message} from '../../../components/messages/messages.component';
import {AuthService} from '../../../services/auth.service';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  messages: { message: string, type: string }[];
  form: UntypedFormGroup;
  private unsubscribe = new Subject<boolean>();
  forgotPasswordMessages: Message[] = [];

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email
      ])
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  request() {
    if (!this.form.valid) {
      return false;
    }

    this.forgotPasswordMessages = [];

    this.auth.requestReset(this.form.value).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(resp => {
        this.forgotPasswordMessages[0] = {
          message: 'app-message.forgot-password.success',
          type: 'success'
        };
      },
      error => {
        this.forgotPasswordMessages[0] = {
          message: 'app-message.forgot-password.error',
          type: 'danger'
        };
      }
    );
  }
}
