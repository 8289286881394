import {EIncidentStatus, EIncidentStatusLookup} from './incident-status.enum';
import {EIncidentClassification, EIncidentClassificationLookup} from './incident-classification.enum';
import {NotificationModel} from './notification-model';

export class Incidentform {
  status: EIncidentStatus;
  classification: EIncidentClassification;
  occurred: Date;
  headline: string;
  description: string;
  location: {lat: number, lng: number, streetAddress: string};
  sendNotification: boolean;
  id: number;

  constructor(
    state: EIncidentStatus,
    classification: EIncidentClassification,
    occurred: Date,
    headline: string,
    description: string,
    location: {
      lat: number,
      lng: number,
      streetAddress: string
    },
    sendNotification: boolean,
    id?: number
  ) {
    this.status = state;
    this.classification = classification;
    this.occurred = occurred;
    this.headline = headline;
    this.description = description;
    this.location = location;
    this.sendNotification = sendNotification;
    this.id = id;
  }
}
