import { Component, OnInit } from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {AdditionalInfoAdminService} from "../../services/additional-info-admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Observable, Subject} from "rxjs";
import {AdditionalInfoItemModel} from "../../models/AdditionalInfoItem";
import {ConfirmationDialogComponent} from "../dialog/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {EIncidentClassification, EIncidentClassificationLookup} from "../../models/incident-classification.enum";
import {EFileType, EFileTypeLookup} from "../../models/AdditionalInfoFileType.enum";

@Component({
  selector: 'app-cbrne-info-edit-item',
  templateUrl: './cbrne-info-edit-item.component.html',
  styleUrls: ['./cbrne-info-edit-item.component.scss']
})
export class CbrneInfoEditItemComponent implements OnInit {
  itemId: number;
  item: AdditionalInfoItemModel;
  private unsubscribe = new Subject<boolean>();
  error: string = null;
  form: UntypedFormGroup;
  private submission: AdditionalInfoItemModel;
  displayedColumns: string[] = ['id', 'description', 'edit'];

  constructor(private service: AdditionalInfoAdminService, private route: ActivatedRoute, private router: Router,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.has('itemid')) {
        this.itemId = parseInt(params.get('itemid'), 10);
        this.loadItem();
      } else {
        this.error = 'No category ID found in URL';
      }
    });
  }
  private loadItem(): void {
    this.service.getItem$(this.itemId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        data => {
          this.item = data;

          this.form = new UntypedFormGroup({
            description: new UntypedFormControl(this.item.description, [Validators.required]),
          });
        }
      );
  }

  editItem() {
    const results = this.form.controls;
    this.submission =  {
      categoryId: this.item.categoryId,
      description: results.description.value,
      files: this.item.files,
      id: this.itemId,
    };

    this.service.updateItem$(this.submission)
      .subscribe(
        _ => this.successfulPost(),
        err => console.error(err)
      );
  }

  successfulPost(): void {
    this.router.navigateByUrl(
      'information',
      {state: {messages: [{type: 'success', message: 'The Item has been updated.'}] } }
    );
  }

  delete(id: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {data: {
          title: 'confirmation-dialog.delete-file.title',
          description: 'confirmation-dialog.delete-file.description',
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.deleteFile$(id).subscribe(
          () => this.successfulDelete(),
          err => console.error(err)
        );
      }
    });
  }
  successfulDelete(): void {
    this.router.navigateByUrl(
      'information',
      {state: {messages: [{type: 'success', message: 'The File has been deleted.'}] } }
    );
  }

  fileTypeName(id: EFileType): string {
    return EFileTypeLookup.get(id).name;
  }

  get FileType(): { id: number, name: string }[] {
    return Object.keys(EFileType).filter(k => typeof EFileType[k] === 'number').map(type => {
      const id = EFileType[type];

      return {
        id,
        name: EIncidentClassificationLookup.get(id).name
      };
    });
  }

  back() {
    this.router.navigateByUrl(
      `information/${this.item.categoryId}/edit`);
  }
}
