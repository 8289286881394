import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Component, Input, OnInit} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, AppDateAdapter} from '../../utils/datepicker-iso';

@Component({
  selector: 'app-iso-datepicker',
  templateUrl: './iso-datepicker.component.html',
  styleUrls: ['./iso-datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: APP_DATE_FORMATS
    }
  ]
})
export class IsoDatepickerComponent implements OnInit {

  maxDate: Date;
  
  @Input() form: UntypedFormGroup;
  
  constructor(private formBuilder: UntypedFormBuilder) { 
    this.maxDate = new Date(Date.now());
  }

  ngOnInit() {
  }

}
