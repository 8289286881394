import {Component, Input, OnInit} from '@angular/core';
import {NewsModel} from '../../models/news-model';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss']
})
export class NewsCardComponent implements OnInit {
  @Input() article: NewsModel;

  constructor() { }

  ngOnInit(): void {
  }

}
