import {Component, Input, OnInit} from '@angular/core';
import {CBRNeInformationModel} from '../../models/cbrne-information-model';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AdditionalInfoAdminService} from "../../services/additional-info-admin.service";
import {AdditionalInfoCategoryModel} from "../../models/AdditionalInfoCategory";
import {Observable, Subject} from "rxjs";
import {AdditionalInfoResponseModel} from "../../models/AdditionalInfoResponseModel";
import {AdditionalInfoItemModel} from "../../models/AdditionalInfoItem";
import {AdditionalInfoUserService} from "../../services/additional-info-user.service";
import {ConfirmationDialogComponent} from "../dialog/confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {EFileType, EFileTypeLookup} from "../../models/AdditionalInfoFileType.enum";

@Component({
  selector: 'app-cbrne-info',
  templateUrl: './cbrne-info.component.html',
  styleUrls: ['./cbrne-info.component.scss']
})
export class CbrneInfoComponent implements OnInit {
  @Input() category: AdditionalInfoResponseModel;
  categoryModels: AdditionalInfoCategoryModel[] = [];
  categoryItems: AdditionalInfoItemModel[] = [];
  topicCategory: string;
  topicCategoryId: number;
  categoryFiles: [number, number][] = [];

  constructor(private domSanitizer: DomSanitizer, private dialog: MatDialog,
              private service: AdditionalInfoAdminService, private router: Router,
              private userService: AdditionalInfoUserService) {
  }


  ngOnInit(): void {
    this.topicCategoryId = this.category.id;
    this.topicCategory = this.category.category;
    this.categoryItems = this.category.items;
  }

  getFileTypeIcon(id) {
    return EFileTypeLookup.get(id).icon;
  }

  getFileTypeName(id) {
    return EFileTypeLookup.get(id).name;
  }

  successfulDelete(): void {
    // this.router.navigateByUrl(
    //   'information',
    //   {state: {messages: [{type: 'success', message: 'The Item has been deleted.'}] } }
    // );
    window.location.reload();
  }

  deleteCategory(topicCategoryId: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {data: {
          title: 'confirmation-dialog.delete-category.title',
          description: 'confirmation-dialog.delete-category.description',
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.service.deleteCategory$(topicCategoryId).subscribe(
          () => this.successfulDelete(),
          err => console.error(err)
        );
      }
    });
  }

  FileTypeAction(fileId: number) {
      window.open(this.userService.getFileUrl(fileId));
  }
}

