import {EIncidentStatus, EIncidentStatusLookup} from './incident-status.enum';
import {EIncidentClassification, EIncidentClassificationLookup} from './incident-classification.enum';
import {MapLocation} from './map-location';
import {AuditModel} from './audit-model';

export class IncidentModel {
  id: number;
  reportedDate: Date;
  resolvedDate: Date | null;
  status: EIncidentStatus;
  classification: EIncidentClassification;
  location: MapLocation;
  incidentAudit: AuditModel | null;
  occurred: Date;
  headline: string;
  description: string;
  nextSteps: string;
  closed: boolean;

  constructor(
    id: number,
    reportedDate: Date,
    state: EIncidentStatus,
    classification: EIncidentClassification,
    occurred: Date,
    headline: string,
    description: string,
    location: MapLocation,
    nextSteps: string,
    closed: boolean,
    resolvedDate?: Date,
    incidentAudit?: AuditModel
  ) {
    this.id = id;
    this.reportedDate = reportedDate;
    this.status = state;
    this.classification = classification;
    this.occurred = occurred;
    this.headline = headline;
    this.description = description;
    this.location = location;
    this.resolvedDate = resolvedDate;
    this.incidentAudit = incidentAudit;
    this.nextSteps = nextSteps;
    this.closed = closed;
  }

  static fromObject(
    obj: {
        id: number,
        reportedDate: Date,
        resolvedDate: Date | null | undefined,
        status: EIncidentStatus,
        occurred: Date,
        headline: string,
        description: string,
        classification: EIncidentClassification,
        location: MapLocation,
        nextSteps: string,
        incidentAudit: AuditModel | null,
        closed: boolean
      }
    ): IncidentModel {
    const id = obj.id;
    const date = obj.reportedDate;
    const state = obj.status;
    const classification = obj.classification;
    const occurred = obj.occurred;
    const headline = obj.headline;
    const description = obj.description;
    const location = obj.location;
    const resolveDate = obj?.resolvedDate;
    const incidentAudit = obj?.incidentAudit;
    const nextSteps = obj.nextSteps;
    const closed = obj.closed;

    return new IncidentModel(
      id,
      date,
      state,
      classification,
      occurred,
      headline,
      description,
      location,
      nextSteps,
      closed,
      resolveDate,
      incidentAudit
    );
  }

  get displayStatus(): string {
    return EIncidentStatusLookup.get(this.status).name;
  }

  get displayClassification(): string {
    return EIncidentClassificationLookup.get(this.classification).name;
  }
}
