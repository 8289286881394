import { Component, OnInit } from '@angular/core';
import {ContactModel} from '../../models/contact-model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  keyContacts = [
    new ContactModel('Laura Petersen', 'UIC', this.translate.instant('contacts.ssnapsight-coordinator'),
      'petersen@uic.org', 'Laura_Peterson.JPG', {website: null, facebook: null, twitter: null},
      {x: 0, y: 10}),

    new ContactModel('Grigore Havarneanu', 'UIC', this.translate.instant('contacts.ssnapsight-coordinator'),
      'havaraneanu@uic.org', 'Grigore_Havarneanu.jpeg'),

    new ContactModel('Dominic Kelly', 'CBRNE', this.translate.instant('contacts.field-exercise-coordinator'),
      'dominic.kelly@cbrneltd.com', 'Dominic_Kelly.jpg', {website: null, facebook: null, twitter: null},
      {x: 65, y: 0}),

    new ContactModel('Åsa Burlin', 'Umea Universitet', 'CSAB ' + this.translate.instant('contacts.representative-fem'),
      'asa.burlin@umu.se', 'Åsa_Burlin.jpg'),

    new ContactModel('Stephen Swain', 'CBRNE', 'PSAB ' + this.translate.instant('contacts.representative-masc'),
      'stephen.swain@cbrneltd.com', 'Stephen_Swain.jpg', {website: null, facebook: null, twitter: null},
      {x: 100, y: 0}),

    new ContactModel('Natasha McCrone', 'Rinisoft', this.translate.instant('contacts.technical-support'),
      'natasha@rinicom.com', 'Natasha_McCrone.jpg', {website: null, facebook: null, twitter: null},
      {x: 0, y: 0}),
  ];

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }
}
