import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable, Subscription} from 'rxjs';
import {catchError, shareReplay} from 'rxjs/operators';
import {handleHttpError} from '../utils/http-error-catcher';
import { StatisticsModel } from '../models/statistics-model';


interface StatisticsDetailResponseModel {
    id: number;
    registeredUsers: number;
    reportedIncidents: number;
    numberOfDownloads: number;
    sendedPushNotifications: number;
}

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  private list: Observable<StatisticsModel[]> = null;
  private listCompleted = false;
  private listCompletedSubscription: Subscription;

  constructor(private http: HttpClient, private auth: AuthService) { }

  list$(): Observable<StatisticsModel[]> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to List Statistics.');
    }

    if (!this.list || this.listCompleted) {
      this.listCompleted = false;
      this.list = this.http.get<StatisticsDetailResponseModel[]>('statistics').pipe(
        catchError(handleHttpError),
        shareReplay(1)
      );

      this.listCompletedSubscription = this.list.subscribe({complete: this.onListComplete.bind(this) });
    }

    return this.list;
  }

  onListComplete(): void {
    this.listCompleted = true;
    setTimeout(() => this.listCompletedSubscription.unsubscribe(), 0);
  }


}
