import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../environments/environment';
import { Injectable } from '@angular/core';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(InterceptorSkipHeader);
      return next.handle(req.clone({ headers }));
    }

    const apiReq = req.clone({
      url: `${environment.apiUrl}/${req.url}`,
      withCredentials: true
    });
    return next.handle(apiReq);
  }
}
