import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LoginModel} from '../../models/login-model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Message} from '../../components/messages/messages.component';
import { UserModel } from 'src/app/models/user-model';
import {OneSignal} from 'onesignal-ngx';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  messages: { message: string, type: string }[];
  nextUrl = '';
  form: UntypedFormGroup;
  private unsubscribe = new Subject<boolean>();
  loginMessages: Message[] = [];
  profile: UserModel;

  constructor(private auth: AuthService, private router: Router, private oneSignal: OneSignal) {
    const nav = this.router.getCurrentNavigation();
    if (nav) {
      const navExtras = nav.extras;
      if (navExtras) {
        if (navExtras.state) {
          if (navExtras.state.messages) {
            this.messages = navExtras.state.messages as { message: string, type: string }[];
          }

          if (navExtras.state.requestedUrl) {
            this.nextUrl = navExtras.state.requestedUrl;
          }
        }
      }
    }
  }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new UntypedFormControl('', [
        Validators.required
      ])
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  login() {
    if (!this.form.valid) {
      return false;
    }

    this.loginMessages = [];

    const data = LoginModel.fromObject(this.form.value);
    
    this.auth.login(data).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(resp => {
      this.auth.profile$.subscribe(data => {
        this.profile = data;
        this.oneSignal.setExternalUserId(this.profile.id.toString());
      });
      // TODO: Messages for error responses
      // this.form.controls.email.setErrors({accountExists: true});
      // TODO: Check for success
      this.router.navigateByUrl(this.nextUrl, { replaceUrl: true });
    },
    error => {
      if (error.status === 401 || error.status === 400) {
        this.loginMessages[0] = {
          message: 'app-message.login.failed',
          type: 'danger'
        };
      } else {
        this.loginMessages[0] = {
          message: 'app-message.login.error',
          type: 'danger'
        };
      }
    }
    );
  }
}
