<div id="incident-view-wrapper" role="main">
  <mat-card class="incident-tile">
    <mat-card-title role="heading" aria-level="2">{{'incident-detail.incident-details' | translate}}</mat-card-title>
    <app-incident-map
      *ngIf="hasIncident"
      [incidents]="[incident]"
      [location]="location"
      [zoom]="13">
    </app-incident-map>
  </mat-card>
  <div>
    <br>
    <mat-card class="incident-tile">
      <mat-card-title role="heading" aria-level="2">{{'incident-detail.incident-information' | translate}}</mat-card-title>
      <mat-card-content *ngIf="!hasIncident">
        <mat-spinner class="centered" aria-label="Loading Incident Data"></mat-spinner>
      </mat-card-content>
      <mat-card-content *ngIf="hasIncident">
        <app-incident-info [incident]="incident"></app-incident-info>
      </mat-card-content>
    </mat-card>
  </div>
  <br/>
  <div *appIsAdmin>
    <div *ngIf="!isNotResolved">
      <mat-card class="incident-tile">
        <mat-card-title role="heading" aria-level="2">{{'incident-detail.resolved' | translate}}</mat-card-title>
      </mat-card>
    </div>
    <div *ngIf="isNotResolved">
      <br>
      <mat-card class="incident-tile">
        <mat-card-title role="heading" aria-level="2">{{'incident-detail.update' | translate}}</mat-card-title>
        <mat-card-content [formGroup]="updateForm">
          <app-incident-update [formGroup]="updateForm"></app-incident-update>
          <mat-label>{{'incident-detail.send' | translate}}</mat-label>
          <mat-error *ngIf="updateForm.controls.sendNotification.pristine">{{'incident-detail.notification' | translate}}</mat-error>
          <mat-radio-group aria-label="option to send Live Notification" formControlName="sendNotification">
            <mat-radio-button value="1">{{'general.yes' | translate}}</mat-radio-button>
            <mat-radio-button [checked]="true" value="2">{{'general.no' | translate}}</mat-radio-button>
          </mat-radio-group>
          <div class="right">
            <button mat-raised-button color="primary" [disabled]="!canSubmit" (click)="submitUpdate()">{{'incident-detail.share-update' | translate}}</button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
    <div *ngIf="!hasUpdates">
      <br>
      <mat-card class="incident-tile">
        <mat-card-title role="heading" aria-level="2">{{'incident-detail.no-update' | translate}}</mat-card-title>
      </mat-card>
    </div>
    <div *ngIf="hasUpdates">
      <div *ngFor="let update of updates">
        <br>
        <mat-card class="incident-tile">
          <div class="public-update-flex" *appIsAdmin>
            <span [ngClass]="update.isPublic ? 'public-indicator-on' : 'public-indicator-off'"></span>
            <mat-card-title class="public-update-title" role="heading" aria-level="2">{{'incident-detail.update-at' | translate}} {{ update.datetime | date: 'yyyy-MM-dd' }} ({{ update.datetime | date: 'HH:mm'}})</mat-card-title>
            <span [ngClass]="update.isPublic ? 'public-indicator-on' : 'public-indicator-off'"></span>
          </div>
          <div *appIsNotAdmin>
            <mat-card-title role="heading" aria-level="2">{{'incident-detail.update-at' | translate}} {{ update.datetime | date: 'yyyy-MM-dd' }} ({{ update.datetime | date: 'HH:mm'}})</mat-card-title>
          </div>
          <mat-card-content class="incident-tile">
            <p>{{ update.description }}</p>
            <div fxLayout="row wrap" fxLayoutAlign="start space-between" fxLayoutGap="1em grid" id="thumb-wrap">
              <div *ngFor="let photo of update.photos; trackBy: trackPhoto" fxFlex.gt-md="25%" fxFlex.gt-sm="33%" fxFlex.gt-xs="50%" fxFlex.lt-sm="100%">
                <img *ngIf="photo.includes('image')" class="photo" [src]="asSafe(photo)" width="100%"/>
                <video *ngIf="photo.includes('video')" controls >
                  <source [src]="asSafe(photo)">
                </video>
                <audio  *ngIf="photo.includes('audio')" controls>
                  <source [src]="asSafe(photo)">
                </audio>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
</div>
