import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { HttpBackend, HttpClient} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FooterComponent } from './components/layout/footer/footer.component';
import { MenuBarComponent } from './components/layout/menu-bar/menu-bar.component';
import { LoginComponent } from './pages/login/login.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { AboutComponent } from './pages/about/about.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { IncidentListComponent } from './pages/incident-list/incident-list.component';
import { HeatmapComponent } from './pages/heatmap/heatmap.component';
import { MatTableModule } from '@angular/material/table';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { APIInterceptor } from './http-client-url.interceptor';
import { RegisterComponent } from './pages/register/register.component';
import { AlertComponent } from './components/alert/alert.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { ContactCardComponent } from './components/contact-card/contact-card.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FaqComponent } from './pages/faq/faq.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { IncidentMapComponent } from './components/incident-map/incident-map.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { StatisticsComponent } from './pages/statistics/statistics.component';
import { ConsortiumMapComponent } from './components/consortium-map/consortium-map.component';
import { ConsortiumPartnerComponent } from './components/consortium-partner/consortium-partner.component';
import { UserCreateComponent } from './pages/user-create/user-create.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { IncidentCreateComponent } from './pages/incident-create/incident-create.component';
import { IncidentSubmissionCreateComponent } from './pages/incident-submission-create/incident-submission-create.component';
import { LocationInputComponent } from './components/location-input/location-input.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ShareInformationComponent } from './pages/share-information/share-information.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import {
  IsAdminDirective,
  IsLoggedInDirective,
  IsNotAdminDirective,
  IsNotLoggedInDirective
} from './directives/auth.directive';
import { DEFAULT_HTTP_TIMEOUT_SECONDS, TimeoutInterceptor } from './http-client-timeout.interceptor';
import { ProfileComponent } from './pages/profile/profile.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MessagesComponent } from './components/messages/messages.component';
import { IncidentInfoComponent } from './components/incident-info/incident-info.component';
import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UserSubmissionListComponent } from './pages/user-submission-list/user-submission-list.component';
import { UserSubmissionDetailComponent } from './pages/user-submission-detail/user-submission-detail.component';
import { IncidentDetailComponent } from './pages/incident-detail/incident-detail.component';
import { PhotoUploadComponent } from './components/photo-upload/photo-upload.component';
import { IncidentEditComponent } from './pages/incident-edit/incident-edit.component';
import { LocationMapComponent } from './components/location-map/location-map.component';
import { LocationViewComponent } from './components/location-view/location-view.component';
import { ShareAfterComponent } from './pages/share-after/share-after.component';
import { RemoveAccountComponent } from './pages/remove-account/remove-account.component'
import { FallbackNotificationComponent } from './components/fallback-notification/fallback-notification.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from './components/dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NewsListComponent } from './pages/news-list/news-list.component';
import { NewsCreateComponent } from './pages/news-create/news-create.component';
import { NewsFormComponent } from './pages/news-create/news-form/news-form.component';
import { NewsEditComponent } from './pages/news-edit/news-edit.component';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { ResourcesComponent } from './pages/resources/resources.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ForgotPasswordComponent } from './pages/login/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/login/reset-password/reset-password.component';
import { ConfirmEmailComponent } from './pages/register/confirm-email/confirm-email.component';
import { PasswordRulesComponent } from './components/password-rules/password-rules.component';
import { FxHideGtSmDirective } from './directives/fx-hide.gt-sm.directive';
import { CBRNeInformationComponent } from './pages/cbrne-information/cbrne-information.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { CbrneInfoComponent } from './components/cbrne-info/cbrne-info.component';
import { PreIncidentComponent } from './pages/pre-incident/pre-incident.component';
import { IncidentFormComponent } from './components/incident-form/incident-form.component';
import {IsoDatepickerComponent} from './components/iso-datepicker/iso-datepicker.component';
import { AdditionalInfoSubmitComponent } from './components/additional-info-submit/additional-info-submit.component';
import { CbrneInfoEditComponent } from './components/cbrne-info-edit/cbrne-info-edit.component';
import { CbrneInfoEditItemComponent } from './components/cbrne-info-edit-item/cbrne-info-edit-item.component';
import { CbrneInfoAddItemComponent } from './components/cbrne-info-add-item/cbrne-info-add-item.component';
import { CbrneAddFileComponent } from './components/cbrne-add-file/cbrne-add-file.component';
import { CbrneWebViewComponent } from './components/cbrne-web-view/cbrne-web-view.component';
import { NgxOneSignalModule } from 'ngx-onesignal';
import { TwitterFeedComponent } from './components/twitter-feed/twitter-feed.component';
import { CommonModule} from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { IncidentUpdateComponent } from './components/incident-update/incident-update.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';

export function translateHttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  return new TranslateHttpLoader(new HttpClient(httpBackend));
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    MenuBarComponent,
    LoginComponent,
    AboutComponent,
    DashboardComponent,
    IncidentListComponent,
    HeatmapComponent,
    RegisterComponent,
    AlertComponent,
    ContactsComponent,
    ContactCardComponent,
    FaqComponent,
    PrivacyComponent,
    IncidentMapComponent,
    UserListComponent,
    StatisticsComponent,
    ConsortiumMapComponent,
    ConsortiumPartnerComponent,
    UserCreateComponent,
    IsLoggedInDirective,
    IsAdminDirective,
    IsNotAdminDirective,
    IsNotLoggedInDirective,
    IncidentCreateComponent,
    IncidentSubmissionCreateComponent,
    LocationInputComponent,
    ShareInformationComponent,
    LogoutComponent,
    ProfileComponent,
    MessagesComponent,
    IncidentInfoComponent,
    NotificationSettingsComponent,
    UserSubmissionListComponent,
    UserSubmissionDetailComponent,
    IncidentDetailComponent,
    PhotoUploadComponent,
    LocationViewComponent,
    ShareAfterComponent,
    RemoveAccountComponent,
    PhotoUploadComponent,
    FallbackNotificationComponent,
    ConfirmationDialogComponent,
    FallbackNotificationComponent,
    IncidentEditComponent,
    LocationMapComponent,
    LocationInputComponent,
    NewsListComponent,
    NewsCreateComponent,
    NewsFormComponent,
    NewsEditComponent,
    NewsCardComponent,
    ResourcesComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ConfirmEmailComponent,
    PasswordRulesComponent,
    FxHideGtSmDirective,
    CBRNeInformationComponent,
    CbrneInfoComponent,
    PreIncidentComponent,
    IncidentFormComponent,
    AdditionalInfoSubmitComponent,
    CbrneInfoEditComponent,
    CbrneInfoEditItemComponent,
    CbrneInfoAddItemComponent,
    CbrneAddFileComponent,
    IsoDatepickerComponent,
    CbrneWebViewComponent,
    TwitterFeedComponent,
    IncidentUpdateComponent,
    BarChartComponent,
  ],
    imports: [
        BrowserAnimationsModule,
        MatToolbarModule,
        MatButtonModule,
        MatMenuModule,
        MatDividerModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatTableModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FlexLayoutModule,
        GoogleMapsModule,
        MatRadioModule,
        ServiceWorkerModule.register('custom-sw.js', {enabled: environment.production}),
        MatOptionModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatStepperModule,
        MatSnackBarModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatGridListModule,
      NgxOneSignalModule.forRoot({
        appId: 'dd4038cb-4a2f-4abe-ada3-4b563e4cacb2',
        allowLocalhostAsSecureOrigin: true,
        autoRegister: false,
        notifyButton: {
          enabled: false,
        },
      }),
      BrowserModule,
      ServiceWorkerModule.register('OneSignalSDKWorker.js', {
        enabled: true,
      }),
      CommonModule,
      ToastrModule.forRoot(),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: translateHttpLoaderFactory,
          deps: [HttpBackend]
        }
      })
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_HTTP_TIMEOUT_SECONDS,
      useValue: 10
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
