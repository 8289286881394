export class LoginModel {
  email: string;
  password: string;

  static fromObject(data: any): LoginModel {
    if (!data.email || ! data.password) {
      throw new Error('Object must have both email & password properties');
    }

    const obj = new LoginModel();
    obj.email = data.email;
    obj.password = data.password;
    return obj;
  }
}
