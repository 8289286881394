<div [formGroup]="form">
  <mat-form-field appearance="fill" class="stretch-width">
    <mat-label>{{'news-form.link' | translate}}</mat-label>
    <input matInput type="text" formControlName="uri">
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill" class="stretch-width">
    <mat-label>{{'news-form.category' | translate}}</mat-label>
    <input matInput type="text" formControlName="category">
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill" class="stretch-width">
    <mat-label>{{'news-form.title' | translate}}</mat-label>
    <input matInput type="text" spellcheck="true" formControlName="title">
  </mat-form-field>
  <p *ngIf="!!articleMetadata?.title && form.controls.title.value != articleMetadata.title">
    {{'news-form.suggested' | translate}} <span class="suggested-title" (click)="useSuggestedTitle()">{{ articleMetadata.title }}</span>
  </p>
  <br>
  <div *ngIf="form.controls.imageUri.value">
    <p>{{'news-form.article-image' | translate}}</p>
    <img class="stretch-width"
      [src]="form.controls.imageUri.value"
      [alt]="form.controls.imageAlt.value"
    >
    <button mat-raised-button color="accent" (click)="removeImage()">
      <mat-icon>delete</mat-icon>
      {{'news-form.remove' | translate}}
    </button>
  </div>
</div>
