import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Inject, Injectable, InjectionToken} from '@angular/core';
import {timeout} from 'rxjs/operators';

export const DEFAULT_HTTP_TIMEOUT_SECONDS = new InjectionToken<number>('defaultTimeoutSeconds');

@Injectable()
export class TimeoutInterceptor implements HttpInterceptor {
  constructor(@Inject(DEFAULT_HTTP_TIMEOUT_SECONDS) protected defaultTimeoutSeconds: number) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const timeoutSeconds = Number(req.headers.get('timeoutSeconds') || this.defaultTimeoutSeconds);

    return next.handle(req).pipe(timeout(timeoutSeconds * 1000));
  }
}
