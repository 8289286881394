import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IncidentModel} from '../../models/incident-model';
import {DomSanitizer} from '@angular/platform-browser';
import {IncidentService} from '../../services/incident.service';
import {Observable, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {SubmissionsService} from '../../services/submissions.service';
import {UploadedFile} from '../../components/photo-upload/photo-upload.component';
import {DatePipe} from '@angular/common';
import {IncidentFilter} from '../../utils/incident-filter';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ShareAfterComponent} from '../share-after/share-after.component';
import {HttpErrorResponse} from "@angular/common/http";
import {AdminGuard} from "../../guards/admin.guard";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-share-information',
  templateUrl: './share-information.component.html',
  styleUrls: ['./share-information.component.scss']
})
export class ShareInformationComponent implements OnInit, OnDestroy {
  idFromUrl = false;
  incidentId: number = null;
  incident: IncidentModel = null;
  error: string = null;
  types: ['image/jpeg', 'image/png', 'image/webp', 'video/mp4', 'video/webm', 'audio/mp3', 'audio/wav'];

  incidentForm: UntypedFormGroup;
  informationForm: UntypedFormGroup;

  availableIncidents: IncidentModel[] = null;
  filteredIncidents: Observable<IncidentModel[]>;

  private unsubscribe = new Subject<boolean>();

  constructor(private route: ActivatedRoute, private changeDetector: ChangeDetectorRef, private domSanitizer: DomSanitizer,
              private incidentService: IncidentService, private submissionService: SubmissionsService,
              private router: Router, private PopUp: MatDialog, private authService: AuthService) { }

  ngOnInit(): void {
    if (this.authService.isAdmin) {
      this.router.navigateByUrl('incidents/new');
    }
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.has('id')) {
        this.idFromUrl = true;
        this.incidentId = parseInt(params.get('id'), 10);
        this.incidentService.get$(this.incidentId).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          this.incident = data;
        });
      } else {
        this.incidentService.list$().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          this.availableIncidents = data;
        });
      }
    });

    this.incidentForm = new UntypedFormGroup({
      incident: new UntypedFormControl('', [Validators.required])
    });

    this.incidentForm.get('incident').valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe(incidentId => {
      if (incidentId) {
        this.incident = this.availableIncidents.find(incident => incident.id === incidentId);
      }
    });

    this.informationForm = new UntypedFormGroup({
      photos: new UntypedFormControl(),
      text: new UntypedFormControl('', [
        Validators.required
      ]),
      location: new UntypedFormControl()
    });

    this.filteredIncidents = this.incidentForm.controls.incident.valueChanges.pipe(
      startWith(''),
      map(value => IncidentFilter.filterIncidents(this.availableIncidents, value))
    );
  }

  openPopUp() {
    const PopUpConfig = new MatDialogConfig();

    PopUpConfig.autoFocus = false;

    this.PopUp.open(ShareAfterComponent, PopUpConfig);
  }

  closePopUp() {
    this.PopUp.closeAll();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  get boundDisplayIncident(): (incident: IncidentModel | string | number) => string {
    return this.displayIncident.bind(this);
  }

  displayIncident(incident: IncidentModel | string | number): string {
    if (typeof incident === 'string') {
      if (incident === '-1') {
        return 'A New Incident';
      }

      return incident;
    }

    if (typeof incident === 'number') {
      incident = this.availableIncidents.find(inc => inc.id === incident);
    }

    if (!incident) {
      return null;
    }

    const formattedDate = new DatePipe('en-GB').transform(incident.reportedDate, 'yyyy-MM-dd');
    return `${formattedDate} - ${incident.displayStatus}`;
  }

  get canShare(): boolean {
    return this.informationForm.valid;
  }

  setPhotos(photos: UploadedFile[]): void {
    this.informationForm.patchValue({
      photos
    });
  }

  onSubmitError(err: HttpErrorResponse) {
    console.error('Submission POST Error', err);
    if (err.status === 0) {
      this.error = 'Error sharing information. Photo/Video filesize is too big.';
    } else {
      this.error = `Error sharing information. ${err.message}`;
    }
  }

  shareInformation() {
    const data = {
      incident: { id: this.idFromUrl ? this.incidentId : this.incident?.id },
      description: this.informationForm.get('text').value,
      photos: [],
      location: {
        lat: this.informationForm.controls.location?.value?.gps?.lat,
        lng: this.informationForm.controls.location?.value?.gps?.lng,
        streetAddress: this.informationForm.controls.location?.value?.streetAddress
      }
    };

    const photoFiles = this.informationForm.get('photos').value?.map(f => f.data as Promise<string>) || [];
    Promise.all(photoFiles).then(
      photos => {
        data.photos = photos;

        const observer = {
          error: err => { this.onSubmitError(err) },
          complete: () => { this.openPopUp(); }
        };

        this.submissionService.share(data).subscribe(observer);
      }
    );
  }
}
