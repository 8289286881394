<div id="info-edit-item">
  <mat-card id="additional-info-edit-container">
  <form [formGroup]="form" *ngIf="form">
  <div>
    <mat-form-field appearance="fill">
      <mat-label>{{'cbrne-info-edit-item.item-description' | translate }}</mat-label>
      <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
  </div>
</form>

    <table mat-table [dataSource]="item.files" *ngIf="form" class="mat-elevation-z8" aria-label="Table of Files">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> {{'cbrne-info-edit-item.id' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> {{'cbrne-info-edit-item.file-type' | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{fileTypeName(element.type)}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> {{'general.delete' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button color="warn" class="user-button" (click)="delete(element.id)"><mat-icon>delete</mat-icon> {{'general.delete' | translate }}</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div>
    <a routerLink="add">
      <button mat-raised-button color="primary" *appIsAdmin>
        <mat-icon>add</mat-icon>
        {{'cbrne-info-edit-item.add-new-file' | translate }}
      </button>
    </a>
    </div>
    <div>
      <button mat-raised-button type="submit" color="primary" (click)="editItem()">{{'general.submit' | translate }}</button>
      <button mat-raised-button color="primary" (click)="back()">{{'general.back' | translate }}</button>
    </div>
  </mat-card>
</div>
