<div class="partner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em grid">
  <div fxFlex="3em">
    <div class="color-circle" [ngStyle]="{'background-color': partner.color}"></div>
  </div>
  <div fxFlex="auto" class="partner-info">
    <span class="country-name" [ngStyle]="{'color': partner.color}">{{ partner.country }}</span>
    <span class="partner-name">{{ partner.name }}</span>
    <span class="coordinator" *ngIf="partner.coordinator">{{'consortium-partner.coordinator' | translate}}</span>
  </div>
</div>
