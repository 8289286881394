<div id="user-submission-view-wrapper" role="main">
  <mat-card class="user-submission-tile">
    <div style="display: inline-flex;">
    <mat-card-title role="heading" aria-level="2">{{'user-submission-detail.submitted' | translate}}</mat-card-title>
    <button mat-raised-button color="primary" class="publish-submission" (click)="submitToIncident()">{{'user-submission-detail.publish' | translate}}</button>
  </div>
    <br/>
    <mat-card-content *ngIf="!submission">
      <mat-spinner class="centered" aria-label="Loading Incident Data"></mat-spinner>
    </mat-card-content>
    <mat-card-content *ngIf="submission">
      <div><span id="submitted-at">{{'user-submission-detail.submitted-at' | translate}}</span> {{ submission.submitted | date: 'yyyy-MM-dd' }} ({{ submission.submitted | date: 'HH:mm' }})</div>
      <br/>
      <div><span id="submitted-by">{{'user-submission-detail.submitted-by' | translate}}</span> {{ submission.submitter }}</div>
    </mat-card-content>
  </mat-card>
  <div *ngIf="submission && submission.incidentId">
    <br>
    <mat-card class="user-submission-tile">
      <mat-card-title role="heading" aria-level="2">{{'user-submission-detail.incident-information' | translate}}</mat-card-title>
      <mat-card-content *ngIf="!resolvedIncident">
        <mat-spinner class="centered" aria-label="Loading Incident Data"></mat-spinner>
      </mat-card-content>
      <mat-card-content *ngIf="resolvedIncident">
        <app-incident-info [incident]="resolvedIncident"></app-incident-info>
      </mat-card-content>
    </mat-card>
  </div>
  <div *ngIf="submission">
    <br>
    <mat-card class="user-submission-tile">
      <mat-card-title role="heading" aria-level="2">{{'user-submission-detail.location' | translate}}</mat-card-title>
      <mat-card-content>
        <div fxLayout="row wrap" fxLayoutAlign="start space-between" fxLayoutGap="1em grid" *ngIf="submission.location; else locationElse">
          <app-location-view [location]="submission.location" showCoords="true" *ngIf="submission.location.lat && submission.location.lng"></app-location-view>
          </div><div>
          <p *ngIf="submission.location.streetAddress">{{'user-submission-detail.street-address' | translate}} {{ submission.location.streetAddress | translate}}</p>
        </div>
        <ng-template #locationElse>
          <div>
            {{'user-submission-detail.no-location' | translate}}
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
  <div *ngIf="submission">
    <br>
    <mat-card class="user-submission-tile">
      <mat-card-title role="heading" aria-level="2">{{'user-submission-detail.files' | translate}}</mat-card-title>
      <mat-card-content>
        <div fxLayout="row wrap" fxLayoutAlign="start space-between" fxLayoutGap="1em grid" id="thumb-wrap" *ngIf="submission.files?.length; else filesElse">
          <div *ngFor="let file of submission.files; trackBy: trackPhoto" fxFlex.gt-md="25%" fxFlex.gt-sm="33%" fxFlex.gt-xs="50%" fxFlex.lt-sm="100%">
            <img *ngIf="file.fileType == 'Image'" [src]="asSafe(file.fileData)" width="100%"/>
            <video *ngIf="file.fileType == 'Video'" controls width="100%">
              <source [src]="asSafe(file.fileData)">
            </video>
            <p *ngIf="file.fileType == 'Audio'">{{'user-submission-detail.audio' | translate}}</p>
            <audio *ngIf="file.fileType == 'Audio'" controls [src]="asSafe(file.fileData)"></audio>
          </div>
        </div>
        <ng-template #filesElse>
          <div>
            {{'user-submission-detail.no-files' | translate}}
          </div>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
  <br>
  <mat-card class="user-submission-tile">
    <mat-card-title role="heading" aria-level="2">{{'user-submission-detail.description' | translate}}</mat-card-title>
    <mat-card-content *ngIf="!submission">
      <mat-spinner class="centered" aria-label="Loading Incident Data"></mat-spinner>
    </mat-card-content>
    <mat-card-content *ngIf="submission">
      <p>{{ submission.description }}</p>
    </mat-card-content>
  </mat-card>
  <br>
</div>
