import {AfterViewInit, Component, ElementRef, forwardRef, ViewChild} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import {MapLoaderService} from '../../services/map-loader.service';
import PlaceResult = google.maps.places.PlaceResult;
import {LocationMapComponent} from '../location-map/location-map.component';

type PositionError = GeolocationPositionError;
type Position = GeolocationPosition;

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LocationInputComponent),
  multi: true
};

@Component({
  selector: 'app-location-input',
  templateUrl: './location-input.component.html',
  styleUrls: ['./location-input.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR]
})
export class LocationInputComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('address') address: ElementRef<HTMLInputElement>;
  @ViewChild('map') map: LocationMapComponent;

  gettingPosition = false;
  IsStreetAddress = false;
  mapLoader: MapLoaderService;
  public location: UntypedFormGroup = new UntypedFormGroup({
    gps: new UntypedFormControl('', [Validators.required]),
    streetAddress: new UntypedFormControl('', []),
  });

  private onChange: (_: any) => void;
  private onTouched: () => void;

  constructor(mapLoader: MapLoaderService) {
    this.mapLoader = mapLoader;
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
  }

  ngAfterViewInit(): void {
    this.mapLoader.mapsLoaded.subscribe((r: boolean) => {
      if (r) {
        this.configureSearchBox();
      }
    });
  }

  useCurrentLocation() {
    this.gettingPosition = true;
    navigator.geolocation.getCurrentPosition(
      this.receiveCurrentLocation.bind(this),
      this.currentPositionError.bind(this),
      { maximumAge: 60000}
    );
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(result => {
        if (result.state === 'denied') {
          alert('Disabled in the browser')
        }
      })
    }
}

  currentPositionError(error: PositionError) {
    this.gettingPosition = false;
    console.error('Error getting current position', error);
  }

  receiveCurrentLocation(position: Position) {
    this.gettingPosition = false;
    const pos = {
      lat: position.coords.latitude,
      lng: position.coords.longitude
    };

    this.location.patchValue({
      gps: pos
    });
  }

  writeValue(val: any): void {
    this.location.patchValue(val || {gps: null, streetAddress: null});
  }

  registerOnChange(fn: any): void {
    this.location.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Don't bother with disabling for this component
  }

  configureSearchBox(): void {
    const autocomplete = new google.maps.places.Autocomplete(
      this.address.nativeElement,
      {
        fields: ['geometry', 'formatted_address'],
        types: ['geocode']
      }
    );

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.handlePlaceSelected(place);
    });
  }

  private handlePlaceSelected(address: PlaceResult) {
    if ('geometry' in address) {
      this.map.selectLocation(address.geometry.location);
    }
    if ('formatted_address' in address) {
      this.location.patchValue({streetAddress: address.formatted_address});
    }
  }
}
