import { Component, OnInit } from '@angular/core';
import {Chart,ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle} from 'chart.js';
import { StatisticsModel } from 'src/app/models/statistics-model';
import {StatisticsService} from '../services/statistics.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

Chart.register(ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle);


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  statistics:any = StatisticsModel;
  chart: any;

  isDataLoaded:boolean = false;
  constructor(private statisticsService: StatisticsService,) { }

  ngOnInit() {
    this.loadChart();
  }

  loadChart(){
    this.statisticsService.list$().subscribe(data => {
      this.statistics = data;
      new Chart('chart', {
      type: 'bar',

      data: {
        labels: ['Current'], 
	       datasets: [
          {
            label: 'Registered users',
            data: [this.statistics.registeredUsers],
            backgroundColor: '#9dc6e0'
          },
          {
            label: 'Reported incidents',
            data: [this.statistics.reportedIncidents],
            backgroundColor: '#5886a5'
          },
          {
            label: 'Number of downloads',
            data: [this.statistics.numberOfDownloads],
            backgroundColor: '#7aa6c2'
          },
          {
            label: 'Sended push notifications',
            data: [this.statistics.sendedPushNotifications],
            backgroundColor: '#004c6d'
          }  
        ]
      },
      options: {
        scales: {
          x: {
            display: false
          }
        },
        aspectRatio:2.5
      }
    });
    this.isDataLoaded = true;
    });
  }
}
