import {Component, OnInit} from '@angular/core';
import {StatisticsService} from '../../services/statistics.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import { StatisticsModel } from 'src/app/models/statistics-model';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  statistics:any = StatisticsModel;


  isDataLoaded:boolean = false;

  constructor(private statisticsService: StatisticsService, private router: Router) {}

  ngOnInit(): void {
    this.loadStatistics();
  }

  loadStatistics(): void {
    this.statisticsService.list$().subscribe(data => {
      this.statistics = data;
      this.isDataLoaded = true;
    });
  }

  get hasData(): boolean {
    return this.statistics !== null;
  }

}
