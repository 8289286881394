import {HttpErrorResponse} from '@angular/common/http';
import {ObservableInput, of} from 'rxjs';

export function handleHttpError(error: HttpErrorResponse): ObservableInput<any> {
  if (error.error instanceof ErrorEvent) {
    console.error(
      'The request could not be completed due to an error with the client or the network',
      error.error.message,
      error.url
    );
  } else if (error.status === 401 || error.status === 403) {
    console.error('You are not authorized to perform that action');
  } else {
    console.log(error);
    console.error(
      `The backend returned an unsuccessful response code ${error.status} ${error.statusText} ` +
      `response body was: ${error.error}`
    );
  }

  throw error;
}
