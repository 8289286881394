<div>
  <h3>{{'notification-settings.set-up' | translate}}</h3>
  <div>
    <p>{{'notification-settings.notifications-are-currently' | translate}} <b>{{ (oneSignal.isSubscribe$ | async) ? ('general.on' | translate) : ('general.off' | translate) }}</b> {{'notification-settings.on-this-device' | translate}}</p>
    <button mat-raised-button color="primary" (click)="toggleSubscription()">{{'notification-settings.click' | translate}} {{ (oneSignal.isSubscribe$ | async) ? ('general.off' | translate) : ('general.on' | translate) }}</button>
        <br/>
    <br/>
    <button mat-raised-button color="primary" [disabled]="!(oneSignal.isSubscribe$ | async)" (click)="sendTest()">{{'notification-settings.send-example' | translate}}</button>
  </div>
</div>
