<div *ngIf="mapLoader.mapsLoaded | async">
  <google-map #map
                           [zoom]="zoom"
                           [center]="location"
                           width="100%"
                           aria-label="Map of Incidents"
                           (keydown.tab)="onTab($event)"
                           (keydown.shift.tab)="onShiftTab($event)">
  <map-marker *ngFor="let incident of incidents"
              (mapClick)="incidentMarkerClicked(incident)"
              [options]="incidentMarkerOptions(incident)">
  </map-marker>
  <div *ngIf="loading" id="loading-spinner">
    <mat-spinner class="centered"></mat-spinner>
  </div>
</google-map>
</div>
