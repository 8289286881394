import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {ActivatedRoute} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss']
})
export class ConfirmEmailComponent implements OnInit {
  confirmed: boolean = null;
  error = '';

  constructor(private auth: AuthService, private routeSnapshot: ActivatedRoute) { }

  ngOnInit(): void {
    this.routeSnapshot.queryParamMap.subscribe(params => {
      const token = params.get('token');
      if (token) {
        this.confirmEmail(token);
      } else {
        this.confirmed = false;
        this.error = 'Confirm Email failed, no token in URL';
      }
    });
  }

  confirmEmail(token: string) {
    this.auth.confirmEmail(token).subscribe(code => {
      if (code === 204) {
        this.confirmed = true;
      } else {
        this.confirmed = false;
        this.error = `Confirm Email failed. ${code} at ${new Date().toISOString()}`;
      }
    }, err => {
      this.confirmed = false;
      this.error = `Confirm Email failed. Error: ${err.message || err}`;
    });
  }
}
