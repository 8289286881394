<div id="user-view-wrapper" class="user-tile" role="main">
  <div id="user-list-container" class="user-tile">
    <mat-card>
      <mat-card-title role="heading" aria-level="2">{{'user-list.users' | translate}}</mat-card-title>
      <app-messages></app-messages>
      <mat-card-content *ngIf="!hasData">
        <mat-spinner class="centered" aria-label="Loading User List"></mat-spinner>
      </mat-card-content>
      <mat-card-content *ngIf="hasData">
        <mat-form-field id="filter-user">
          <mat-label>{{'user-list.filter' | translate}}</mat-label>
          <input matInput (keyup)="setFilter($event)" placeholder="{{'user-list.filter-prompt' | translate}}">
        </mat-form-field>
        <button mat-raised-button color="primary" id="create-button" routerLink="./new"><mat-icon>add</mat-icon>{{'user-list.create' | translate}}</button>
        <mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" style="overflow-x: scroll; display: grid; grid-template-rows: 1fr;">
          <!-- Id Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>{{'user-list.id' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.id }}</mat-cell>>
          </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef>{{'user-list.email' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
          </ng-container>

          <!-- Role Column -->
          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef>{{'user-list.role' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.displayRole | translate }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell  *matHeaderCellDef > {{'user-list.actions' | translate }} </mat-header-cell>
            <mat-cell *matCellDef="let row" >
              <button mat-raised-button color="primary" class="user-button" routerLink="/profile/{{row.id}}"><mat-icon>edit</mat-icon> {{'general.edit' | translate}}</button>
              <span class="spacer"></span>
              <button mat-raised-button color="warn" class="user-button" (click)="delete(row.id)"><mat-icon>delete</mat-icon> {{'general.delete' | translate }}</button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"></mat-row>
        </mat-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
