import {MapLocation} from './map-location';
import {FileModel} from './file-model';

export class SubmissionModel {
  id: number;
  incidentId: number | null;
  submitted: Date;
  submitter: string;
  description: string;
  files: FileModel[];
  location: MapLocation | null;

  constructor(id: number, incidentId: number | null, submitted: Date, submitter: string, description: string, files: FileModel[],
              location: MapLocation | null) {
    this.id = id;
    this.incidentId = incidentId;
    this.submitted = submitted;
    this.submitter = submitter;
    this.description = description;
    this.files = files;
    this.location = location;
  }

  static fromObject(data: {
    id: number, incidentId: number | null, submitted: Date, submitter: string, description: string, files: FileModel[],
    location: MapLocation | null
  }): SubmissionModel {
    return new SubmissionModel(data.id, data.incidentId, data.submitted, data.submitter, data.description, data.files, data.location);
  }
}
