import { PhotoUploadComponent } from '../components/photo-upload/photo-upload.component';
import {EUserRole, EUserRoleLookup} from './user-role.enum';

export class UserModel {
  id: number;
  email: string;
  role: EUserRole;
  isConfirmed: boolean;

  constructor(id: number, email: string, role: EUserRole, isConfirmed: boolean) {
    this.id = id;
    this.email = email;
    this.role = role;
    this.isConfirmed = isConfirmed;
  }

  static fromObject(obj: {id: number, email: string, role: EUserRole, isConfirmed: boolean}): UserModel {
    const id = obj.id;
    const email = obj.email;
    const role = obj.role;
    const isConfirmed = obj.isConfirmed;

    return new UserModel(
      id,
      email,
      role,
      isConfirmed
    );
  }

  get displayRole(): string {
    return EUserRoleLookup.get(this.role).name;
  }
}

export class UserCreateModel {
  email: string;
  password: string;
  role: string;
  isConfirmed: boolean

  constructor(email: string, password: string, role: EUserRole, isConfirmed: boolean) {
    this.email = email;
    this.password = password;
    this.role = EUserRoleLookup.get(role).name;
    this.isConfirmed = isConfirmed;
  }

  static fromObject(data: { email: string, password: string, role: EUserRole, isConfirmed: boolean }): UserCreateModel {
    return new UserCreateModel(data.email, data.password, data.role, data.isConfirmed);
  }
}
