<div id="news-edit-wrapper" role="main">
  <mat-card id="news-edit-container">
    <mat-card-title role="heading" aria-level="2">{{'news-edit.edit' | translate}}</mat-card-title>
    <app-news-form [form]="form"></app-news-form>
    <br>
    <div class="right">
      <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="saveNewsItem()">{{'general.save' | translate}}</button>
    </div>
  </mat-card>
</div>
