export class AdditionalInfoCategoryModel {
  id: number;
  name: string;
  adminOnly: boolean;

  constructor(id: number, name: string, adminOnly: boolean) {
    this.id = id;
    this.name = name;
    this.adminOnly = adminOnly;
  }

  static fromObject(
    obj: {
      id: number;
      name: string;
      adminOnly: boolean;
    }
  ): AdditionalInfoCategoryModel {
    const id = obj.id;
    const name = obj.name;
    const adminOnly = obj.adminOnly;

    return new AdditionalInfoCategoryModel(
      id,
      name,
      adminOnly,
    );
  }
}
