import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {RegistrationModel} from '../models/registration-model';
import {interval, Observable, ReplaySubject, Subscription} from 'rxjs';
import {LoginModel} from '../models/login-model';
import {UserModel} from '../models/user-model';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {EUserRole, EUserRoleLookup} from '../models/user-role.enum';
import {handleHttpError} from '../utils/http-error-catcher';

interface ProfileResponseModel {
  id: number;
  email: string;
  role: string;
  isConfirmed: boolean;
}

interface ChangePasswordResponseModel {
  success: boolean;
}

interface RegistrationResultModel {
  response: string;
}

class SessionStatus {
  id: number;
  email: string;
  admin: boolean;
  isConfirmed: boolean;

  static fromObject(obj: {admin: EUserRole; id: any; email: any; isConfirmed: boolean}) {
    const session = new SessionStatus();
    session.id = obj.id;
    session.email = obj.email;
    session.admin = obj.admin === EUserRole.Admin;
    session.isConfirmed = obj.isConfirmed;
    return session;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _isLoggedIn = false;
  private _isAdmin = false;
  private _loggedIn = new ReplaySubject<boolean>(1);
  private _admin = new ReplaySubject<boolean>(1);
  private _isConfirmed = false;

  private sessionRefreshing = false;
  private profile: Observable<UserModel> = null;
  private profileCompleted = false;
  private profileCompletedSubscription: Subscription;

  constructor(private http: HttpClient) {
    this._updateSession();
    // Task every min to update session
    interval(1000 * 60).subscribe(() => {
      if (this.sessionRefreshing) {
        this._updateSession();
      }

    });
  }

  _updateSession(): void {
    this.sessionStatus$.subscribe(
      result => {
        this._isAdmin = result.admin;
        this._isLoggedIn = true;
        this._loggedIn.next(this._isLoggedIn);
        this._admin.next(this._isAdmin);
        this._isConfirmed = result.isConfirmed;
      },
      () => {
        this._isAdmin = false;
        this._isLoggedIn = false;
        this._loggedIn.next(this._isLoggedIn);
        this._admin.next(this._isAdmin);
        this._isConfirmed = false;
        this.stopUpdatingSession();
      }
    );
  }

  startUpdatingSession(): void {
    this.sessionRefreshing = true;
  }

  stopUpdatingSession(): void {
    this.sessionRefreshing = false;
  }

  registerAccount$(data: RegistrationModel): Observable<boolean> {
    if (this.isLoggedIn) {
      throw new Error('You cannot register an account as you are already logged in.');
    }

    const body = {
      email: data.email,
      password: data.password
    };

    return this.http.post<RegistrationResultModel>(`Auth/register`, body).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }

  login(data: LoginModel): Observable<boolean> {
    const body = {
      email: data.email,
      password: data.password
    };

    return this.http.post<boolean>('Auth/login', body).pipe(
      catchError(handleHttpError),
      map(resp => {
        this._isLoggedIn = true;
        this._loggedIn.next(true);
        this._isAdmin = resp;
        this._admin.next(resp);
        this._isConfirmed = resp;
        return true;
      }),
      resp => { this.startUpdatingSession(); return resp; }
    );
  }

  logout() {
    this._isLoggedIn = false;
    this._loggedIn.next(this._isLoggedIn);
    this._isAdmin = false;
    this._admin.next(this._isAdmin);

    this.http.post('Auth/logout', {}).pipe(
      catchError(handleHttpError),
      map(() => {
        return true;
      }),
    ).subscribe(() => this.stopUpdatingSession());
  }

  get loggedIn(): Observable<boolean> {
    return this._loggedIn;
  }

  get admin(): Observable<boolean> {
    return this._admin;
  }

  get isLoggedIn(): boolean {
    return this._isLoggedIn;
  }

  get isAdmin(): boolean {
    return this._isAdmin;
  }

  get profile$(): Observable<UserModel> {
    if (!this.isLoggedIn) {
      throw new Error('You must be logged in to view your profile.');
    }

    if (!this.profile || this.profileCompleted) {
      this.profileCompleted = false;
      this.profile = this.http.get<ProfileResponseModel>('Auth/profile').pipe(
        catchError(handleHttpError),
        map(u => UserModel.fromObject({
          email: u.email,
          id: u.id,
          role: EUserRoleLookup.find(ur => ur.name.toLowerCase() === u.role.toLowerCase()
          ).id,
          isConfirmed: u.isConfirmed
        })
        ),
        shareReplay(1)
      );

      this.profileCompletedSubscription = this.profile.subscribe({ complete: this.onProfileCompleted.bind(this) });
    }

    return this.profile;
  }

  get sessionStatus$(): Observable<SessionStatus> {
    return this.http.get<ProfileResponseModel>('Auth/profile').pipe(
      catchError(handleHttpError),
      map(resp => SessionStatus.fromObject({
        id: resp.id,
        email: resp.email,
        admin: EUserRoleLookup.find(ur => ur.name.toLowerCase() === resp.role.toLowerCase()).id,
        isConfirmed: resp.isConfirmed
      })));
  }

  onProfileCompleted(): void {
    this.profileCompleted = true;
    setTimeout(() => this.profileCompletedSubscription.unsubscribe(), 0);
  }

  changePassword(currentPassword: string, newPassword: string): Observable<boolean> {
    if (!this.isLoggedIn) {
      throw new Error('You must be logged in to change your password.');
    } else if (currentPassword === null || newPassword === null) {
      throw new Error('Cannot make a change password request with null fields');
    }

    const body = {
      currentPassword,
      newPassword
    };

    return this.http.post<ChangePasswordResponseModel>(`Auth/changePassword`, body).pipe(
      catchError(handleHttpError),
    );
  }

  requestReset(data: { email: string }): Observable<void> {
    return this.http.post('Auth/forgotPassword', data).pipe(
      catchError(handleHttpError),
    );
  }

  resetPassword(data: { token: string, password: string }): Observable<void> {
    return this.http.post('Auth/resetPassword', data).pipe(
      catchError(handleHttpError),
    );
  }

  confirmEmail(token: string): Observable<number> {
    return this.http.post('Auth/confirmEmail', { token }, { observe: 'response' }).pipe(
      catchError(handleHttpError),
      map(resp => resp.status)
    );
  }

  setProfile(location: string): Observable<number> {
    return this.http.post('Auth/profile', {location}, {observe: 'response' }).pipe(
      catchError(handleHttpError),
      map(resp => resp.status)
    )
  }
}
