<p>
  {{'password-rules.please-make-sure-your-password-meets-our-rules' | translate}}
</p>
<ul id="rules" [ngClass]="{ 'highlight': rulesHighlighted }">
  <li>{{'password-rules.one-digit' | translate}}</li>
  <li>{{'password-rules.one-lowercase' | translate}}</li>
  <li>{{'password-rules.one-uppercase' | translate}}</li>
  <li>{{'password-rules.one-symbol' | translate}}</li>
  <li>{{'password-rules.six-characters' | translate}}</li>
</ul>
<br>
<ng-container [formGroup]="passwordform">
  <mat-form-field appearance="fill">
    <mat-label>{{'password-rules.pass' | translate}}</mat-label>
    <input matInput type="password" autocomplete="new-password" formControlName="password">
    <mat-error *ngIf="passwordform.controls.password.hasError('short') ||
             passwordform.controls.password.hasError('alphanumeric') ||
             passwordform.controls.password.hasError('lower') ||
             passwordform.controls.password.hasError('upper')">
             {{'password-rules.password' | translate}} <a (click)="highlightRules()" class="rules">{{'password-rules.requirements' | translate}}</a> {{'password-rules.not-met' | translate}}
    </mat-error>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill">
    <mat-label>{{'password-rules.confirm-password' | translate}}</mat-label>
    <input matInput type="password" autocomplete="new-password" formControlName="confirmPassword" [errorStateMatcher]="passwordErrorMatcher">
    <mat-error *ngIf="passwordform.hasError('passwordsDiffer')">{{'password-rules.passwords-must-match' | translate}}</mat-error>
  </mat-form-field>
</ng-container>
