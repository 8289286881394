<div *ngIf="id == 'photoUpload'">
<button type="button" mat-raised-button color="primary" (click)="fileInput.click()">
  <span>{{'photo-upload.photos-videos' | translate}}</span>
  <input #fileInput [id]="id" style="display: none" type="file" multiple accept="{{mediaTypes}}" (change)="onUploadChange($event)"/>
</button>
<div fxLayout="row wrap" fxLayoutAlign="start space-between" fxLayoutGap="1em grid" id="thumb-wrap">
  <div *ngFor="let file of files" fxFlex="25%">
    <img *ngIf="file.file.type.includes('image')" [src]="file.thumbnail" class="upload-thumb" width="100%"/>
    <video *ngIf="file.file.type.includes('video')" class="upload-thumb" controls width="100%">
      <source [src]="file.thumbnail">
    </video>
    <audio *ngIf="file.file.type.includes('audio')" class="upload-thumb" controls [src]="file.thumbnail"></audio>
  </div>
</div>
</div>
<div *ngIf="id == 'info-submit'">
  <button type="button" mat-raised-button color="primary" (click)="fileInput.click()">
    <span>{{'photo-upload.media' | translate}}</span>
    <input #fileInput [id]="id" style="display: none" type="file" multiple accept="{{mediaTypes}}" (change)="onUploadChange($event)"/>
  </button>
  <div fxLayout="row wrap" fxLayoutAlign="start space-between" fxLayoutGap="1em grid" id="thumb-wrap">
    <div *ngIf="files.length > 0">{{'photo-upload.you-are-about' | translate}} {{files.length}} {{'photo-upload.file' | translate}}</div>
  </div>
</div>
