import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';

export class Message {
  message: string;
  value?: any;
  type: 'success' | 'warn' | 'danger';
}

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() messages: Message[] = [];
  observedMessages: Message[] = [];
  navMessages: Message[] = [];
  @Input() messageSource: Observable<Message> = null;
  private unsubscribe = new Subject<boolean>();

  constructor(private router: Router) {
    const nav = this.router.getCurrentNavigation();
    if (nav) {
      const navExtras = nav.extras;
      if (navExtras) {
        if (navExtras.state) {
          if (navExtras.state.messages) {
            this.navMessages = navExtras.state.messages as Message[];
          }
        }
      }
    }
  }

  get visibleMessages(): Message[] {
    return this.navMessages.concat(this.messages).concat(this.observedMessages);
  }

  ngOnInit(): void {
    this.subscribeToMessages();
  }

  ngOnChanges(): void {
    this.subscribeToMessages();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  subscribeToMessages(): void {
    if (this.messageSource) {
      this.unsubscribe.next(true);
      this.unsubscribe.complete();
      this.unsubscribe = new Subject<boolean>();

      this.messageSource.pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(message => {
        this.observedMessages.push(message);
      });
    }
  }

  clear(): void {
    this.observedMessages = [];
  }
}
