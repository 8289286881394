<div id="home-container" role="main" class="cbrne-wrapper" >
  <div class="info-wrapper">
  <mat-card class="info-tile">
    <mat-card-title role="heading" aria-level="1">{{'cbrne-information.additional' | translate}}</mat-card-title>
    <mat-card-content>
      <p>{{'cbrne-information.welcome' | translate}}</p>
        <div id="new-cat-button">
          <p>
          <a routerLink="/information/add">
            <button mat-raised-button color="primary" *appIsAdmin>
              <mat-icon>add</mat-icon>
              {{'cbrne-information.new-category' | translate}}
            </button>
          </a>
          </p>
        </div>
    </mat-card-content>
  </mat-card>
  </div>
  <br>
  <br/>
  <div class="topic-wrapper" *appIsNotAdmin>
    <app-cbrne-info class="topic-card-user" *ngFor="let category of categoryItems" [category]="category"></app-cbrne-info>
  </div>
  <div class="topic-wrapper" *appIsAdmin>
    <div class="flex-left">
      <mat-card class="column-title">
        <mat-card-title role="heading" aria-level="1">{{'cbrne-information.public-documents' | translate}}</mat-card-title>
      </mat-card>
      <ng-container *ngFor="let category of categoryItems">
        <app-cbrne-info class="topic-card" *ngIf="!category.adminOnly" [category]="category"></app-cbrne-info>
      </ng-container>
    </div>
    <div class="flex-right">
      <mat-card class="column-title">
        <mat-card-title role="heading" aria-level="1">{{'cbrne-information.operational-documents' | translate}}</mat-card-title>
      </mat-card>
      <ng-container *ngFor="let category of categoryItems">
        <app-cbrne-info class="topic-card" *ngIf="category.adminOnly" [category]="category"></app-cbrne-info>
      </ng-container>
    </div>
  </div>
  <br/>
</div>
