import {MapLocation} from './map-location';

export class LatestNotificationModel {
  id: number;
  created: Date;
  location: MapLocation;
  headline: string;
  description: string;
  nextSteps: string;

  constructor(
    id: number,
    created: Date,
    location: MapLocation,
    headline: string,
    description: string,
    nextSteps: string
  ) {
    this.id = id;
    this.created = created;
    this.location = location;
    this.headline = headline;
    this.description = description;
    this.nextSteps = nextSteps;
  }

  static fromObject(
    obj: {
      id: number,
      created: Date,
      headline: string,
      description: string,
      location: MapLocation,
      nextSteps: string,
    }
  ): LatestNotificationModel {
    const id = obj.id;
    const created = obj.created;
    const location = obj.location;
    const headline = obj.headline;
    const description = obj.description;
    const nextSteps = obj.nextSteps;

    return new LatestNotificationModel(
      id,
      created,
      location,
      headline,
      description,
      nextSteps,
    );

  }
}
