import {Component, Inject, OnInit} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {Router} from '@angular/router';


export class FallbackNotificationOptions {
  title: string;
  body: string;
  data: {
    uri: string;
  };
}

@Component({
  selector: 'app-fallback-notification',
  templateUrl: './fallback-notification.component.html',
  styleUrls: ['./fallback-notification.component.scss']
})
export class FallbackNotificationComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: FallbackNotificationOptions,
    private ref: MatSnackBarRef<FallbackNotificationComponent>,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  get hasUri(): boolean {
    return !!this.data.data.uri;
  }

  view(event) {
    if (this.hasUri) {
      let url = this.data.data.uri;
      url = url.replace(window.location.origin, '');

      this.router.navigateByUrl(url);
      this.dismiss(event);
    }
  }

  dismiss(event) {
    this.ref.dismiss();
    event.preventDefault();
  }
}
