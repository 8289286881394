import {EnumMemberDetails, EnumMap} from '../utils/lookup-map';

export enum EIncidentClassification {
  Emergency,
  NonEmergency,
  Unknown
}

export class EIncidentClassificationDetails extends EnumMemberDetails<EIncidentClassification> {}

export const EIncidentClassificationLookup: EnumMap<EIncidentClassification, EIncidentClassificationDetails> =
  new EnumMap<EIncidentClassification, EIncidentClassificationDetails>(
  [
      [EIncidentClassification.Emergency, { id: EIncidentClassification.Emergency, name: 'Emergency' }],
      [EIncidentClassification.NonEmergency, { id: EIncidentClassification.NonEmergency, name: 'Non Emergency' }]
    ],
    { id: EIncidentClassification.Unknown, name: 'Unknown Classification' }
  );
