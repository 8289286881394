<div id="profile-view-wrapper" role="main">
<mat-card class="profile-tile center">
  <mat-card-title role="heading" aria-level="2" *ngIf="ownProfile">{{'profile.your-profile' | translate}}</mat-card-title>
  <mat-card-title role="heading" aria-level="2" *ngIf="!ownProfile">{{'profile.profile' | translate}}</mat-card-title>
  <ng-template #loading>
    <mat-card-content>
      <mat-spinner class="centered" aria-label="Loading Profile Data"></mat-spinner>
    </mat-card-content>
  </ng-template>
  <app-messages [messageSource]="messageStream"></app-messages>
  <mat-card-content *ngIf="profile; else loading">
    <div>{{'profile.email' | translate}}: {{ profile.email }}</div>
   
  </mat-card-content>
  <br>
  <div *ngIf="ownProfile">
    <mat-card-title role="heading" aria-level="2" >{{'profile.change' | translate}}</mat-card-title>
    <mat-card-content [formGroup]="changePasswordForm">
      <mat-form-field appearance="fill">
        <mat-label>{{'profile.current-password' | translate}}</mat-label>
        <input matInput type="password" autocomplete="password" formControlName="currentPassword">
        <mat-error *ngIf="changePasswordForm.controls.currentPassword.touched && !changePasswordForm.controls.currentPassword.value">{{'profile.password-cannot' | translate}}</mat-error>
        <mat-error *ngIf="changePasswordForm.controls.currentPassword.hasError('wrong')">{{'profile.password-wrong' | translate}}</mat-error>
      </mat-form-field>
      <br>
      <app-password-rules formControlName="password"></app-password-rules>
      <br>
    </mat-card-content>
    <mat-card-actions>
      <div class="left" style="margin-left: 8px;">
        <button mat-raised-button color="primary" [disabled]="!changePasswordForm.valid" (click)="changePassword()">{{'profile.change-pass' | translate}}</button>
        <button mat-raised-button color="primary" (click)="openPopUp()">{{'profile.delete' | translate}}</button>
      </div>
    </mat-card-actions>
  </div>
  <div *appIsAdmin>
      <div *ngIf="!ownProfile">
        <mat-card-title role="heading" aria-level="2" >{{'profile.reset' | translate}}</mat-card-title>
        <mat-card-content [formGroup]="resetPasswordForm">
          <div class="passwords">
            <app-password-rules formControlName="password"></app-password-rules>
          </div>
          <div class="user-role">
            <mat-card-title role="heading" aria-level="2" >{{'profile.user-role' | translate}}</mat-card-title>
        <mat-radio-group aria-label="user role" formControlName="role">
          <mat-radio-button *ngFor="let option of userRoles; trackBy: roleType" [value]="option.id">{{ option.name | translate}}</mat-radio-button>
        </mat-radio-group>
          </div>
          <div class="user-role">
          <mat-card-title role="heading" aria-level="2" >{{'profile.confirmed' | translate}}</mat-card-title>
          <mat-checkbox formControlName="isConfirmed"></mat-checkbox>
        </div>
          <div class="left">
            <button mat-raised-button color="primary" [disabled]="!changePasswordForm.valid && !resetPasswordForm.valid" (click)="saveChanges()">{{'profile.save' | translate}}</button>
          </div>
        </mat-card-content>
      </div>
  </div>
</mat-card>
<br/>
<mat-card class="profile-tile">
  <mat-card-title role="heading" aria-level="2">{{'profile.notification' | translate}}</mat-card-title>
  <mat-card-content>
    <app-notification-settings></app-notification-settings>
    <br>
    <!-- <input type="checkbox"  mat-raised-button color="primary" (click)="toggleLocation()">{{ gettingPosition ? ('location-input.fetching' | translate) : ('profile.toggle' | translate)}} -->
    <div style="display: grid; grid-template-columns: 1fr 1fr; align-items: center;">
    <span>{{ gettingPosition ? ('location-input.fetching' | translate) : ('profile.toggle' | translate)}}</span>
    <label class="switch">
      <input type="checkbox" (click)="toggleLocation()">
      <span class="slider round"></span>
    </label>
  </div>
  </mat-card-content>
</mat-card>
</div>
