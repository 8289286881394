import {Observable, Subscription} from "rxjs";
import {catchError, map, shareReplay} from "rxjs/operators";
import {handleHttpError} from "../utils/http-error-catcher";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AdditionalInfoResponseModel} from "../models/AdditionalInfoResponseModel";
import {Injectable} from "@angular/core";
import {AdditionalInfoFileModel} from "../models/AdditionalInfoFileModel";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})

export class AdditionalInfoUserService {
  private list: Observable<AdditionalInfoResponseModel[]> = null;
  private listCompleted = false;
  private listCompletedSubscription: Subscription;
  file: Observable<string>;

  private webFile: Observable<string> = null;
  private webFileCompleted = false;
  private webFileCompletedSubscription: Subscription;

  constructor(private http: HttpClient) { }

  list$(): Observable<AdditionalInfoResponseModel[]> {
    if (!this.list || this.listCompleted) {
      this.listCompleted = false;
      this.list = this.http.get<AdditionalInfoResponseModel[]>('additionalinformation/tree').pipe(
        catchError(handleHttpError),
        map(resp => {
          return resp.map(AdditionalInfoResponseModel.fromObject);
        }),
        shareReplay(1)
      );

      this.listCompletedSubscription = this.list.subscribe({ complete: this.onListCompleted.bind(this) });
    }

    return this.list;
  }

  onListCompleted(): void {
    this.listCompleted = true;
    setTimeout(() => this.listCompletedSubscription.unsubscribe(), 0);
  }

  getWebFile$(categoryId: number): Observable<string> {
    if (!this.webFile || this.webFileCompleted) {
      this.webFileCompleted = false;
      this.webFile = this.http.get(`additionalinformation/categories/${categoryId}/web`,
        {responseType: 'text'}).pipe(
        catchError(handleHttpError),
        shareReplay(1)
      );

      this.webFileCompletedSubscription = this.webFile.subscribe({ complete: this.onWebFileCompleted.bind(this) });
    }

    return this.webFile;
  }

  onWebFileCompleted(): void {
    this.webFileCompleted = true;
    setTimeout(() => this.webFileCompletedSubscription.unsubscribe(), 0);
  }

  getFileUrl(id): string {
    return `${environment.apiUrl}/additionalinformation/files/${id}`;
  }
  getPdfUrl(id): string {
    return `${environment.apiUrl}/additionalinformation/categories/${id}/pdf`;
  }
  getDownloadUrl(id): string {
    return `${environment.apiUrl}/additionalinformation/categories/${id}/download`;
  }
}
