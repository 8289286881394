import {Component, Input, OnInit} from '@angular/core';
import {IncidentModel} from '../../models/incident-model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {EIncidentStatus, EIncidentStatusLookup} from '../../models/incident-status.enum';
import {EIncidentClassification, EIncidentClassificationLookup} from '../../models/incident-classification.enum';

export interface IncidentFormResult {
  location?: {
    lat: number;
    lng: number;
    streetAddress?: string;
  };
  occurred: Date;
  headline?: string;
  description?: string;
  status?: EIncidentStatus;
  classification?: EIncidentClassification;
  nextSteps?: string;
  sendNotification: boolean;
  closed: boolean;
}

@Component({
  selector: 'app-incident-form',
  templateUrl: './incident-form.component.html',
  styleUrls: ['./incident-form.component.scss']
})
export class IncidentFormComponent implements OnInit {
  @Input() incident: IncidentModel;

  form: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
  }

  private configureFormGroup(): void {
    let formattedTime: string;
    const formattedDate = new Date(this.incident.occurred);

    formattedDate.setTime(formattedDate.getTime() - (formattedDate.getTimezoneOffset() * 60000));


    if (this.incident.occurred != null) {
      formattedTime = formattedDate.getHours().toString().padStart(2, '0') + ':' + formattedDate.getMinutes().toString().padStart(2, '0');
      formattedDate.setHours(0, 0, 0, 0);
    }

    this.form = this.formBuilder.group({
      location: new UntypedFormControl(
        {
          gps: {lat: this.incident.location.lat, lng: this.incident.location.lng},
          streetAddress: this.incident.location.streetAddress
        },
        [Validators.required]
      ),
      incidentTime: new UntypedFormControl({value: formattedTime, disabled: true}, [Validators.required]),
      incidentDate: new UntypedFormControl({value: formattedDate, disabled: true}, [Validators.required]),
      status: new UntypedFormControl({value: this.incident.status, disabled: this.isResolved()}, [Validators.required]),
      classification: new UntypedFormControl({value: this.incident.classification, disabled: true}, [Validators.required]),
      headline: new UntypedFormControl({value: this.incident.headline, disabled: true}, [Validators.required]),
      description: new UntypedFormControl({value: this.incident.description, disabled: true}, [Validators.required]),
      sendNotification: new UntypedFormControl('', [
        Validators.required
      ]),
      closed: new UntypedFormControl({value: false, disabled: !this.isResolved()})
    });
  }

  ngOnInit(): void {
    this.configureFormGroup();
  }

  isResolved(): boolean {
    return this.incident.status === EIncidentStatus.Resolved;
  }

  get displayLocation(): string {
    if (this.form.controls.location.value) {
      return `${this.form.controls.location.value.gps.lat}, ${this.form.controls.location.value.gps.lng}`;
    }

    return 'Unknown';
  }

  trackType(index: number, item: {id: number, name: string}): any {
    return item.id;
  }

  get incidentStatuses(): { id: number, name: string }[] {
    return Object.keys(EIncidentStatus).filter(k => typeof EIncidentStatus[k] === 'number').map(type => {
      const id = EIncidentStatus[type];

      return {
        id,
        name: EIncidentStatusLookup.get(id).name
      };
    });
  }

  get incidentClassifications(): { id: number, name: string }[] {
    return Object.keys(EIncidentClassification).filter(k => typeof EIncidentClassification[k] === 'number').map(type => {
      const id = EIncidentClassification[type];

      return {
        id,
        name: EIncidentClassificationLookup.get(id).name
      };
    });
  }

  getResult(): IncidentFormResult | null {
    if (!this.isValid()) {
      return null;
    }

    const results = this.form.controls;

    const date: Date = results.incidentDate.value;
    let timeValue = 0;

    timeValue += results.incidentTime.value.substring(0, 2) * 3600000;
    timeValue += results.incidentTime.value.substring(3) * 60000;

    date.setHours(0, 0, 0, 0);

    const dateTime = new Date(date.getTime() + timeValue);

    return {
      location: {
        lat: results.location.value.gps.lat,
        lng: results.location.value.gps.lng,
        streetAddress: results.location.value.streetAddress,
      },
      occurred: dateTime,
      headline: results.headline.value,
      description: results.description.value,
      status: results.status.value,
      classification: results.classification.value,
      sendNotification: results.sendNotification.value === 'true',
      nextSteps: this.incident.nextSteps,
      closed: results.closed.value
    };
  }

  isValid(): boolean {
    return this.form.valid;
  }
}
