import {EnumMap, EnumMemberDetails} from "../utils/lookup-map";
import {EIncidentClassification} from "./incident-classification.enum";

export enum EFileType {
  Jpeg,
  Png,
  Webp,
  Mp4,
  Webm,
  Mpeg,
  X_wav,
  Ogg,
  Opus,
  Pdf,
  Audio,
  Image,
  Video,
  Other,
  Text
}

export class EFileTypeDetails extends EnumMemberDetails<EFileType> {
  icon: string;
}

export const EFileTypeLookup: EnumMap<EFileType, EFileTypeDetails> =
  new EnumMap<EFileType, EFileTypeDetails>(
    [
      [EFileType.Jpeg, {id: EFileType.Jpeg, name: 'Jpeg', icon: 'image'}],
      [EFileType.Png, {id: EFileType.Png, name: 'Png', icon: 'image'}],
      [EFileType.Webp, {id: EFileType.Webp, name: 'Webp', icon: 'image'}],
      [EFileType.Mp4, {id: EFileType.Mp4, name: 'Mp4', icon: 'smart_display'}],
      [EFileType.Webm, {id: EFileType.Webm, name: 'Webm', icon: 'smart_display'}],
      [EFileType.Mpeg, {id: EFileType.Mpeg, name: 'Mpeg', icon: 'headphones'}],
      [EFileType.X_wav, {id: EFileType.X_wav, name: 'Wav', icon: 'headphones'}],
      [EFileType.Ogg, {id: EFileType.Ogg, name: 'Ogg', icon: 'headphones'}],
      [EFileType.Opus, {id: EFileType.Opus, name: 'Opus', icon: 'headphones'}],
      [EFileType.Pdf, {id: EFileType.Pdf, name: 'Pdf', icon: 'picture_as_pdf'}],
      [EFileType.Audio, {id: EFileType.Audio, name: 'Audio', icon: 'headphones'}],
      [EFileType.Image, {id: EFileType.Image, name: 'Image', icon: 'image'}],
      [EFileType.Video, {id: EFileType.Video, name: 'Video', icon: 'smart_display'}],
      [EFileType.Text, {id: EFileType.Text, name: 'Text', icon: 'web'}],
      [EFileType.Other, {id: EFileType.Other, name: 'Other', icon: 'file_download'}],
    ]
  );
