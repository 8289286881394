import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {RegistrationModel} from '../../models/registration-model';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Message} from '../../components/messages/messages.component';
import {PasswordRulesComponent} from '../../components/password-rules/password-rules.component';

@Component({
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  @ViewChild(PasswordRulesComponent) passwordRulesComponent: PasswordRulesComponent;
  form: UntypedFormGroup;
  private unsubscribe = new Subject<boolean>();
  messageStream = new Subject<Message>();
  registerMessages: Message[] = [];

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new UntypedFormControl('', [
      ]),
      privacyPolicyAgreed: new UntypedFormControl(false, [
        Validators.required
      ])
    }, {
      validators: [
        this.privacyPolicy
      ]
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }


  privacyPolicy(control: UntypedFormGroup): ValidationErrors | null {
    const agreed = control.get('privacyPolicyAgreed');

    return !agreed.value ? { privacyPolicy: true } : null;
  }

  registerAccount() {
    if (!this.form.valid) {
      return false;
    }

    this.registerMessages = [];

    const data = new RegistrationModel(this.form.controls.email.value, this.form.controls.password.value.password);

    this.auth.registerAccount$(data).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      resp => {
        this.router.navigateByUrl(
          'login',
          { state: { messages: [{type: 'success', message: 'app-message.register.success'}] } }
        );
    },
      error =>
            this.passwordRulesComponent.handleError(error));
            this.registerMessages[0] = {
              message: 'app-message.register.failed',
              type: 'danger'
            };
  }
  goToLink(url: string){
    window.open(url, "_blank");
  }
}
// TODO: Don't validate while typing
// TODO: GDS Validation standards: https://design-system.service.gov.uk/patterns/validation/

