import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable, Subscription, throwError} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {handleHttpError} from '../utils/http-error-catcher';
import {IncidentModel} from '../models/incident-model';
import {EIncidentStatusLookup} from '../models/incident-status.enum';
import {IncidentCreateModel} from '../models/incident-create-model';
import {EIncidentClassificationLookup} from '../models/incident-classification.enum';
import {IncidentFormResult} from '../components/incident-form/incident-form.component';

interface IncidentDetailResponseModel {
  id: number;
  classification: string;
  status: string;
  type: string;
  reported: string;
  occurred: Date;
  headline: string;
  description: string;
  resolved: string | null;
  location: { lat: number, lng: number, streetAddress: string };
  audit: {
    auditId: number, dateCreated: number, dateDeleted: number | null,
    dateUpdated: number | null, userCreated: string, userDeleted: string | null, userUpdated: string | null
  };
  nextSteps: string;
  sendNotification: boolean;
  closed: boolean;
}

interface IncidentCreateResponseModel {
  id: number;
  success: boolean;
}

interface IncidentUpdateResponseModel {
  id: number;
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  private list: Observable<IncidentModel[]> = null;
  private listCompleted = false;
  private listCompletedSubscription: Subscription;
  private getMap: Map<number, Observable<IncidentModel>> = new Map();
  private getCompletedMap: Map<number, boolean> = new Map();
  private getCompletedSubscriptionMap: Map<number, Subscription> = new Map();

  constructor(private http: HttpClient, private auth: AuthService) { }

  private static mapDetailResponse(resp: IncidentDetailResponseModel): IncidentModel {
    return IncidentModel.fromObject({
      ...resp,
      reportedDate: new Date(resp.reported),
      resolvedDate: resp.resolved ? new Date(resp.resolved) : null ,
      classification: EIncidentClassificationLookup.findByName(resp.classification).id,
      status: EIncidentStatusLookup.findByName(resp.status).id,
      occurred: resp.occurred,
      headline: resp.headline,
      description: resp.description,
      nextSteps: resp.nextSteps,
      incidentAudit: resp.audit,
      closed: resp.closed
    });
  }

  // TODO: Filter? Pagination?
  list$(): Observable<IncidentModel[]> {
    if (!this.list || this.listCompleted) {
      this.listCompleted = false;
      this.list = this.http.get<IncidentDetailResponseModel[]>('incidents').pipe(
        catchError(handleHttpError),
        map(resp => {
          return resp.map(IncidentService.mapDetailResponse);
        }),
        shareReplay(1)
      );

      this.listCompletedSubscription = this.list.subscribe({ complete: this.onListCompleted.bind(this) });
    }

    return this.list;
  }

  onListCompleted(): void {
    this.listCompleted = true;
    setTimeout(() => this.listCompletedSubscription.unsubscribe(), 0);
  }

  get$(id: number): Observable<IncidentModel> {
    let obs = this.getMap.get(id);
    if (!obs || this.getCompletedMap.get(id)) {
      this.getCompletedMap.set(id, false);
      obs = this.http.get<IncidentDetailResponseModel>(`incidents/${id}`).pipe(
        catchError(handleHttpError),
        map(IncidentService.mapDetailResponse),
        shareReplay(1)
      );
      this.getMap.set(id, obs);

      const subscription = obs.subscribe({complete: this.onGetCompleted(id)});
      this.getCompletedSubscriptionMap.set(id, subscription);
    }

    if (!obs) {
      return throwError('Not found');
    }

    return obs;
  }

  onGetCompleted(id: number): () => void {
    return () => {
      this.getCompletedMap.set(id, true);
      setTimeout(() => this.getCompletedSubscriptionMap.get(id).unsubscribe(), 0);
    };
  }

  create(data: IncidentCreateModel): Observable<number> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Create Incidents.');
    }

    const body = {...data};

    return this.http.post<IncidentCreateResponseModel>(`incidents`, body).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.id;
      })
    );
  }

  update$(incident): Observable<boolean> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Update Incidents.');
    }

    const body = {
      ...incident,
      reported: incident.reportedDate && 'toISOString' in incident.reportedDate ? incident.reportedDate.toISOString() : undefined,
      resolved: incident.resolvedDate && 'toISOString' in  incident.resolvedDate ? incident.resolvedDate.toISOString() : undefined,
    };

    return this.http.put<IncidentCreateResponseModel>(`incidents/${incident.id}`, body).pipe(
      catchError(handleHttpError),
      map(resp => resp.success)
    );
  }
}
