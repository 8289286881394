<div id="incident-update-wrapper" role="main">

  <mat-card id="incident-update-container">

    <div *ngIf="incident && isClosed">
      <Label>{{'incident-edit.closed' | translate}}</Label>
    </div>

    <div *ngIf="incident && !isClosed">
      <app-incident-form #incidentFormComponent [incident]="incident"></app-incident-form>
    </div>

    <div  class="right">
      <button mat-raised-button color="primary" (click)="postUpdate()">{{'incident-edit.save' | translate}}</button>
    </div>

  </mat-card>

</div>
