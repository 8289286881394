<div id="info-container">
<mat-card class="topic">
  <mat-card-title><span>{{topicCategory}}</span>
    <span class="right-button" style="float: right">
      <a routerLink="/information/{{topicCategoryId}}/edit">
      <button mat-raised-button color="primary" *appIsAdmin style="alignment: right">
        <mat-icon style="alignment: right">edit</mat-icon>
        {{'general.edit' | translate}}
      </button>
    </a>
      <button mat-raised-button color="warn" *appIsAdmin style="alignment: right" (click)="deleteCategory(topicCategoryId)">
        <mat-icon>delete</mat-icon>
        {{'general.delete' | translate }}
      </button>
    </span>
    </mat-card-title>
  <mat-card-content>
    <div *ngFor="let item of categoryItems">
      <span>{{item.description}}</span>
      <span *ngFor="let file of item.files">
        <mat-icon class="file-icon" (click)="FileTypeAction(file.id)" role="button" title="{{getFileTypeName(file.type)}} File">
          {{getFileTypeIcon(file.type)}}
        </mat-icon>
      </span>
    </div>
  </mat-card-content>
</mat-card>
</div>
