import { Component, OnInit } from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {AdditionalInfoAdminService} from "../../services/additional-info-admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {AdditionalInfoFileModel} from "../../models/AdditionalInfoFileModel";
import {AdditionalFileSubmission} from "../../models/AdditionalFileSubmission";

@Component({
  selector: 'app-cbrne-add-file',
  templateUrl: './cbrne-add-file.component.html',
  styleUrls: ['./cbrne-add-file.component.scss']
})
export class CbrneAddFileComponent implements OnInit {
  itemId: number;
  form: UntypedFormGroup;
  types: string[] = ['*/*'];
  submission: AdditionalFileSubmission;
  files: unknown[];

  constructor(private service: AdditionalInfoAdminService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.itemId = parseInt(this.route.snapshot.paramMap.get('itemid'), 10);
    this.form = new UntypedFormGroup({
      files: new UntypedFormControl('', [Validators.required]),
    });
  }

  addFile() {
    if (!this.form.valid) {
      return false;
    }

    const data = new AdditionalFileSubmission(
      1,
      this.itemId,
      this.files,
    );

    const Files = this.form.get('files').value?.map(f => f.data as Promise<string>) || [];
    Promise.all(Files).then(
      files => {
        data.filepaths = files;

        this.service.createFile(data).subscribe(() => {
            this.router.navigateByUrl(
              'information',
              {state: {messages: [{type: 'success', message: 'The File has been created.'}]}}
            );
          }, err => console.error(err)
        );
      });
}}
