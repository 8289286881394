import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {Message} from '../../../components/messages/messages.component';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';
import {PasswordDifferenceErrorStateMatcher} from '../../../utils/password-error-state-matcher';
import {ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {PasswordRulesComponent} from '../../../components/password-rules/password-rules.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  @ViewChild(PasswordRulesComponent) child: PasswordRulesComponent;
  messages: { message: string, type: string }[];
  form: UntypedFormGroup;
  passwordErrorMatcher = new PasswordDifferenceErrorStateMatcher();
  private unsubscribe = new Subject<boolean>();
  resetMessages: Message[] = [];

  constructor(private auth: AuthService, private routeSnapshot: ActivatedRoute, private users: UserService) { }

  ngOnInit(): void {
    this.routeSnapshot.queryParamMap.subscribe(params => {
      this.form = new UntypedFormGroup({
        token: new UntypedFormControl(params.get('token')),
        password: new UntypedFormControl('', [
        Validators.required
      ]),
    });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  reset() {
    if (!this.form.valid) {
      return false;
    }

    this.resetMessages = [];

    this.auth.resetPassword({ token: this.form.controls.token.value, password: this.form.controls.password.value.password }).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(resp => {
        this.resetMessages[0] = {
          message: 'app-message.reset-password.success',
          type: 'success'
        };
      },
      error => {
        if (error.error instanceof Array) {
          this.child.handleError(error);
        } else {
          this.resetMessages[0] = {
            message: 'app-message.reset-password.error',
            type: 'danger'
          };
        }
      }
    );
  }
}
