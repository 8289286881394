<div id="user-create-wrapper" role="main">
  <mat-card id="user-create-container">
    <mat-card-title role="heading" aria-level="2">{{'user-create.create' | translate}}</mat-card-title>
    <div [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{'user-create.email' | translate}}</mat-label>
        <input matInput type="email" autocomplete="email" spellcheck="false" formControlName="email">
        <mat-error *ngIf="form.controls.email.touched && !form.controls.email.value">{{'user-create.email-cannot' | translate}}</mat-error>
        <mat-error *ngIf="form.controls.email.hasError('email')">{{'user-create.please-enter' | translate}}</mat-error>
        <mat-error *ngIf="form.controls.email.hasError('accountExists')">{{'user-create.already-exists' | translate}}</mat-error>
      </mat-form-field>
      <br>
      <div><app-password-rules formControlName="password"></app-password-rules></div>
      <div id="user-role">
        <mat-label>{{'user-create.user-role' | translate}}</mat-label>
        <br/>
      </div><div>
        <mat-radio-group aria-label="user role" formControlName="role">
          <mat-radio-button *ngFor="let option of userRoles; trackBy: trackRole" [value]="option.id">{{ option.name | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-checkbox formControlName="isConfirmed">{{'user-create.is-confirmed' | translate}}</mat-checkbox>
      <br>
      <div class="right">
        <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="createAccount()">{{'user-create.create-account' | translate}}</button>
      </div>
    </div>
  </mat-card>
</div>
