import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {IncidentModel} from '../../models/incident-model';
import {MapLoaderService} from '../../services/map-loader.service';

const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => LocationMapComponent),
  multi: true
};

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR]
})

export class LocationMapComponent implements ControlValueAccessor {

  constructor(public mapLoader: MapLoaderService) {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
  }

  get mapLocation(): { lat: number, lng: number } {
    return this.value || { lat: 51.5905532, lng: 7.3805655 };
  }
  value: { lat: number, lng: number };
  private onChange: (_: any) => void;
  private onTouched: () => void;

  selectLocation(latLng) {
    this.value = { lat: latLng.lat(), lng: latLng.lng() };
    this.onChange(this.value);
    this.onTouched();
  }

  writeValue(obj: { lat: number, lng: number }): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Don't bother with disabling for this component
  }
}
