export class IncidentUpdateModel {
  id: number;
  datetime: Date;
  submitter: string;
  description: string;
  photos: string[] | null;
  isPublic: boolean;
  sendNotification: boolean;

  constructor(id: number, datetime: Date, submitter: string, description: string, photos: string[] | null, isPublic: boolean, sendNotification: boolean) {
    this.id = id;
    this.datetime = datetime;
    this.submitter = submitter;
    this.description = description;
    this.photos = photos;
    this.isPublic = isPublic;
    this.sendNotification = sendNotification;
  }

  static fromObject(obj: {
    id: number, datetime: Date, submitter: string, description: string, photos: string[], isPublic: boolean, sendNotification: boolean
  }): IncidentUpdateModel {
    return new IncidentUpdateModel(
      obj.id,
      obj.datetime,
      obj.submitter,
      obj.description,
      obj.photos,
      obj.isPublic,
      obj.sendNotification
    );
  }
}
