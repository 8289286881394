<div id="additional-info-add" role="main">
  <mat-card id="additional-info-add-container">
    <form [formGroup]="form">
      <mat-card-title role="heading" aria-level="2">{{'additional-info-submit.add-a-new-category' | translate}}</mat-card-title>
      <div>
      <mat-form-field appearance="fill">
        <mat-label>{{'additional-info-submit.category-name' | translate}}</mat-label>
        <textarea matInput formControlName="name"></textarea>
        <div *ngIf="form.controls['name'].touched && form.controls['name'].invalid"></div>
        <mat-error>{{'additional-info-submit.name-is-required' | translate}}</mat-error>
      </mat-form-field>
      </div>
      <div id="admin-only-div">
        <mat-radio-group aria-label="Select an option" formControlName="adminOnly">
          <mat-label>{{'additional-info-submit.visible-to-admins-only' | translate}}</mat-label><br>
          <mat-radio-button [value]="true">{{'general.yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'general.no' | translate}}</mat-radio-button>
          <mat-error *ngIf="form.controls.adminOnly.pristine">{{'additional-info-submit.admin-option-required' | translate}}</mat-error>
        </mat-radio-group>
        <p>{{'additional-info-submit.information-items-and-files' | translate}}</p>
      </div>
    </form>
    <div>
      <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid" (click)="addCategory()">{{'general.submit' | translate}}</button>
    </div>
  </mat-card>
</div>
