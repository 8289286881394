import {Component, forwardRef, OnInit} from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgForm,
  ValidationErrors,
  Validators
} from '@angular/forms';


const CUSTOM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PasswordRulesComponent),
  multi: true
};

@Component({
  selector: 'app-password-rules',
  templateUrl: './password-rules.component.html',
  styleUrls: ['./password-rules.component.scss'],
  providers: [CUSTOM_VALUE_ACCESSOR]
})
export class PasswordRulesComponent implements ControlValueAccessor {

  private onChange: (_: any) => void;
  private onTouched: () => void;

  constructor() {
    this.onChange = (_: any) => {};
    this.onTouched = () => {};
  }

  rulesHighlighted = false;
  passwordErrorMatcher = new PasswordDifferenceErrorStateMatcher();

  public passwordform: UntypedFormGroup = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required]),
      confirmPassword: new UntypedFormControl('', [Validators.required])
    }, {
      validators: [
        this.passwordsDiffer
      ]
    });

    writeValue(obj: any): void {
        this.passwordform.patchValue(obj);
    }
    registerOnChange(fn: any): void {
        this.passwordform.valueChanges.subscribe(fn);
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {

    }


  passwordsDiffer(control: UntypedFormGroup): ValidationErrors | null {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    return password.dirty && confirmPassword.dirty && password.value !== confirmPassword.value ? { passwordsDiffer: true } : null;
  }

  highlightRules() {
    this.rulesHighlighted = true;
  }

    handleError(err: any) {
    if (err.status === 401) {
      // Unauthorised
      this.passwordform.controls.currentPassword.setErrors({wrong: true});
    } else if (err.status === 400) {
      err.error.forEach(e => {
        if (e.code === 'PasswordTooShort') {
          this.passwordform.controls.password.setErrors({short: true});
        } else if (e.code === 'PasswordRequiresNonAlphanumeric') {
          this.passwordform.controls.password.setErrors({alphanumeric: true});
        } else if (e.code === 'PasswordRequiresLower') {
          this.passwordform.controls.password.setErrors({lower: true});
        } else if (e.code === 'PasswordRequiresUpper') {
          this.passwordform.controls.password.setErrors({upper: true});
        } else {
          console.error(e.code, e.description);
        }});
    } else {
      console.log(err);
    }

  }
}

class PasswordDifferenceErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return form.hasError('passwordsDiffer');
  }
}
