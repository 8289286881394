export class RegistrationModel {
  email: string;
  password: string;

  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }

  static fromObject(data: any): RegistrationModel {
    if (!data.email || ! data.password) {
      throw new Error('Object must have both email & password properties');
    }
    const email = data.email;
    const role = data.role;

    return new RegistrationModel(
      email,
      role
    );

  }
}
