import {Component, Input, OnInit} from '@angular/core';
import {IncidentModel} from '../../models/incident-model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IncidentService} from '../../services/incident.service';
import {EIncidentStatus, EIncidentStatusLookup} from '../../models/incident-status.enum';
import {EIncidentClassification, EIncidentClassificationLookup} from '../../models/incident-classification.enum';

@Component({
  selector: 'app-incident-info',
  templateUrl: './incident-info.component.html',
  styleUrls: ['./incident-info.component.scss']
})
export class IncidentInfoComponent implements OnInit {
  editing = false;

  @Input() incident: IncidentModel;
  form: UntypedFormGroup;

  constructor(private service: IncidentService) {

  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
        classification: new UntypedFormControl(this.incident.classification, [Validators.required]),
        status: new UntypedFormControl(this.incident.status, [Validators.required]),
        reported: new UntypedFormControl(this.incident.reportedDate, [Validators.required]),
        resolved: new UntypedFormControl(this.incident.resolvedDate, []),
        nextSteps: new UntypedFormControl(this.incident.nextSteps, []),
        location: new UntypedFormControl(this.incident.location, [Validators.required]),
      },
      {validators: []});

    if (this.incident.status !== EIncidentStatus.Resolved) {
      this.incident.resolvedDate = null;
    }
  }

  get incidentStatuses(): { id: number, name: string }[] {
    return Object.keys(EIncidentStatus).filter(k => typeof EIncidentStatus[k] === 'number').map(type => {
      const id = EIncidentStatus[type];

      return {
        id,
        name: EIncidentStatusLookup.get(id).name
      };
    });
  }

  get incidentClassifications(): { id: number, name: string }[] {
    return Object.keys(EIncidentClassification).filter(k => typeof EIncidentClassification[k] === 'number').map(type => {
      const id = EIncidentClassification[type];

      return {
        id,
        name: EIncidentClassificationLookup.get(id).name
      };
    });
  }

  trackType(index: number, item: {id: number, name: string}): any {
    return item.id;
  }

  // saveForm(): void {
  //   this.service.update(this.incident.id, this.form.value).subscribe(
  //     () => {
  //       this.reloadIncident();
  //     },
  //     err => console.error(err));
  // }

  reloadIncident(): void {
    this.service.get$(this.incident.id).subscribe(
      next => this.incident = next,
      err => console.error(err),
    );
  }
}
