<div class="incident-info-container">
  <div class="incident-info-item">
    <span class="incident-info-label">{{'incident-info.incident-id' | translate }}</span>
    <span class="incident-info-value">{{ incident.id }}</span>
  </div>
  <div class="incident-info-item" *appIsAdmin>
    <span class="incident-info-label">{{'incident-info.reporter' | translate }}</span>
    <span class="incident-info-value" *ngIf="incident.incidentAudit?.userCreated">{{ incident.incidentAudit.userCreated }}</span>
    <span class="incident-info-value" *ngIf="!incident.incidentAudit?.userCreated">N/a</span>
  </div>
  <!-- Gap filler for spacing -->
  <div class="incident-info-item"></div>
</div>
<div class="incident-info-container">
  <div class="incident-info-item">
    <span class="incident-info-label">{{'incident-info.reported-date' | translate }}</span>
    <span class="incident-info-value">{{ incident.reportedDate | date: 'yyyy-MM-dd' }} ({{ incident.reportedDate | date: 'HH:mm' }})</span>
  </div>
  <div class="incident-info-item">
    <span class="incident-info-label">{{'incident-info.status' | translate }}</span>
    <span class="incident-info-value">{{ incident.displayStatus | translate }}</span>
  </div>
  <div class="incident-info-item">
    <span class="incident-info-label">{{'incident-info.classification' | translate }}</span>
    <span class="incident-info-value">{{ incident.displayClassification | translate }}</span>
  </div>
<!--   Gap filler for spacing-->
  <div class="incident-info-item"></div>
</div>
<div class="incident-info-container">
  <div class="incident-info-item">
    <span class="incident-info-label">{{'incident-info.date-occured' | translate }}</span>
    <span class="incident-info-value">{{ incident.occurred | date: 'yyyy-MM-dd' }} ({{ incident.occurred | date: 'HH:mm' }})</span>
  </div>
  <div class="incident-info-item" *ngIf="incident.resolvedDate !== null">
    <span class="incident-info-label">{{'incident-info.resolved-date' | translate }}</span>
    <span class="incident-info-value">{{incident.resolvedDate | date: 'yyyy-MM-dd'}} ({{ incident.resolvedDate | date: 'HH:mm'}})</span>
  </div>
  <!--   Gap filler for spacing-->
  <div class="incident-info-item"></div>
</div>
<div class="incident-info-container">
  <div class="incident-info-item">
    <span class="incident-info-label">{{'incident-info.headline' | translate }}</span>
    <span class="incident-info-value">{{incident.headline}}</span>
  </div>
</div>
<div class="incident-info-container">
  <div class="incident-info-item">
    <span class="incident-info-label">{{'incident-info.description' | translate }}</span>
    <span class="incident-info-value">{{incident.description}}</span>
  </div>
</div>
<div class="incident-info-container">
  <div class="next-steps">
    <div *ngIf="!editing">
      <span *ngIf="incident.nextSteps" class="next-steps-label">{{'incident-info.live-update' | translate }} {{incident.nextSteps}}</span>
      <span *ngIf="!incident.nextSteps" class="next-steps-label">{{'incident-info.live-update-no-updated-reported' | translate }}</span>
    </div>
    <br>
    <span class="next-steps-label" *ngIf="incident.incidentAudit.dateUpdated != null">{{'incident-info.updated' | translate }} {{ incident.incidentAudit.dateUpdated | date: 'yyyy-MM-dd' }} ({{ incident.incidentAudit.dateUpdated | date: 'HH:mm' }})</span>
    <span class="next-steps-label" *ngIf="incident.incidentAudit.dateUpdated == null">{{'incident-info.updated' | translate }} {{ incident.incidentAudit.dateCreated | date: 'yyyy-MM-dd' }} ({{ incident.incidentAudit.dateCreated | date: 'HH:mm' }})</span>
    <mat-form-field *ngIf="editing" class="next-steps-form">
      <textarea matInput placeholder="Next Steps" [value]="incident.nextSteps" formControlName="nextSteps"></textarea>
    </mat-form-field>
    <br><br>
  </div>
</div>
