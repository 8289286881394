<div id="incident-create-wrapper" role="main">
    <mat-card id="incident-create-container" *ngIf="submission">
      <form [formGroup]="form">
  
        <mat-card-title role="heading" aria-level="2">{{'incident-create.report-incident-to-public' | translate}}</mat-card-title>
  
        <div style="display: grid;">
          <app-location-view style="padding-bottom: 1rem;" [location]="submission.location" ></app-location-view>
          <mat-label style="padding-bottom: 0.5rem;">Location of Incident: {{ submission.location.lat }} {{submission.location.lng }} </mat-label>
        </div>
  
        <div id="form-layout">
          <mat-form-field>
            <mat-label>{{'incident-create.time-of-incident' | translate}}</mat-label>
            <input type="time" matInput formControlName="incidentTime" >
          </mat-form-field>
  
          <app-iso-datepicker [form]="form"></app-iso-datepicker>
  
          <mat-form-field>
            <mat-label>{{'incident-create.incident-status' | translate}}</mat-label>
            <mat-select formControlName="status">
              <mat-option *ngFor="let option of incidentStatuses; trackBy: trackType" [value]="option.id">
                {{ option.name | translate }}
              </mat-option>
            </mat-select>
            <mat-error>{{'incident-create.status' | translate}}</mat-error>
          </mat-form-field>
  
          <mat-form-field>
            <mat-label>{{'incident-create.incident-classification' | translate}}</mat-label>
            <mat-select formControlName="classification">
              <mat-option *ngFor="let option of incidentClassifications; trackBy: trackType" [value]="option.id">
                {{ option.name | translate }}
              </mat-option>
            </mat-select>
            <mat-error id="classification-error">{{'incident-create.classification' | translate}}</mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="text-input">
            <mat-label>{{'incident-create.headline-of-incident' | translate}}</mat-label>
            <textarea matInput formControlName="headline"></textarea>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="text-input">
            <mat-label>{{'incident-form.description-of-incident' | translate}}</mat-label>
            <textarea matInput formControlName="description" [(ngModel)]="submission.description"></textarea>
          </mat-form-field>
  
          <mat-checkbox (change)="showUpdateForm()">{{'incident-create.add-an-update' | translate}}</mat-checkbox>
          <app-incident-update *ngIf="addUpdate" [formGroup]="form"></app-incident-update>
  
          <mat-label>{{'incident-create.send' | translate}}</mat-label>
          <mat-error *ngIf="form.controls.sendNotification.pristine">{{'incident-create.notification' | translate}}</mat-error>
          <mat-radio-group aria-label="option to send Live Notification" formControlName="sendNotification">
            <mat-radio-button value="1">{{'general.yes' | translate}}</mat-radio-button>
            <mat-radio-button [checked]="true" value="2">{{'general.no' | translate}}</mat-radio-button>
          </mat-radio-group>
          <div class="right">
            <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="submitIncident()">{{'incident-create.create-incident' | translate}}</button>
          </div>
        </div>
  
      </form>
  
    </mat-card>
  </div>
  