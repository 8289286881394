import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NewsModel} from '../../models/news-model';
import {NewsService} from '../../services/news.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-news-create',
  templateUrl: './news-create.component.html',
  styleUrls: ['./news-create.component.scss']
})
export class NewsCreateComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(private router: Router, private news: NewsService, private translate: TranslateService) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl('', [
        Validators.required
      ]),
      category: new UntypedFormControl('', [
        Validators.required
      ]),
      uri: new UntypedFormControl('', [
        Validators.required
      ]),
      imageUri: new UntypedFormControl(''),
      imageAlt: new UntypedFormControl('')
    });
  }

  createNewsItem() {
    if (!this.form.valid) {
      return false;
    }

    const data = NewsModel.fromObject(this.form.value);
    console.log(this.form.value, data);

    this.news.create(data).subscribe(resp => {
      this.router.navigateByUrl(
        'news',
        {state: {messages: [{type: 'success', message: this.translate.instant('news-create.news-item-created')}]}}
      );
    });
  }
}
