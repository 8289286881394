<div class="contacts-container" role="main">
  <mat-card class="contacts-tile">
    <mat-card-title role="heading" aria-level="1">{{'contacts.contacts' | translate}}</mat-card-title>
  </mat-card>
  <br>
  <div class="card-grid-wrapper">
    <div fxLayout="row wrap" fxLayoutAlign="center space-between" fxLayoutGap="3em grid" class="card-grid">
      <app-contact-card fxFlex="30em" *ngFor="let contact of keyContacts" [contact]="contact">
      </app-contact-card>
    </div>
  </div>
</div>

