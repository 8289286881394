<div id="additional-info-edit">
  <mat-card id="additional-info-edit-container">
    <mat-card-content>
    <form [formGroup]="form" *ngIf="form">
      <div>
      <mat-form-field appearance="fill">
        <mat-label>{{'cbrne-info-edit.category-name' | translate}}</mat-label>
        <textarea matInput formControlName="name"></textarea>
      </mat-form-field>
      </div>

    <table mat-table id="item-table" [dataSource]="category.items" *ngIf="form" class="mat-elevation-z8" aria-label="Table of Items">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> {{'cbrne-info-edit.id' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> {{'cbrne-info-edit.description' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>

      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> {{'cbrne-info.actions' | translate }} </th>
        <td mat-cell *matCellDef="let element">
          <a routerLink="/information/{{categoryId}}/edit/{{element.id}}/edit">
        <button mat-raised-button color="primary">
          <mat-icon>edit</mat-icon>{{'general.edit' | translate}}
        </button>
          </a>
          <button mat-raised-button color="warn" class="user-button" (click)="delete(element.id)"><mat-icon>delete</mat-icon>{{'general.delete' | translate }}</button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <a routerLink="/information/{{categoryId}}/edit/add">
      <button mat-raised-button color="primary" *appIsAdmin>
        <mat-icon>add</mat-icon>
        {{'cbrne-info-edit.add-new-item' | translate}}
      </button>
    </a>
      <div>
        <mat-radio-group aria-label="Select an option" [(ngModel)]="category.adminOnly" formControlName="adminOnly">
          <p>{{'cbrne-info-edit.visible-to-admins-only' | translate}}</p>
          <mat-radio-button [value]="true">{{'general.yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false">{{'general.no' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </form>
    <div>
      <button mat-raised-button type="submit" color="primary" (click)="editCategory()">{{'general.submit' | translate}}</button>
      <button mat-raised-button color="primary" (click)="back()">{{'general.back' | translate}}</button>
    </div>
    </mat-card-content>
  </mat-card>
</div>
