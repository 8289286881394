import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {of, ReplaySubject} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MapLoaderService {
  mapsLoaded = new ReplaySubject<boolean>();

  // Loads the Google Maps script using the API key that is set in the current environment
  constructor(private http: HttpClient) {
    const mapKey = environment.mapKey;
    const url = mapKey ? `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=visualization,places` : 'https://maps.googleapis.com/maps/api/js?&libraries=visualization,places';
    http.jsonp(url, 'callback')
      .pipe(
        map(() => true),
        catchError(err => {
          console.log(err);
          return of(false);
        }),
      ).subscribe(v => {
        this.mapsLoaded.next(v);
    });
  }
}
