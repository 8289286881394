export class StatisticsModel {
    id: number;
    registeredUsers: number;
    reportedIncidents: number;
    numberOfDownloads: number;
    sendedPushNotifications: number;

  constructor(id: number, registeredUsers: number, reportedIncidents: number, numberOfDownloads: number, sendedPushNotifications: number) {
    this.id = id;
    this.registeredUsers = registeredUsers;
    this.reportedIncidents = reportedIncidents;
    this.numberOfDownloads = numberOfDownloads;
    this.sendedPushNotifications = sendedPushNotifications;
  }

  static fromObject(data: any):StatisticsModel {
    const id = data.id;
    const registeredUsers = data.registeredUsers;
    const reportedIncidents = data.reportedIncidents;
    const numberOfDownloads = data.numberOfDownloads;
    const sendedPushNotifications = data.sendedPushNotifications;
    return new StatisticsModel(
      id,
      registeredUsers,
      reportedIncidents,
      numberOfDownloads,
      sendedPushNotifications
    );
  }
}
