<div id="confirm-email-wrapper" role="main">
  <mat-card *ngIf="confirmed == null" class="center" id="confirm-email-container">
    <mat-card-title role="heading" aria-level="2">{{'confirm-email.confirming' | translate}}</mat-card-title>
  </mat-card>
  <mat-card *ngIf="confirmed == true" class="center" id="confirm-email-container">
    <mat-card-title role="heading" aria-level="2">{{'confirm-email.your-email' | translate}}</mat-card-title>
    <mat-card-content>
      <a routerLink="/">
        <button mat-raised-button color="primary" role="link" aria-label="Got to Dashboard">
          {{'confirm-email.go-to-dashboard' | translate}}
        </button>
      </a>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="confirmed == false" class="center" id="confirm-email-container">
    <mat-card-title role="heading" aria-level="2">{{'confirm-email.failed' | translate}}</mat-card-title>
    <mat-card-content>
      <p>{{'confirm-email.we-could-not' | translate}}</p>
      <p>{{ error }}</p>
    </mat-card-content>
  </mat-card>
</div>
