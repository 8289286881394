<ng-container [formGroup]="location">
  <app-location-map #map formControlName="gps"></app-location-map>
  <br>
  <button mat-raised-button color="primary" class="location-button"
          (click)="useCurrentLocation()"
          [disabled]="gettingPosition"
          [ngStyle]='{"margin-bottom": "1em"}'>
    {{ gettingPosition ? ('location-input.fetching' | translate) : ('location-input.gps' | translate)}}
  </button>
  <button mat-raised-button color="primary" class="location-button" (click)="IsStreetAddress = true">{{'location-input.street-address' | translate}}</button>
  <div [hidden]="!IsStreetAddress">
    <p>{{'location-input.if-known' | translate}}</p>
    <div id="address-input">
    <mat-form-field appearance="fill">
      <mat-label>{{'location-input.street-address' | translate}}</mat-label>
      <input (change)="this.location" matInput formControlName="streetAddress" placeholder="{{'location-input.street-address-prompt' | translate}}" #address>    </mat-form-field>
    </div>
  </div>
</ng-container>
