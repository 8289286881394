import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EIncidentStatus} from '../../models/incident-status.enum';
import {IncidentService} from '../../services/incident.service';
import {IncidentModel} from '../../models/incident-model';
import {IncidentUpdateService} from '../../services/incident-update.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {IncidentFormComponent} from '../../components/incident-form/incident-form.component';
import {IncidentUpdateModel} from '../../models/incident-update-model';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-incident-edit',
  templateUrl: './incident-edit.component.html',
  styleUrls: ['./incident-edit.component.scss']
})
export class IncidentEditComponent implements OnInit {
  @ViewChild('incidentFormComponent') incidentFormComponent: IncidentFormComponent;

  private unsubscribe = new Subject<boolean>();
  incident: IncidentModel;
  error: string = null;
  incidentId: number;

  constructor(
    private incidentUpdateService: IncidentUpdateService,
    private incidentService: IncidentService,
    private route: ActivatedRoute,
    private service: IncidentService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {

      if (params.has('id')) {
        this.incidentId = parseInt(params.get('id'), 10);
        this.loadIncident();
      } else {
        this.error = 'No Submission ID found in URL';
      }
    });
  }

  private loadIncident(): void {
    this.incidentService.get$(this.incidentId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        data => this.incident = data,
        err => console.error('Couldn\'t get data for that IncidentId', err)
      );
  }

  get isClosed() {
    return this.incident && this.incident.closed === true;
  }

  postUpdate() {
    const result = this.incidentFormComponent.getResult();
    const updatedIncident = {...this.incident, ...result};

    updatedIncident.sendNotification = result.sendNotification ?? false;

    this.service.update$(updatedIncident)
      .subscribe(
        _ => this.successfulPost(),
        err => {
          console.error(err);
          this.toastr.error(this.translate.instant('toast-notification.incident-edit.error.message'),
            this.translate.instant('toast-notification.incident-edit.error.title'), {
              timeOut: 10000,
              positionClass: 'toast-bottom-center',
              closeButton: true
            });
        }
      );
  }

  successfulPost(): void {
    this.router.navigateByUrl(
      'incidents'
    );
    this.toastr.success(this.translate.instant('toast-notification.incident-edit.success.message'),
      this.translate.instant('toast-notification.incident-edit.success.title'), {
      timeOut: 10000,
      positionClass: 'toast-bottom-center',
      closeButton: true
    });
  }
}

