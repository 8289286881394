
export class ContactModel {
  name: string;
  organisation: string;
  role: string;
  imageFile: string;
  position: {x: number, y: number} | null;
  email: string;
  links: {facebook: string|null, twitter: string|null, website: string|null};

  constructor(name: string, organisation: string, role: string, email: string, imageFile: string | null = null,
              links: {facebook: string|null, twitter: string|null, website: string|null} = {facebook: null, website: null, twitter: null},
              position: {x: number, y: number} | null = null) {
    this.name = name;
    this.organisation = organisation;
    this.role = role;
    this.imageFile = imageFile ? imageFile : 'default.png';
    this.position = position;
    this.email = email;
    this.links = links;
  }
}
