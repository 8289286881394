<div id="additional-info-add" role="main">
  <mat-card id="additional-info-add-container">
    <form [formGroup]="form">
      <mat-card-title role="heading" aria-level="2">{{'cbrne-info-add-item.add-a-new-item' | translate}}</mat-card-title>
      <div>
        <mat-form-field appearance="fill">
          <mat-label>{{'cbrne-info-add-item.item-description' | translate}}</mat-label>
          <textarea matInput formControlName="itemDescription"></textarea>
          <div *ngIf="form.controls['itemDescription'].touched && form.controls['itemDescription'].invalid"></div>
          <mat-error>{{'cbrne-info-add-item.description-is-required' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <p>{{'cbrne-info-add-item.to-upload-files' | translate}}</p>
    </form>
    <div>
      <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid" (click)="addItem()">{{'general.submit' | translate}}</button>
    </div>
  </mat-card>
</div>
