import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ConsortiumPartnerModel} from '../../models/consortium-partner-model';

@Component({
  selector: 'app-consortium-partner',
  templateUrl: './consortium-partner.component.html',
  styleUrls: ['./consortium-partner.component.scss']
})
export class ConsortiumPartnerComponent implements OnInit {
  @Input() partner: ConsortiumPartnerModel;
  @Input() highlight = false;
  @Output() partnerSelected: EventEmitter<ConsortiumPartnerModel> = new EventEmitter<ConsortiumPartnerModel>();

  constructor() { }

  ngOnInit(): void {
  }

  click() {
    this.partnerSelected.emit(this.partner);
  }
}
