<div id="reset-wrapper" role="main">
  <div id="logo-container">
    <img src="assets/images/proactive-logo.png" id="proactive-logo">
  </div>
  <mat-card class="center" id="reset-container">
   <mat-card-subtitle>{{'forgot-password.enter-your-email' | translate}}</mat-card-subtitle>
    <app-messages [messages]="resetMessages"></app-messages>
    <div *ngIf="form" [formGroup]="form">
      <app-password-rules formControlName="password"></app-password-rules>
      <br>
      <div class="right">
        <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="reset()">{{'forgot-password.reset-password' | translate}}</button>
      </div>
    </div>
  </mat-card>
</div>
