import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {ExternalArticleImageMetadata, ExternalArticleMetadata, NewsService} from '../../../services/news.service';

@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss']
})
export class NewsFormComponent implements OnInit, OnDestroy {
  @Input() form: UntypedFormGroup;
  private unsubscribe = new Subject();
  articleMetadata: ExternalArticleMetadata;

  constructor(private news: NewsService) { }

  ngOnInit(): void {
    if (this.form.controls.uri) {
      this.form.controls.uri.valueChanges.pipe(
        takeUntil(this.unsubscribe),
        debounceTime(1000)
      ).subscribe(changes => {
        this.fetchArticle(changes);
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
  }

  fetchArticle(uri: string) {
    this.news.fetchArticle(uri).pipe(takeUntil(this.unsubscribe)).subscribe(this.receiveArticle.bind(this));
  }

  receiveArticle(metadata: ExternalArticleMetadata) {
    this.form.patchValue({
      imageUri: metadata.images[0].uri,
      imageAlt: metadata.images[0].alt
    });

    this.articleMetadata = metadata;
  }

  useSuggestedTitle() {
    this.form.patchValue({
      title: this.articleMetadata.title
    });
  }

  removeImage() {
    this.form.patchValue({
      imageUri: null,
      imageAlt: null
    });
  }
}
