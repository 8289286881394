<mat-card-title role="heading" aria-level="2">{{'share-after.thank-you' | translate}}</mat-card-title>
<mat-card-content>
  <p>{{'share-after.please-note' | translate}}</p>
  <p>{{'share-after.if-you-would' | translate}}</p>
  <a routerLink="/profile">
    <button mat-raised-button color="primary" class="involved-button" role="link"
            aria-label="Configure Push Notifications" [mat-dialog-close]="true">
      <mat-icon>notifications_active</mat-icon>
      {{'share-after.configure' | translate}}
    </button>
  </a>
  <mat-dialog-actions id="close-button">
    <button mat-raised-button color="primary" aria-label="Close message and return to home" routerLink="/" [mat-dialog-close]="true">{{'share-after.close' | translate}}</button>
  </mat-dialog-actions>
</mat-card-content>
