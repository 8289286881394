import { Component, OnInit } from '@angular/core';
import { zip } from 'rxjs';
import { IncidentModel } from 'src/app/models/incident-model';
import { IncidentService } from 'src/app/services/incident.service';
import { MapLoaderService } from 'src/app/services/map-loader.service';

@Component({
templateUrl: './heatmap.component.html',
styleUrls: ['./heatmap.component.scss']
})
export class HeatmapComponent implements OnInit{

  incidentData: IncidentModel[];
  isDataLoaded:boolean = false;

  constructor(private incidentService: IncidentService, public mapLoader: MapLoaderService) {
    zip(mapLoader.mapsLoaded)
  }

  ngOnInit() {
    this.loadIncidents();
  }

  zoom = 4;
  heatmapOptions = {radius: 15};
  heatmapData = [];

   loadIncidents(): void {
    this.incidentService.list$().subscribe(data => {
      this.incidentData = data;
      for(let incident of this.incidentData) 
        this.heatmapData.push({lat: incident.location.lat, lng: incident.location.lng})
        this.isDataLoaded = true;
    });
  }
}
