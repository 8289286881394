<div id="faq-view-wrapper" role="main">
  <mat-card class="faq-tile">
    <mat-card-title role="heading" aria-level="2">{{'faq.help' | translate}}</mat-card-title>
    <mat-card-content>
      <ol>
        <li *ngFor="let question of questions"><a [href]="'faq#question-' + question.id">{{question.question | translate}}</a></li>
      </ol>
      <p>
        {{'faq.if-you-cannot' | translate}}
        <a routerLink="/about">{{'faq.information' | translate}}</a> {{'faq.or' | translate}} <a routerLink="/contacts">{{'faq.support' | translate}}</a>.
      </p>
    </mat-card-content>
  </mat-card>
  <div *ngFor="let question of questions">
  <br>
  <mat-card class="faq-tile">
    <mat-card-title role="heading" aria-level="2" [id]="'question-' + question.id">{{question.question | translate}}</mat-card-title>

    <p>{{question.answer | translate}}</p>
  </mat-card>
  </div>
</div>
