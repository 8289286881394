export class AdditionalInfoItemSubmission {
  categoryId: number;
  description: string;

  constructor(categoryId: number, itemDescription: string) {
    this.categoryId = categoryId;
    this.description = itemDescription;
  }

  static fromObject(
    obj: {
      categoryId: number
      description: string;
    }
  ): AdditionalInfoItemSubmission {
    const categoryId = obj.categoryId;
    const itemDescription = obj.description;
    return new AdditionalInfoItemSubmission(
      categoryId,
      itemDescription,
    );
  }
}
