<div id="register-wrapper" role="main">
  <div id="logo-container">
    <img src="assets/images/proactive-logo.png" id="proactive-logo">
  </div>
  <mat-card class="center" id="register-container">
   <mat-card-subtitle>{{'register.enter' | translate}}</mat-card-subtitle>
    <mat-card-subtitle>{{'register.already' | translate}} <a routerLink="/login">{{'register.login' | translate}}</a></mat-card-subtitle>
    <app-messages [messages]="registerMessages"></app-messages>
    <app-messages [messageSource]="messageStream"></app-messages>
    <div [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{'register.email' | translate}}</mat-label>
        <input matInput type="email" autocomplete="email" spellcheck="false" formControlName="email">
        <mat-error *ngIf="form.controls.email.touched && !form.controls.email.value">{{'register.email-cannot' | translate}}</mat-error>
        <mat-error *ngIf="form.controls.email.hasError('email')">{{'register.please-enter' | translate}}</mat-error>
        <mat-error *ngIf="form.controls.email.hasError('accountExists')">{{'register.already-exists' | translate}}</mat-error>
      </mat-form-field>
      <br>
      <div><app-password-rules formControlName="password"></app-password-rules></div>
      <br>
      <div>
        <mat-checkbox formControlName="privacyPolicyAgreed">
          {{'register.i-have-reviewed' | translate}} <a (click)="goToLink('/privacy')">{{'register.privacy' | translate}}</a>
        </mat-checkbox>
      </div>
      <br>
      <div class="right">
        <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="registerAccount()">{{'register.create-account' | translate}}</button>
      </div>
    </div>
  </mat-card>
</div>
