import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {handleHttpError} from '../utils/http-error-catcher';
import {NewsModel} from '../models/news-model';
import {ObservableCache} from '../utils/observable-cache';


interface NewsItemResponseModel {
  id: number;
  title: string;
  body: string;
  imageUri: string;
}

interface NewsCreateResponseModel {
  id: number;
  title: string;
  body: string;
  imageUri: string;
}

export interface ExternalArticleMetadata {
  title: string;
  images: ExternalArticleImageMetadata[];
}

export interface ExternalArticleImageMetadata {
  uri: string;
  alt: string;
}


@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private cache = new ObservableCache();

  constructor(private http: HttpClient, private auth: AuthService) { }

  // TODO: Filter? Pagination?
  list$(): Observable<NewsModel[]> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to List News Items.');
    }

    let obs = this.http.get<NewsItemResponseModel[]>('news').pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.map(NewsModel.fromObject);
      })
    );

    obs = this.cache.register('list', obs);

    return obs;
  }

  get$(id: number): Observable<NewsModel> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Get News Items.');
    }

    let obs = this.http.get<NewsItemResponseModel>(`news/${id}`).pipe(
      catchError(handleHttpError),
      map(NewsModel.fromObject)
    );

    obs = this.cache.register(id.toString(), obs);

    return obs;
  }

  create(data: NewsModel): Observable<boolean> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Create News Items.');
    }

    return this.http.post<NewsCreateResponseModel>(`news`, data).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }

  edit(id: number, data: NewsModel): Observable<boolean> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Edit News Items.');
    }

    if (!data.id) {
      data.id = id;
    } else if (data.id !== id) {
      throw new Error('URL ID and Data ID must match');
    }

    return this.http.put<NewsCreateResponseModel>(`news/${id}`, data).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }

  delete(id: number): Observable<boolean> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to Delete News Items.');
    }

    return this.http.delete<NewsCreateResponseModel>(`news/${id}`).pipe(
      catchError(handleHttpError),
      map(resp => {
        return resp.success;
      })
    );
  }

  fetchArticle(uri: string): Observable<ExternalArticleMetadata> {
    return this.http.get(`news/articleMeta`, { params: { uri } }).pipe(map(
      resp => resp as ExternalArticleMetadata
    ));
  }
}
