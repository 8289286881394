import { Injectable } from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {handleHttpError} from '../utils/http-error-catcher';
import {HttpClient} from '@angular/common/http';
import {NewsModel} from '../models/news-model';
import {NotificationModel} from '../models/notification-model';
import {IncidentModel} from '../models/incident-model';
import {LatestNotificationModel} from '../models/latest-notification-model';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private news: Observable<NewsModel[]> = null;
  private newsCompletedSubscription: Subscription;
  private newsCompleted = false;
  private notifications: Observable<NotificationModel[]> = null;
  private notificationsCompletedSubscription: Subscription;
  private notificationsCompleted = false;
  private latestUpdate: Observable<LatestNotificationModel>;

  constructor(private http: HttpClient) { }

  news$(): Observable<NewsModel[]> {
    if (!this.news || this.newsCompleted) {
      this.newsCompleted = false;
      this.news = this.http.get<NewsModel[]>('dashboard/recentNews').pipe(
        catchError(handleHttpError),
        map(resp => {
          return resp.map(NewsModel.fromObject);
        }),
        shareReplay(1)
      );

      this.newsCompletedSubscription = this.news.subscribe({ complete: this.onNewsComplete.bind(this) });
    }

    return this.news;
  }

  onNewsComplete(): void {
    this.newsCompleted = true;
    setTimeout(() => this.newsCompletedSubscription.unsubscribe(), 0);
  }

  latestNotification(): Observable<LatestNotificationModel> {
    this.latestUpdate = this.http.get<IncidentModel>('dashboard/latestNotification').pipe(
      catchError(handleHttpError),
      map( resp => {
        if (resp != null) {
          return LatestNotificationModel.fromObject(resp);
        } else {
          return resp;
        }
      }),
      shareReplay(1)
    );

    return this.latestUpdate;
  }

  notifications$(): Observable<NotificationModel[]> {
    if (!this.notifications || this.notificationsCompleted) {
      this.notificationsCompleted = false;
      this.notifications = this.http.get<NotificationModel[]>('dashboard/notifications').pipe(
        catchError(handleHttpError),
        map(resp => {
          return resp.map(NotificationModel.fromObject);
        }),
        shareReplay(1)
      );

      this.notificationsCompletedSubscription = this.notifications.subscribe({ complete: this.onNotificationsComplete.bind(this) });
    }

    return this.notifications;
  }

  onNotificationsComplete(): void {
    this.notificationsCompleted = true;
    setTimeout(() => this.notificationsCompletedSubscription.unsubscribe(), 0);
  }
}
