import {Component, OnInit} from '@angular/core';
import {IncidentService} from '../../services/incident.service';
import {IncidentModel} from '../../models/incident-model';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {IncidentUpdateModel} from '../../models/incident-update-model';
import {IncidentUpdateService} from '../../services/incident-update.service';
import {UploadedFile} from '../../components/photo-upload/photo-upload.component';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {EIncidentStatus} from '../../models/incident-status.enum';
import {Incidentform} from '../../models/incidentform.model';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-incident-detail',
  templateUrl: './incident-detail.component.html',
  styleUrls: ['./incident-detail.component.scss']
})
export class IncidentDetailComponent implements OnInit {
  incident: IncidentModel = null;
  updates: IncidentUpdateModel[] = null;
  error: string = null;
  incidentResolved: Incidentform;

  updateForm: UntypedFormGroup;
  private unsubscribe = new Subject<boolean>();

  constructor(private route: ActivatedRoute, private incidentService: IncidentService, private incidentUpdateService: IncidentUpdateService,
              private domSanitizer: DomSanitizer, private service: IncidentService, private toastr: ToastrService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.has('id')) {
        const id = parseInt(params.get('id'), 10);
        this.incidentService.get$(id).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          this.incident = data;
        });
        this.incidentUpdateService.list$(id).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          this.updates = data;
        });
      } else {
        this.error = 'No Submission ID found in URL';
      }
    });

    this.updateForm = new UntypedFormGroup({
      photos: new UntypedFormControl(),
      text: new UntypedFormControl('', [Validators.required]),
      resolved: new UntypedFormControl(),
      makePublic: new UntypedFormControl(),
      sendNotification: new UntypedFormControl('', [Validators.required])
    });
  }

  get location(): google.maps.LatLngLiteral {
    return {lat: this.incident.location.lat, lng: this.incident.location.lng};
  }

  get isNotResolved(): boolean {
    if (this.incident != null) {
      return this.incident.status !== EIncidentStatus.Resolved;
    }
  }

  get hasIncident(): boolean {
    return this.incident !== null;
  }

  get hasUpdates(): boolean {
    return this.updates !== null && this.updates.length > 0;
  }

  trackPhoto(item: string, index: number): string {
    return item;
  }

  asSafe(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  get canSubmit(): boolean {
    return this.updateForm.valid;
  }

  setPhotos(photos: UploadedFile[]): void {
    this.updateForm.patchValue({
      photos
    });
  }

  submitUpdate() {
    const data = {
      incident: { id: this.incident?.id },
      description: this.updateForm.get('text').value,
      photos: [],
      isPublic: this.updateForm.get('makePublic').value,
      sendNotification: this.updateForm.controls.sendNotification.value,
      status: this.updateForm.controls.resolved.value
    };

    if (data.isPublic == null) {
      data.isPublic = false;
    }

    if (this.updateForm.controls.sendNotification.value !== '1') {
      data.sendNotification = false;
    } else { data.sendNotification = true; }




    const photoFiles = this.updateForm.get('photos').value?.map(f => f.data as Promise<string>) || [];
    Promise.all(photoFiles).then(
      photos => {
        data.photos = photos;

        this.incidentUpdateService.share(this.incident.id, data).subscribe(
          () => {
            this.updateForm.reset({text: '', photos: []});
            this.reload();
          }, err => {
            console.error(err);
            this.toastr.error(this.translate.instant('toast-notification.incident-detail.error.message'),
              this.translate.instant('toast-notification.incident-detail.error.title'), {
              timeOut: 10000,
              positionClass: 'toast-bottom-center',
              closeButton: true
            });
          }, () => {
            this.toastr.success(this.translate.instant('toast-notification.incident-detail.success.message'),
              this.translate.instant('toast-notification.incident-detail.success.title'), {
              timeOut: 10000,
              positionClass: 'toast-bottom-center',
              closeButton: true
            });
          }
        );
      }
    );

    if (this.updateForm.controls.resolved.value == true) {
      data.status = EIncidentStatus.Resolved
    } else {data.status = EIncidentStatus.Ongoing}
    
      this.incidentResolved = new Incidentform(
        data.status,
        this.incident.classification,
        this.incident.occurred,
        this.incident.headline,
        this.incident.description,
        {
          lat: this.incident.location.lat,
          lng: this.incident.location.lng,
          streetAddress: this.incident.location.streetAddress},
        data.sendNotification,
        this.incident.id
      );
      this.service.update$(this.incidentResolved).subscribe(() => {});
    
  }

  reload(): void {
    this.incidentService.get$(this.incident.id).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.incident = data;
    });
    this.incidentUpdateService.list$(this.incident.id).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.updates = data;
    });
  }
}
