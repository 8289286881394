import {Component, OnDestroy, OnInit} from '@angular/core';
import {DashboardService} from '../../services/dashboard.service';
import {takeUntil} from 'rxjs/operators';
import {interval, Subject} from 'rxjs';
import {NewsModel} from '../../models/news-model';
import {NotificationModel} from '../../models/notification-model';
import {IncidentService} from '../../services/incident.service';
import {IncidentModel} from '../../models/incident-model';
import {IncidentFilter} from '../../utils/incident-filter';
import {MatTableDataSource} from '@angular/material/table';
import {EIncidentStatus} from '../../models/incident-status.enum';
import {NotificationService} from '../../services/notification.service';
import {LatestNotificationModel} from '../../models/latest-notification-model';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  notifications: NotificationModel[] = null;
  news: NewsModel[] = null;
  latestNews: NewsModel[] = null;
  latestNotification: LatestNotificationModel = null;
  private unsubscribe = new Subject<boolean>();

  dataSource = new MatTableDataSource(null);
  incidentData: IncidentModel[] = [];
  filterValue: string;
  resolved: IncidentModel[];

  constructor(
    private dashboard: DashboardService,
    private incidentService: IncidentService,
    public notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.dashboard.news$().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.news = data;
      this.latestNews = this.recentNews();
    });

    this.dashboard.notifications$().pipe(takeUntil(this.unsubscribe)).subscribe(
        data => this.notifications = data
    );

    this.loadIncidents();
    // Task every min to load all Incidents again
    interval(1000 * 60).subscribe(() => {
      this.loadIncidents();
    });

    this.loadLatestNotification();

    // Task to get latest Notification every min
    interval(1000 * 60).subscribe( () => {
      this.loadLatestNotification();
    });

    interval(1000 * 60).subscribe(() => {
      this.latestNews = this.recentNews();
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  get hasNews(): boolean {
    return this.news !== null && this.news.length > 0;
  }

  get hasLatestNotification(): boolean {
    return this.latestNotification !== null;
  }

  get hasNotifications(): boolean {
    return this.notifications !== null;
  }

  private loadIncidents(): void {
    this.incidentService.list$().subscribe(data => {
      this.incidentData = data;
      this.dataSource.data = data;
    });
  }

  private loadLatestNotification(): void {
    this.dashboard.latestNotification().pipe(takeUntil(this.unsubscribe)).subscribe(data => {
      this.latestNotification = data;
    });
  }

  get previousIncidents(): Array<IncidentModel> {
    const recentResolved = this.incidentData
      .filter(incident => !!incident.resolvedDate);
    recentResolved.sort((a, b) => {
      return b.resolvedDate.getTime() - a.resolvedDate.getTime();
    });
    return recentResolved.slice(0, 4);
  }

  private recentNews() {
    if (this.hasNews) {
      const recentNewsitems = this.news
        .sort((a, b) => {
          return (new Date(b.created).getTime()) - (new Date(a.created).getTime());
        })
        .slice(0, 3);
      return recentNewsitems;
    }
  }

  gpsCoords(incident: IncidentModel): string {
    return `${incident.location.lat.toFixed(3)}, ${incident.location.lng.toFixed(3)}`;
  }
}
