import {Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {EUserRole, EUserRoleLookup} from '../../models/user-role.enum';
import {UserService} from '../../services/user.service';
import {UserCreateModel} from '../../models/user-model';
import {PasswordRulesComponent} from '../../components/password-rules/password-rules.component';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  @ViewChild(PasswordRulesComponent) child: PasswordRulesComponent;
  form: UntypedFormGroup;

  constructor(private router: Router, private users: UserService) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new UntypedFormControl('', []),
      role: new UntypedFormControl(EUserRole.User),
      isConfirmed: new UntypedFormControl(false)
    });
  }

  get userRoles(): {id: number, name: string}[] {
    return Object.keys(EUserRole).filter(k => typeof EUserRole[k] === 'number').map(role => {
      const id = EUserRole[role];

      return {
        id,
        name: EUserRoleLookup.get(id).name
      };
    });
  }

  trackRole(index: number, item: {id: number, name: string}): any {
    return item.id;
  }

  createAccount() {
    if (!this.form.valid) {
      return false;
    }

    const data = new UserCreateModel(
      this.form.controls.email.value,
      this.form.controls.password.value.password,
      this.form.controls.role.value,
      this.form.controls.isConfirmed.value);
    console.log(this.form.value, data);

    this.users.create(data).subscribe(resp => {
      this.router.navigateByUrl(
        'users',
        {state: {messages: [{type: 'success', message: 'The account has been created. The user may now log in.'}]}}
      );
    },
      err => this.child.handleError(err));
  }

}

// TODO: Don't validate while typing
// TODO: GDS Validation standards: https://design-system.service.gov.uk/patterns/validation/
