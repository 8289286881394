import { Injectable } from '@angular/core';
import {Observable, Subscription, throwError} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {handleHttpError} from '../utils/http-error-catcher';
import {SubmissionModel} from '../models/submission-model';
import {MapLocation} from '../models/map-location';
import {FileModel} from '../models/file-model';

interface SubmissionDetailResponseModel {
  id: number;
  incidentId: number | null;
  submitted: Date;
  submitter: string;
  description: string;
  files: FileModel[];
  location: MapLocation | null;
}

interface ShareInformationModel {
  incident: { id: number } | null;
  description: string;
  photos: string[];
  location: MapLocation | null;
}

@Injectable({
  providedIn: 'root'
})
export class SubmissionsService {
  private list: Observable<SubmissionModel[]> = null;
  private listCompleted = false;
  private listCompletedSubscription: Subscription;
  private getMap: Map<number, Observable<SubmissionModel>> = new Map();
  private getCompletedMap: Map<number, boolean> = new Map();
  private getCompletedSubscriptionMap: Map<number, Subscription> = new Map();

  constructor(private http: HttpClient, private auth: AuthService) { }

  private mapDetailResponse(resp: SubmissionDetailResponseModel): SubmissionModel {
    return SubmissionModel.fromObject(resp);
  }

  // TODO: Filter? Pagination?
  list$(): Observable<SubmissionModel[]> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to List User Submissions.');
    }

    if (!this.list || this.listCompleted) {
      this.listCompleted = false;
      this.list = this.http.get<SubmissionDetailResponseModel[]>('submissions').pipe(
        catchError(handleHttpError),
        map(resp => {
          return resp.map(this.mapDetailResponse);
        }),
      shareReplay(1)
      );

      this.listCompletedSubscription = this.list.subscribe({ complete: this.onListComplete.bind(this) });
    }

    return this.list;
  }

  onListComplete(): void {
    this.listCompleted = true;
    setTimeout(() => this.listCompletedSubscription.unsubscribe(), 0);
  }

  get$(id: number): Observable<SubmissionModel> {
    if (!this.auth.isLoggedIn || !this.auth.isAdmin) {
      throw new Error('You must be logged in as an Admin to View a User Submission.');
    }

    let obs = this.getMap.get(id);
    if (!obs || this.getCompletedMap.get(id)) {
      this.getCompletedMap.set(id, false);
      obs = this.http.get<SubmissionDetailResponseModel>(`submissions/${id}`).pipe(
        catchError(handleHttpError),
        map(this.mapDetailResponse),
        shareReplay(1)
      );
      this.getMap.set(id, obs);

      const subscription = obs.subscribe({ complete: this.onGetCompleted(id) });
      this.getCompletedSubscriptionMap.set(id, subscription);
    }

    if (!obs) {
      return throwError('Not found');
    }

    return obs;
  }

  onGetCompleted(id: number): () => void {
    return () => {
      this.getCompletedMap.set(id, true);
      setTimeout(() => this.getCompletedSubscriptionMap.get(id).unsubscribe(), 0);
    };
  }

  share(data: ShareInformationModel): Observable<boolean> {
    if (!this.auth.isLoggedIn) {
      throw new Error('You must be logged in to Share Information.');
    }

    return this.http.post('submissions', data).pipe(
      catchError(handleHttpError),
      map(() => true)
    );
  }
}
