import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {SubmissionsService} from '../../services/submissions.service';
import {SubmissionModel} from '../../models/submission-model';

@Component({
  selector: 'app-user-submission-list',
  templateUrl: './user-submission-list.component.html',
  styleUrls: ['./user-submission-list.component.scss']
})
export class UserSubmissionListComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource<SubmissionModel>(null);
  displayedColumns: string[] = ['id', 'location', 'description', 'submitted', 'controls'];
  filterValue: string;
  private listUnsubscribe = new Subject<boolean>();

  messages: { message: string, type: string }[];

  constructor(private submissions: SubmissionsService) {}

  ngOnInit(): void {
    this.submissions.list$().pipe(takeUntil(this.listUnsubscribe)).subscribe(data => {
      this.dataSource.data = data;
      this.applyFilter();
    });
  }

  ngOnDestroy(): void {
    this.listUnsubscribe.next(true);
    this.listUnsubscribe.complete();
  }

  setFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.applyFilter();
  }

  applyFilter() {
    this.dataSource.filter = this.filterValue;
  }

  get hasData(): boolean {
    return this.dataSource.data !== null;
  }
}
