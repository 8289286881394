<header [ngClass]="{'fixed-header': fixed}" aria-label="Page Header">
  <div *ngIf="routeTitle$ | async as title" aria-live="assertive">
    <span [attr.aria-label]="title"></span>
  </div>
  <mat-toolbar color="primary" id="toolbar">

    <button tabindex="0" mat-button routerLink="/" role="link" aria-label="Dashboard" [disabled]="disabled">
      <img src="assets/images/proactive-logo-white-transparent.png" id="nav-logo" alt="Proactive Logo">
    </button>

    <!-- This fills the remaining space of the current row -->
    <span class="fill-remaining-space"></span>
    <div fxLayout="row" fxShow="false" fxShow.gt-sm role="navigation" aria-label="Navigation Menu">
      <button mat-button routerLink="/home" role="link" aria-label="Home" id="toolbarItem" [disabled]="disabled">{{'menu-bar.home' | translate}}</button>
      <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" *appIsNotLoggedIn
              mat-button (click)="goToLogin()" (keydown.enter)="goToLogin()" role="link" aria-label="Login" id="toolbarItem" [disabled]="disabled">{{'menu-bar.login' | translate}}</button>
      <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" *appIsLoggedIn
              mat-button (click)="logOut()" (keydown.enter)="logOut()" role="link" aria-label="Log Out" id="toolbarItem" [disabled]="disabled">{{'menu-bar.logout' | translate}}</button>
        </div>
        <!-- <label for="">
                <select [(ngModel)]="language" #langselect (change)="translate.use(langselect.value) && changeLanguage()">
                        <option *ngFor="let lang of translate.getLangs()" [value]="lang"
                        [attr.selected]="lang === translate.currentLang ? lang : false">{{lang}}</option>
                </select>
        </label> -->
    <button mat-button [mat-menu-trigger-for]="menu" #menuTrigger="matMenuTrigger" fxHide="false" aria-label="Navigation Menu" id="toolbarItem" [disabled]="disabled">
      <mat-icon>menu</mat-icon>
    </button>

  </mat-toolbar>
  <mat-menu xPosition="before" #menu="matMenu" role="navigation" aria-label="Popup Navigation Menu" #menuElement>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)"
            *appFxHide.gt-sm mat-menu-item routerLink="/home" role="link" aria-label="Home">{{'menu-bar.home' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)"
            mat-menu-item routerLink="/incidents" role="link" aria-label="Incidents">{{'menu-bar.incidents' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" *appIsAdmin
            mat-menu-item routerLink="/map-heatmap-layer" role="link" aria-label="Heatmap">{{'menu-bar.heatmap' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" *appIsAdmin
            mat-menu-item (click)="goToLink('/news')" role="link" aria-label="Users">{{'menu-bar.news' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" *appIsAdmin
            mat-menu-item routerLink="/users" role="link" aria-label="Users">{{'menu-bar.users' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" *appIsAdmin
            mat-menu-item routerLink="/submissions" role="link" aria-label="User Submissions">{{'menu-bar.public-submissions' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" mat-menu-item
            routerLink="/privacy" role="link" aria-label="Privacy Policy">{{'menu-bar.privacy-and-cookies' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" mat-menu-item
            routerLink="/about" role="link" aria-label="About">{{'menu-bar.about' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" mat-menu-item
            routerLink="/faq" role="link" aria-label="Help">{{'menu-bar.help' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" *appIsLoggedIn
            mat-menu-item routerLink="/profile" role="link" aria-label="Profile">{{'menu-bar.profile' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" *appIsAdmin
            mat-menu-item routerLink="/statistics" role="link" aria-label="Statistics">{{'menu-bar.statistics' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" *appIsLoggedIn
            mat-menu-item (click)="logOut()" (keydown.enter)="logOut()" role="link" aria-label="Log Out">{{'menu-bar.logout' | translate}}</button>
    <button (keydown.tab)="onMenuItemTab($event)" (keydown.shift.tab)="onMenuItemShiftTab($event)" *appIsNotLoggedIn
            mat-menu-item (click)="goToLogin()" (keydown.enter)="goToLogin()" role="link" aria-label="Login">{{'menu-bar.login' | translate}}</button>
  </mat-menu>
</header>
