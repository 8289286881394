import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NewsService} from '../../services/news.service';
import {NewsModel} from '../../models/news-model';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-news-edit',
  templateUrl: './news-edit.component.html',
  styleUrls: ['./news-edit.component.scss']
})
export class NewsEditComponent implements OnInit {
  form: UntypedFormGroup;

  newsId: number = null;
  loaded = false;

  error: string;

  private unsubscribe = new Subject<boolean>();

  constructor(private route: ActivatedRoute, private router: Router, private news: NewsService) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      title: new UntypedFormControl('', [
        Validators.required
      ]),
      category: new UntypedFormControl('', [
        Validators.required
      ]),
      uri: new UntypedFormControl('', [
        Validators.required
      ]),
      imageUri: new UntypedFormControl(''),
      imageAlt: new UntypedFormControl('')
    });

    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.has('id')) {
        this.newsId = parseInt(params.get('id'), 10);
        this.news.get$(this.newsId).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          this.form.patchValue(data);
          this.loaded = true;
        });
      } else {
        this.error = 'No News Item ID found in URL';
      }
    });
  }

  saveNewsItem() {
    if (!this.form.valid) {
      return false;
    }

    const data = NewsModel.fromObject(this.form.value);
    console.log(this.form.value, data);

    this.news.edit(this.newsId, data).subscribe(resp => {
      this.router.navigateByUrl(
        'news',
        {state: {messages: [{type: 'success', message: 'News Item Updated'}]}}
      );
    });
  }
}
