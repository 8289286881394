<mat-card class="contact-card" fxLayout="row">
  <div class="profile-wrap">
    <img mat-card-md-image class="profile" src="assets/images/profiles/{{ contact.imageFile }}" alt="Profile photo: {{contact.name}}" [ngStyle]="{ 'object-position': objectPosition}">
  </div>
  <div>
    <mat-card-title role="heading" aria-level="2">{{ contact.name }}</mat-card-title>
    <mat-card-subtitle>{{ contact.role }}</mat-card-subtitle>
    <mat-card-content>
      <h5>{{ contact.organisation }}</h5>
      <a href="mailto: {{contact.email}}">{{ contact.email }}</a>
      <div class="icon-container" role="link">
        <a href="{{contact.links.facebook}}" class="icon" *ngIf="contact.links.facebook!=null">
          <mat-icon style="color: #3b5998">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z"/></svg>
          </mat-icon>
        </a>

        <a href="{{contact.links.twitter}}" class="icon" *ngIf="contact.links.twitter!=null">
          <mat-icon style="color: #1da1f2">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z"/></svg>
          </mat-icon>
        </a>

        <a href="{{contact.links.website}}" class="icon" *ngIf="contact.links.website!=null">
          <mat-icon style="color: slategray">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z"/></svg>
          </mat-icon>
        </a>
      </div>
    </mat-card-content>



  </div>
</mat-card>
