import {Observable, Subscription} from 'rxjs';
import {shareReplay} from 'rxjs/operators';

export class ObservableCache {
  private obsMap: Map<string, Observable<any>> = new Map();
  private obsCompletedMap: Map<string, boolean> = new Map();
  private obsCompletedSubscriptionMap: Map<string, Subscription> = new Map();

  register<T>(key: string, obs: Observable<T>): Observable<T> {
    let existingObs = this.obsMap.get(key);
    const completed = this.obsCompletedMap.get(key);

    if (!existingObs || completed) {
      this.obsCompletedMap.set(key, false);
      existingObs = obs.pipe(shareReplay(1));
      this.obsMap.set(key, existingObs);

      const subscription = existingObs.subscribe({complete: this.onObsCompleted(key)});
      this.obsCompletedSubscriptionMap.set(key, subscription);
    }

    return existingObs;
  }

  private onObsCompleted(key: string): () => void {
    return () => {
      this.obsCompletedMap.set(key, true);
      setTimeout(() => this.obsCompletedSubscriptionMap.get(key).unsubscribe(), 0);
    };
  }
}
