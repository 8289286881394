<div *ngIf="form">

  <form [formGroup]="form" *ngIf="incident">

    <!--- Map/Location Input --->
    <div>
      <mat-label *ngIf="displayLocation">{{'incident-form.location-of-incident' | translate}} {{ displayLocation }} </mat-label>
    </div>

    <!--- Incident Time Input --->
    <div id="form-layout">
      <mat-form-field>
        <mat-label>{{'incident-form.time-of-incident' | translate}}</mat-label>
        <input type="time" matInput formControlName="incidentTime">
      </mat-form-field>

      <!--- Incident Date Input --->
      <app-iso-datepicker [form]="form"></app-iso-datepicker>

      <!--- Status Input --->
      <mat-form-field>
        <mat-label>{{'incident-form.incident-status' | translate}}</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let option of incidentStatuses; trackBy: trackType" [value]="option.id">
            {{ option.name | translate }}
          </mat-option>
        </mat-select>
        <mat-error>{{'incident-form.status-is-required' | translate}}</mat-error>
      </mat-form-field>

      <!--- Classification Input --->
      <mat-form-field>
        <mat-label>{{'incident-form.incident-classification' | translate}}</mat-label>
        <mat-select formControlName="classification">
          <mat-option *ngFor="let option of incidentClassifications; trackBy: trackType" [value]="option.id">
            {{ option.name | translate }}
          </mat-option>
        </mat-select>
        <mat-error id="classification-error">{{'incident-form.classification-required' | translate}}</mat-error>
      </mat-form-field>

      <!--- Headline Input --->
      <mat-form-field appearance="fill" class="text-input">
        <mat-label>{{'incident-form.headline-of-incident' | translate}}</mat-label>
        <textarea matInput formControlName="headline"></textarea>
      </mat-form-field>

      <!--- Description Input --->
      <mat-form-field appearance="fill" class="text-input">
        <mat-label>{{'incident-form.description-of-incident' | translate}}</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>

      <!-- Displaying Reported Date --->
      <mat-label>{{'incident-form.reported' | translate}}</mat-label>
      <p>{{incident.reportedDate | date: 'yyyy-MM-dd' }} at {{incident.reportedDate | date: 'HH:mm (z)'}}</p>

      <!--- Push Notifications Input --->
      <mat-label>{{'incident-form.send-push-notification' | translate}}</mat-label>
      <mat-error *ngIf="form.controls.sendNotification.pristine">{{'incident-form.notification-option-required' | translate}}</mat-error>
      <mat-radio-group aria-label="option to send Live Notification" formControlName="sendNotification">
        <mat-radio-button value="true">{{'general.yes' | translate}}</mat-radio-button>
        <mat-radio-button [checked]="true" value="false">{{'general.no' | translate}}</mat-radio-button>
      </mat-radio-group>

      <mat-checkbox formControlName="closed">{{'incident-form.mark-incident-as-closed' | translate}}</mat-checkbox>
      <mat-card-subtitle *ngIf="!isResolved()">{{'incident-form.only-resolved' | translate}}</mat-card-subtitle>
    </div>

  </form>

</div>


