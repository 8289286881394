import {Component} from '@angular/core';
import {NotificationService} from './services/notification.service';
import {AuthService} from './services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'SSNapSight';
  allowedLanguages = ['English', 'Deutsch'];
  language: string;

  constructor(private notificationService: NotificationService, private authService: AuthService, private translate: TranslateService, private router: ActivatedRoute) {
    translate.addLangs(this.allowedLanguages);
    this.translate.setDefaultLang('English');
    authService.startUpdatingSession();
  }

  ngOnInit() {
    const lang = localStorage.getItem('lang');
    this.language = lang ? lang : this.translate.defaultLang;
    this.translate.use(this.language);

    this.router.queryParams
      .subscribe(params => {
        const queryLanguage = params.language
        switch(queryLanguage) {
          case 'en':
            this.language = 'English'
            break;
          case 'de':
            this.language = 'Deutsch'
            break;
        }
        this.translate.use(this.language);
      }
    );
  }

}
