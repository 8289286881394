import {AdditionalInfoFileModel} from "./AdditionalInfoFileModel";

export class AdditionalInfoItemModel {
id: number;
categoryId: number;
description: string;
files: AdditionalInfoFileModel[] | null;

  constructor(id: number, categoryId: number, description: string, files: AdditionalInfoFileModel[] | null) {
    this.id = id;
    this.categoryId = categoryId;
    this.description = description;
    this.files = files;
  }

  static fromObject(
    obj: {
      id: number;
      categoryId: number;
      description: string;
      files: AdditionalInfoFileModel[] | null;
    }
  ): AdditionalInfoItemModel {
    const id = obj.id;
    const categoryId = obj.categoryId;
    const description = obj.description;
    const files = obj.files;

    return new AdditionalInfoItemModel(
      id,
      categoryId,
      description,
      files,
    );
  }
}
