import {IncidentModel} from '../models/incident-model';

export class IncidentFilter {
  public static filterIncidents(incidents: IncidentModel[], value: string): IncidentModel[] {
    if (!incidents || typeof value === 'number') {
      return [];
    }

    if (!value) {
      return incidents;
    }

    const filters = value.toLowerCase().split(' ');
    let filtered = incidents;

    filters.forEach(filter => {
      filtered = filtered.filter(incident => this._filterIncident(filter, incident));
    });

    return filtered;
  }

  private static _filterIncident(filter: string, incident: IncidentModel): boolean {
    if (!incident) {
      return false;
    }

    return (incident.location && incident.location.streetAddress && incident.location.streetAddress.toLowerCase().includes(filter))
      || incident.displayClassification.toLowerCase().includes(filter)
      || (incident.reportedDate && incident.reportedDate.toISOString().toLowerCase().includes(filter));
  }
}
