import {EnumMap, EnumMemberDetails} from '../utils/lookup-map';

export enum EIncidentStatus {
  Reported,
  AwaitingInformation,
  Ongoing,
  Resolved,
  Unknown
}

export class EIncidentStatusDetails extends EnumMemberDetails<EIncidentStatus> {}

export const EIncidentStatusLookup: EnumMap<EIncidentStatus, EIncidentStatusDetails> =
  new EnumMap<EIncidentStatus, EIncidentStatusDetails>(
    [
      [EIncidentStatus.Reported, { id: EIncidentStatus.Reported, name: 'Reported' }],
      [EIncidentStatus.AwaitingInformation, { id: EIncidentStatus.AwaitingInformation, name: 'Awaiting Information' }],
      [EIncidentStatus.Ongoing, { id: EIncidentStatus.Ongoing, name: 'Ongoing' }],
      [EIncidentStatus.Resolved, { id: EIncidentStatus.Resolved, name: 'Resolved' }],
      [EIncidentStatus.Unknown, { id: EIncidentStatus.Unknown, name: 'Unknown Status' }]
    ],
    { id: EIncidentStatus.Unknown, name: 'Unknown Status' }
  );
