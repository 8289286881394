import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivateChild
} from '@angular/router';
import {isObservable, Observable, of} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {map, mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate, CanActivateChild {
  constructor(private auth: AuthService, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.auth.loggedIn.pipe(map(loggedIn => {
      if (loggedIn) {
        return true;
      }

      this.router.navigateByUrl('login', {
        state: {
          requestedUrl: state.url
        }
      });

      // Ideally we would return a UrlTree here, but when doing this the NavigationExtras are discarded
      return false;
    }));
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.auth.loggedIn.pipe(mergeMap((loggedIn, _) => {
      if (loggedIn) {
        return of(true);
      }

      // Ideally we would return a UrlTree here, but when doing this the NavigationExtras are discarded
      const innerValue = this.canActivate(next, state);
      if (isObservable(innerValue)) {
        return innerValue;
      } else {
        return of(innerValue as boolean | UrlTree);
      }
    }));
  }
}
