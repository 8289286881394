import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {SubmissionModel} from '../../models/submission-model';
import {SubmissionsService} from '../../services/submissions.service';
import {IncidentModel} from '../../models/incident-model';
import {IncidentService} from '../../services/incident.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-submission-detail',
  templateUrl: './user-submission-detail.component.html',
  styleUrls: ['./user-submission-detail.component.scss']
})
export class UserSubmissionDetailComponent implements OnInit {
  submissionId: number = null;
  submission: SubmissionModel = null;
  resolvedIncident: IncidentModel = null;

  error: string;

  private unsubscribe = new Subject<boolean>();

  constructor(private router: Router, private route: ActivatedRoute, private submissions: SubmissionsService, private incidents: IncidentService,
              private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.has('id')) {
        this.submissionId = parseInt(params.get('id'), 10);
        this.submissions.get$(this.submissionId).pipe(takeUntil(this.unsubscribe)).subscribe(data => {
          this.submission = data;

          if (this.submission.incidentId) {
            this.incidents.get$(this.submission.incidentId).pipe(takeUntil(this.unsubscribe)).subscribe(incidentData => {
              this.resolvedIncident = incidentData;
            });
          }
        });
      } else {
        this.error = 'No Submission ID found in URL';
      }
    });
  }

  trackPhoto(item: string, index: number): string {
    return item;
  }

  asSafe(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  submitToIncident() {
      this.router.navigate([`submissions/${this.submissionId}/new-incident-submission`]);
  }
}
