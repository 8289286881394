import { Component, OnInit } from '@angular/core';
import {CBRNeInformationModel} from '../../models/cbrne-information-model';
import {Observable, Subscription} from "rxjs";
import {AdditionalInfoCategoryModel} from "../../models/AdditionalInfoCategory";
import {AdditionalInfoAdminService} from "../../services/additional-info-admin.service";
import {AdditionalInfoItemModel} from "../../models/AdditionalInfoItem";
import {AdditionalInfoUserService} from "../../services/additional-info-user.service";
import {AdditionalInfoResponseModel} from "../../models/AdditionalInfoResponseModel";

@Component({
  selector: 'app-cbrne-information',
  templateUrl: './cbrne-information.component.html',
  styleUrls: ['./cbrne-information.component.scss'],
})
export class CBRNeInformationComponent implements OnInit {
  categoryModels: AdditionalInfoCategoryModel[] = [];
  categoryList = new Map<number, string>();
  itemModels: AdditionalInfoItemModel[] = [];
  categoryItems: AdditionalInfoResponseModel[] = [];


  constructor(private service: AdditionalInfoAdminService, private userService: AdditionalInfoUserService) { }

  ngOnInit(): void {
    this.userService.list$().subscribe(categoryitems => {
      this.categoryItems = categoryitems;
    });
  }

}
