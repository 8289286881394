<div id="share-information-wrapper" role="main">
  <mat-card class="share-information-title">
    <mat-card-title role="heading" aria-level="2">{{'share-information.share-information' | translate}}</mat-card-title>
    <mat-card-content>
      <p>{{'share-information.not-for-emergency' | translate}}</p>
      <p>{{'share-information.ensure' | translate}}</p>
    </mat-card-content>
  </mat-card>
  <mat-card class="share-information-container" [formGroup]="informationForm">
    <mat-card-content>
      <div>
        <label><h2>{{'share-information.incident-location' | translate}}</h2></label>
        <app-location-input formControlName="location"></app-location-input>
      </div>
      <label for="photoUpload"><h2>{{'share-information.provide' | translate}}</h2></label>
      <mat-form-field appearance="fill" id="incident-info-text">
        <textarea matInput type="textarea" formControlName="text" rows="5"></textarea>
        <mat-error>{{'share-information.text-field' | translate}}</mat-error>
      </mat-form-field>
      <div class="mat-error submit-error" *ngIf="error">{{error}}</div>
      <app-photo-upload formControlName="photos" id="photoUpload" [mediaTypes]="types"></app-photo-upload>
      <br>
      <div id="notice">{{'share-information.please-note' | translate}}</div>
      <div class="left">
        <button mat-raised-button color="primary" class="submit-button" [disabled]="!canShare" (click)="shareInformation()">{{'share-information.share-information' | translate}}</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
