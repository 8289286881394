<div class="flex-container" fxLayoutAlign="center space-between" fxLayout="row wrap">
  <mat-card id="pre-incident-container" fxFlex="70">
      <mat-card-title role="heading" aria-level="2">{{'pre-incident.public' | translate}}</mat-card-title>
  </mat-card>
  <mat-card fxFlex="50">
    {{'pre-incident.if-you-think' | translate}}
  </mat-card>
  <mat-card fxFlex="20"><img src="/assets/images/preincidenticons/pre-incident-1.png"
                             alt="move away from harmful substance"></mat-card>
  <mat-card fxFlex="50">
    {{'pre-incident.get-fresh-air' | translate}}
  </mat-card>
  <mat-card fxFlex="20"><img src="/assets/images/preincidenticons/pre-incident-2.png"
                             alt="do not eat, drink, smoke or touch your face"></mat-card>
  <mat-card fxFlex="50">
    {{'pre-incident.remove-your' | translate}}
  </mat-card>
  <mat-card fxFlex="20"><img src="/assets/images/preincidenticons/pre-incident-3.png"
                             alt="remove outer clothing."></mat-card>
  <mat-card fxFlex="50">
    {{'pre-incident.if-any' | translate}}
  </mat-card>
  <mat-card fxFlex="20"><img src="/assets/images/preincidenticons/pre-incident-4.png"
                             alt="if skin is itchy or burning, rinse with water."></mat-card>
  <mat-card fxFlex="50">
    {{'pre-incident.when-emergency' | translate}}
  </mat-card>
  <mat-card fxFlex="20"><img src="/assets/images/preincidenticons/pre-incident-5.png"
                             alt="emergency responders may ask you to shower in a system they will set up."></mat-card>
  <mat-card fxFlex="50">
    {{'pre-incident.you-should' | translate}}
  </mat-card>
  <mat-card fxFlex="20"><img src="/assets/images/preincidenticons/pre-incident-6.png"
                             alt="do not put your old clothes back on."></mat-card>
</div>
