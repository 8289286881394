<div id="forgot-password-wrapper" role="main">
  <div id="logo-container">
    <img src="assets/images/proactive-logo.png" id="proactive-logo">
  </div>
  <mat-card class="center" id="forgot-password-container">
    <mat-card-subtitle>{{'forgot-password.enter-your-email' | translate}}</mat-card-subtitle>
    <mat-card-subtitle>{{'forgot-password.dont' | translate}} <a routerLink="/register">{{'forgot-password.register-here' | translate}}</a></mat-card-subtitle>
    <app-messages [messages]="forgotPasswordMessages"></app-messages>
    <div [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>{{'forgot-password.email' | translate}}</mat-label>
        <input matInput type="email" autocomplete="email" spellcheck="false" formControlName="email" (keyup.enter)="request()">
        <mat-error *ngIf="form.controls.email.touched && !form.controls.email.value">{{'forgot-password.email-cannot' | translate}}</mat-error>
        <mat-error *ngIf="form.controls.email.hasError('email')">{{'forgot-password.please-enter' | translate}}</mat-error>
      </mat-form-field>
      <br>
      <div class="right">
        <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="request()">{{'forgot-password.request-reset' | translate}}</button>
      </div>
    </div>
  </mat-card>
</div>
